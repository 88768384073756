import apiInstance from '../../../../../api';
import {call, put, select} from 'redux-saga/effects';
import {
  // Wallets
  sendRequestWalletsSearchSuccess,
  sendRequestWalletsSearchError,
  setWallets,
  sendRequestUploadWalletsSuccess,
  sendRequestUploadWalletsError,
  setUploadData,
  sendRequestWalletCreateSuccess,
  sendRequestWalletCreateError,
  sendRequestWalletUpdateSuccess,
  sendRequestWalletUpdateError,
  sendRequestWalletsStatusesGetSuccess,
  sendRequestWalletsStatusesGetError,
  setWalletsStatuses,

  // Transactions
  sendRequestTransactionsSearchSuccess,
  sendRequestTransactionsSearchError,
  setTransactions,
  sendRequestTransactionCreateSuccess,
  sendRequestTransactionCreateError,
} from '../../../../reducers/utils/financeReducers/financeWalletsReducer';
import { selectCommonState } from '../../../../reducers/utils/commonReducer';

// Wallets
export function* handleSearchWallets(action) {
  const api = '/wallets/search?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestWalletsSearchSuccess());
      yield put(setWallets(response.data.data));
    } else {
      yield put(sendRequestWalletsSearchError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestWalletsSearchError());
  }
}

export function* handleUploadWallets(action) {
  const api = '/wallets/importingWallets?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  let formData = new FormData();

  if (data.walletsFile) { formData.append('walletsFile', action.payload.walletsFile); }
  formData.append('data', JSON.stringify( data ));

  try {
    const response = yield call(() => apiInstance.post(fullApi, formData));
    
    yield put(sendRequestUploadWalletsSuccess());
    yield put(setUploadData(response.data.data));
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestUploadWalletsError());
  }
}

export function* handleCreateWallet(action) {
  const api = '/wallets/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestWalletCreateSuccess());
    } else {
      yield put(sendRequestWalletCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestWalletCreateError());
  }
}

export function* handleUpdateWallet(action) {
  const api = '/wallets/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestWalletUpdateSuccess());
    } else {
      yield put(sendRequestWalletUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestWalletUpdateError());
  }
}

export function* handleGetWalletsStatuses(action) {
  const api = '/wallets/getStatuses?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestWalletsStatusesGetSuccess());
      yield put(setWalletsStatuses(response.data.data));
    } else {
      yield put(sendRequestWalletsStatusesGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestWalletsStatusesGetError());
  }
}

// Transactions
export function* handleSearchTransactions(action) {
  const api = '/wallets/getTransactionsWallet?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestTransactionsSearchSuccess());
      yield put(setTransactions(response.data.data));
    } else {
      yield put(sendRequestTransactionsSearchError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestTransactionsSearchError());
  }
}

export function* handleCreateTransaction(action) {
  const api = '/wallets/addTransaction?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestTransactionCreateSuccess());
    } else {
      yield put(sendRequestTransactionCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestTransactionCreateError());
  }
}
