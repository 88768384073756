import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestDeliveryServiceRacesUpdate,
  sendRequestDeliveryServiceRacesDelete,
  setCurrentDeliveryServiceRace,
  sendRequestDeliveryServiceNotWorkingGet,
  sendRequestDeliveryServiceNotWorkingCreate,
  sendRequestDeliveryServiceNotWorkingDelete,
} from '../../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Flex, Select, Form, TimePicker, Popconfirm, Input, Button } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import YearCalendar from '../../../../../../../../components/YearCalendar';

const EditableForm = () => {
  const navigate = useNavigate();
  const { isAuth } = useSelector((store) => store.auth);
  const { cities } = useSelector((store) => store.settingsCatalogs);
  const {
    currentDeliveryService,
    currentDeliveryServiceRace,
    deliveryServiceNotWorking,
    deliveryServiceNotWorkingCreateRequestStatus,
    deliveryServiceNotWorkingDeleteRequestStatus,
  } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [ timezone, setTimezone] = useState(currentDeliveryService.timezoneCity);

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestDeliveryServiceNotWorkingGet({ token: localStorage.getItem('token'), deliveryServiceId: currentDeliveryService.id, raceId: currentDeliveryServiceRace.id}));
    }
  }, [isAuth]);

  useEffect(() => {
    if (
      deliveryServiceNotWorkingCreateRequestStatus === 'resolved' ||
      deliveryServiceNotWorkingDeleteRequestStatus === 'resolved'
    ) {
      dispatch(sendRequestDeliveryServiceNotWorkingGet({ token: localStorage.getItem('token'), deliveryServiceId: currentDeliveryService.id, raceId: currentDeliveryServiceRace.id}));
    }
  }, [
    deliveryServiceNotWorkingCreateRequestStatus,
    deliveryServiceNotWorkingDeleteRequestStatus,
  ]);

  useEffect(() => {
    if (currentDeliveryServiceRace) {
      form.setFieldsValue({
        city: {value: currentDeliveryServiceRace.cityId, label: cities?.find(city => city.id.toString() === currentDeliveryServiceRace.cityId.toString())?.name}.value,
        timeStart: dayjs(currentDeliveryServiceRace.timeStart, timeFormat).add(currentDeliveryServiceRace.timezoneCity, 'hour'),
        timeEnd: dayjs(currentDeliveryServiceRace.timeEnd, timeFormat).add(currentDeliveryServiceRace.timezoneCity, 'hour'),
        initialCourierCutoff: dayjs(currentDeliveryServiceRace.initialCourierCutoff, timeFormat).add(currentDeliveryServiceRace.timezoneCity, 'hour'),
        finalCourierCutoff: dayjs(currentDeliveryServiceRace.finalCourierCutoff, timeFormat).add(currentDeliveryServiceRace.timezoneCity, 'hour'),
      });
    }
  }, [currentDeliveryServiceRace, form]);

  dayjs.extend(customParseFormat);
  const timeFormat = 'HH:mm';

  const handleSubmit = (values) => {
    form.validateFields()
    .then((values) => {
      const requestData = {
        token: localStorage.getItem('token'),
        raceId: currentDeliveryServiceRace.id,
        cityId: values.city,
        deliveryServiceId: currentDeliveryService.id,
        timeStart: moment(new Date(values.timeStart)).subtract(timezone, 'hours').format("HH:mm"),
        timeEnd: moment(new Date(values.timeEnd)).subtract(timezone, 'hours').format("HH:mm"),
        initialCourierCutoff: values.initialCourierCutoff ? moment(new Date(values.initialCourierCutoff)).subtract(timezone, 'hours').format("HH:mm") : "00:00",
        finalCourierCutoff: values.finalCourierCutoff ? moment(new Date(values.finalCourierCutoff)).subtract(timezone, 'hours').format("HH:mm") : "00:00",
      }
      console.log(requestData)
      dispatch(sendRequestDeliveryServiceRacesUpdate(requestData));
      form.resetFields();
    })
    .catch((errorInfo) => {
      console.error('Ошибка при валидации формы:', errorInfo);
    });
  }

  const cityOptions = cities?.map((item) => ({
    ...item,
    value: item?.id,
    label: item?.name,
    timezone: item.timezone,
  }));

  const handleChange = (value, option) => {
    setTimezone(option.timezone);
  };

  const handleDelete = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      raceId: currentDeliveryServiceRace.id,
    }
    
    dispatch(sendRequestDeliveryServiceRacesDelete(requestData));
    navigate(`/delivery/services/${currentDeliveryService.id}`);
    setCurrentDeliveryServiceRace(null);
  };

  const handleAddNotWorkingDay = (day) => {
    const requestData = {
      token: localStorage.getItem('token'),
      deliveryServiceId: currentDeliveryService.id,
      raceId: currentDeliveryServiceRace.id,
      days: [day]
    }
    
    dispatch(sendRequestDeliveryServiceNotWorkingCreate(requestData));
  };

  const handleRemoveNotWorkingDay = (day) => {
    const requestData = {
      token: localStorage.getItem('token'),
      deliveryServiceId: currentDeliveryService.id,
      raceId: currentDeliveryServiceRace.id,
      days: [day]
    }
    
    dispatch(sendRequestDeliveryServiceNotWorkingDelete(requestData));
  };

  console.log(currentDeliveryServiceRace)
  return (
    <Flex vertical>
      <Form
        style={{ width: '100%' }}
        form={form}
        name='deliveryService'
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={{
          city: {value: currentDeliveryServiceRace.cityId, label: cities?.find(city => city.id.toString() === currentDeliveryServiceRace.cityId.toString())?.name}.value,
          timeStart: dayjs(currentDeliveryServiceRace.timeStart, timeFormat).add(currentDeliveryServiceRace.timezoneCity, 'hour'),
          timeEnd: dayjs(currentDeliveryServiceRace.timeEnd, timeFormat).add(currentDeliveryServiceRace.timezoneCity, 'hour'),
          initialCourierCutoff: dayjs(currentDeliveryServiceRace.initialCourierCutoff, timeFormat).add(currentDeliveryServiceRace.timezoneCity, 'hour'),
          finalCourierCutoff: dayjs(currentDeliveryServiceRace.finalCourierCutoff, timeFormat).add(currentDeliveryServiceRace.timezoneCity, 'hour'),
        }}
      >
        <Form.Item label='Город' name='city' rules={[{ required: true, message: 'Выберите город' }]}>
          <Select
            showSearch
            placeholder="Выберите город"
            optionFilterProp="children"
            options={cityOptions}
            filterOption={(input, option) =>
              (option?.name?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
            }
            optionRender={(option) => (
              <Flex>{option.data.label} (GMT{option.data.timezone >= 0 ? `+${option.data.timezone}` : option.data.timezone})</Flex>
            )}
            onChange={handleChange}
          />
        </Form.Item>
        <Flex justify='space-between' gap='large'>
          <Form.Item style={{flex: '1' }} label="Начало доставки" name="timeStart" rules={[{ required: true, message: 'Укажите начало доставки' }]}>
            <TimePicker style={{width: '100%' }} format="HH:mm"/>
          </Form.Item>
          <Form.Item style={{flex: '1' }} label="Завершение доставки" name="timeEnd" rules={[{ required: true, message: 'Укажите завершение доставки' }]}>
            <TimePicker style={{width: '100%' }} format="HH:mm"/>
          </Form.Item>
        </Flex>
        <Flex justify='space-between' gap='large'>
          <Form.Item style={{flex: '1' }} label="Время начала работы курьера" name="initialCourierCutoff">
            <TimePicker style={{width: '100%' }} format="HH:mm"/>
          </Form.Item>
          <Form.Item style={{flex: '1' }} label="Время окончания работы курьера" name="finalCourierCutoff">
            <TimePicker style={{width: '100%' }} format="HH:mm"/>
          </Form.Item>
        </Flex>
      </Form>
      <Flex gap='large'>
        <Button type='primary' htmlType='submit' onClick={handleSubmit} style={{ display: 'flex', marginLeft: 'auto' }}>Сохранить</Button>
        <Popconfirm
          title="Удаление рейса!"
          description={`Вы действительно хотите удалить рейс?`}
          okText="Да"
          cancelText="Нет"
          placement="left"
          onConfirm={handleDelete}
        >
          <Button danger={true}>Удалить</Button>
        </Popconfirm>
      </Flex>

      <div style={{marginTop: '60px'}}>
        <YearCalendar
          data={deliveryServiceNotWorking}
          onCreate={handleAddNotWorkingDay}
          onDelete={handleRemoveNotWorkingDay}
        />
      </div>

    </Flex>
  );
};

export default EditableForm;
