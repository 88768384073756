import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestModifierGroupsGet } from '../../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import {
  sendRequestSearchProductsGet,
  clearCategoriesSortStatus,
  setCurrentKitchen,
  setCurrentCategory,
  setSearchProducts,
  setCurrentProductId,
  setIsShowAssortmentDrawer,
  clearProductsCreateStatus,
  clearProductsUpdateStatus,
  clearProductsDeleteStatus,
} from '../../../../redux/reducers/utils/kitchenReducers/kitchenAssortmentReducer';
import { Flex, Typography, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Loader from '../../../../components/Loader';
import AssortmentTableComponent from './components/AssortmentTableComponent';
import AssortmentDrawerComponent from './components/AssortmentDrawerComponent';
import CategoryComponent from './components/CategoriesComponent';
import AssortmentSearchComponent from './components/AssortmentSearchComponent';
import { ScrolledCol } from '../../../../core/styles';
import { checkAccess } from '../../../../core/utils'

const { Title, Text } = Typography;

const AssortmentPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const { selectKitchen } = useSelector((store) => store.kitchen);
  const { modifierGroups } = useSelector((store) => store.kitchenMenu);
  const { accessComponentsForUser } = useSelector((store) => store.services);
  const {
    searchProductsGetRequestStatus,
    productsCreateRequestStatus,
    productsUpdateRequestStatus,
    productsDeleteRequestStatus,
    productChangeModerationRequestStatus,
    productDeleteImageRequestStatus,
    categoriesSortRequestStatus,
    searchCategories,
    searchCategoriesCount,
    searchCategoriesSorting,
    searchProducts,
    searchKitchens,
    currentKitchen,
    currentCategory,
    currentProductId,
    groupedCategories,
  } = useSelector((store) => store.kitchenAssortment);
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState({
    enabledByAdmin: null,
    search: '',
    priceFrom: 0,
    priceTo: 1000,
  })
  const [offset, setOffset] = useState(0)
  const [offsetLimit, setOffsetLimit] = useState(0)
  const anchorRef = useRef(null);
  const [searchMode, setSearchMode] = useState(false);
  const offsetRef = useRef(offset);
  const scrollRef = useRef(null);
  const isAdmin = checkAccess(accessComponentsForUser, 'moderation');
  const [isProductChanged, setIsProductChanged] = useState(false);

  useEffect(() => {
    offsetRef.current = offset;
  }, [offset]);

  useEffect(() => {
    if (
      searchData.enabledByAdmin === false ||
      searchData.search ||
      searchData.priceFrom > 0 ||
      searchData.priceTo < 1000
    ) {
      setSearchMode(true);
    } else {
      setSearchMode(false);
    }
    dispatch(setSearchProducts([]));
    dispatch(setCurrentCategory(null));
    dispatch(setCurrentKitchen(null));
  }, [searchData]);

  useEffect(() => {
    if (isAuth) {
      const requestData = {
        token: localStorage.getItem('token'),
        offset: 0,
        kitchensIds: [],
        categoriesIds: [],
        ...searchData,
        typeData: [
          'kitchens',
          'categories',
        ]
      }

      dispatch(sendRequestSearchProductsGet(requestData));

      if(categoriesSortRequestStatus === 'resolved') {
        dispatch(sendRequestSearchProductsGet(requestData));
        dispatch(clearCategoriesSortStatus());
      }
    } 
  }, [isAuth, searchData, categoriesSortRequestStatus]);

  useEffect(() => {
    if (selectKitchen) {
      dispatch(sendRequestModifierGroupsGet({ token: localStorage.getItem('token'), kitchenId: selectKitchen.id }));
    }
  }, [selectKitchen]);

  useEffect(() => {
    if (
      productsCreateRequestStatus === 'resolved' ||
      productsUpdateRequestStatus === 'resolved' ||
      productsDeleteRequestStatus === 'resolved' ||
      productChangeModerationRequestStatus === 'resolved' ||
      productDeleteImageRequestStatus === 'resolved' 
    ) {
      const requestData = {
        token: localStorage.getItem('token'),
        offset: 0,
        kitchensIds: [currentKitchen],
        categoriesIds: [currentCategory],
        ...searchData,
        typeData: [
          'products',
        ]
      }

      setIsProductChanged(true);
      setOffset(0);
      dispatch(sendRequestSearchProductsGet(requestData));
      dispatch(clearProductsCreateStatus());
      dispatch(clearProductsUpdateStatus());
      dispatch(clearProductsDeleteStatus());
    }
  }, [
    productsCreateRequestStatus,
    productsUpdateRequestStatus,
    productsDeleteRequestStatus,
    productChangeModerationRequestStatus,
    productDeleteImageRequestStatus,
  ]);

  useEffect(() => {
    if (isProductChanged) {
      const requestData = {
        token: localStorage.getItem('token'),
        offset: 0,
        kitchensIds: [],
        categoriesIds: [],
        ...searchData,
        typeData: [
          'kitchens',
          'categories',
        ]
      }

      setIsProductChanged(false);
      setOffset(0);
      dispatch(sendRequestSearchProductsGet(requestData));
      dispatch(clearProductsCreateStatus());
      dispatch(clearProductsUpdateStatus());
      dispatch(clearProductsDeleteStatus());
    }
  }, [searchProducts]);

  const loadProduts = (currentOffset, kitchenId, categoryId) => {
    if (currentOffset < offsetLimit) {
      const requestData = {
        token: localStorage.getItem('token'),
        offset: currentOffset,
        kitchensIds: [kitchenId],
        categoriesIds: [categoryId],
        ...searchData,
        typeData: [
          'products',
        ]
      }

      console.log('load', requestData)
      dispatch(sendRequestSearchProductsGet(requestData));
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Insert') {
        dispatch(setCurrentProductId(null));
        dispatch(setIsShowAssortmentDrawer(true));
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    };

    const callback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setOffset(prevOffset => prevOffset + 30);
          loadProduts(offsetRef.current, currentKitchen, currentCategory);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    if (anchorRef.current) {
      observer.observe(anchorRef.current);
    }

    return () => {
      if (anchorRef.current) {
        observer.unobserve(anchorRef.current);
      }
    };
  }, [currentCategory]);

  const finalyKitchens = searchMode ? filterCategoriesWithProducts(groupedCategories) : groupedCategories
  const productsWithKey = searchProducts.map(item => ({...item, key: item.id}));

  // console.log(finalyKitchens)

  const findProductById = (productId) => {
    return searchProducts.find((product) => product.id === productId);
  };

  const selectedProduct = currentProductId ? findProductById(currentProductId) : null;

  const handleChangeCategory = (kitchenId, id, offsetLimit) => {
    const requestData = {
      token: localStorage.getItem('token'),
      offset: 0,
      kitchensIds: [kitchenId],
      categoriesIds: [id],
      ...searchData,
      typeData: [
        'products',
      ]
    }

    dispatch(sendRequestSearchProductsGet(requestData));

    dispatch(setCurrentKitchen(kitchenId));
    dispatch(setCurrentCategory(id));
    setOffset(0);
    setOffsetLimit(offsetLimit);

    if (scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  }

  const handleSearch = (searchTerm, moderationFilter, priceFrom, priceTo) => {
    setOffset(0);
    setSearchData({
      ...searchData,
      search: searchTerm,
      enabledByAdmin: moderationFilter,
      priceFrom: priceFrom,
      priceTo: priceTo,
    });
  }

  const handleOpenCreateDrawer = () => {
    dispatch(setCurrentProductId(null));
    dispatch(setIsShowAssortmentDrawer(true));
  };

  function filterCategoriesWithProducts(data) {
    const filteredData = data.filter(item => {
      const filteredCategories = item.categories.filter(category => category.countProducts !== 0 || category.countModered !== 0);
      return filteredCategories.length > 0;
    });
  
    const result = filteredData.map(item => ({
      id: item.id,
      name: item.name,
      categories: item.categories.filter(category => category.countProducts !== 0 || category.countModered !== 0)
    }));
  
    return result;
  }

  return (
    <>
      {(
        searchProductsGetRequestStatus === 'pending'
      ) && <Loader />}

      <Flex gap={16}>
        <ScrolledCol width='300px' height='calc(100vh - 65px)'>
          <CategoryComponent type={isAdmin && 'admin'} kitchens={finalyKitchens} onChangeCategory={handleChangeCategory}/>
        </ScrolledCol>
        <Flex vertical>
          {currentCategory && finalyKitchens.length > 0 ? 
            <>
              <Title level={2}>{finalyKitchens.find(item => item.id === currentKitchen).name} - {finalyKitchens.find(item => item.id === currentKitchen).categories.find(item => item.id === currentCategory)?.name}</Title>

              <Flex gap={16} align='center'>
                <Button
                  type='primary'
                  shape="round"
                  icon={<PlusOutlined />}
                  onClick={handleOpenCreateDrawer}
                  style={{width: 'fit-content'}}
                >
                  Добавить продукт
                </Button>
                {/* <Search
                  placeholder="Поиск по названию или описанию"
                  value={kitchenSearchTerm}
                  onChange={(e) => setKitchenSearchTerm(e.target.value)}
                  onPressEnter={(e) => setSearchData({...searchData, search: e.target.value})}
                  style={{ maxWidth: '300px' }}
                /> */}
                <AssortmentSearchComponent type={isAdmin && 'admin'} onSearch={handleSearch}/>
              </Flex>
            </>
          :
            <Title level={2}>Ассортимент</Title>
          }

          {currentCategory ? 
            <ScrolledCol height='calc(100vh - 165px)'>
              <div ref={scrollRef}></div>
              <AssortmentTableComponent
                products={productsWithKey}
                currentProductId={currentProductId}
              />
              <div ref={anchorRef} style={{display: 'block', height: '50px', color: 'transparent'}}>...download</div>
            </ScrolledCol>
          :
            <Flex vertical align='center' justify='center' style={{height: '95%'}}>
              <Text>Выберите категорию для управления меню</Text>
            </Flex>
          }
        </Flex>
      </Flex>

      <AssortmentDrawerComponent
        product={selectedProduct}
        modifierGroups={modifierGroups}
      />

    </>
  );
}

export default AssortmentPage;