import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestCitiesGet,
} from '../../../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';
import {
  sendRequestDeliveryStatusesOrdersGet,
} from '../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Typography, Divider } from 'antd';
import Loader from '../../../../components/Loader';
import ClientsTableComponent from "./components/ClientsTableComponent";
import ClientsSearchFilterComponent from "./components/ClientsSearchFilterComponent";
import { Container, Page, ScrolledCol } from "../../../../core/styles";

const { Title } = Typography;

const ClientsSearchPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const {
    clientsGetRequestStatus,
    clients
  } = useSelector((store) => store.clientsSearch);
  const dispatch = useDispatch();
  // const [page, setPage] = useState(1);
  // console.log(page);
  // const scrolledColRef = useRef();

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestCitiesGet({ token: localStorage.getItem('token') }));
      dispatch(sendRequestDeliveryStatusesOrdersGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  // const handleScroll = () => {
  //   const scrolledCol = scrolledColRef.current;
  //   if (
  //     scrolledCol.scrollTop + scrolledCol.clientHeight >= scrolledCol.scrollHeight
  //   ) {
  //     console.log(scrolledCol.scrollTop, scrolledCol.clientHeight, scrolledCol.scrollHeight);
  //     setPage(page + 1);
  //     console.log("Достигнут конец содержимого ScrolledCol");
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [page]);

  // useEffect(() => {
  //   if (page > 1) {
  //     const requestData = {
  //       token: localStorage.getItem('token'),
  //       ...requestValues,
  //       offset: page * 30,
  //       limit: 30,
  //     }
  
  //     console.log(requestData);
  //     dispatch(sendRequestClientsGet(requestData));
  //   }
  // }, [page]);

  return (
    <Page>
      {(
        clientsGetRequestStatus === 'pending'
      ) && <Loader />}

      <ScrolledCol>
        <Container>
          <Title level={2}>Поиск клиентов</Title>
          <ClientsSearchFilterComponent/>
          <Divider/>
          <ClientsTableComponent/>
        </Container>
      </ScrolledCol>
    </Page>
  )
}

export default ClientsSearchPage;