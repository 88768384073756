import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getKitchensSettings, updateKitchensSettings } from '../../../../../redux/reducers/utils/kitchenReducers/kitchenSettingsReducer';
import styled from 'styled-components';
import { Form, Input, Button, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import InputMask from 'react-input-mask';
import { Text, Title } from '../../../../../core/styles';
import Flex from '../../../../../components/Flex';
import Card from '../../../../../components/Card';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

const SettingsForm = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const { selectKitchen } = useSelector((store) => store.kitchen);
  const { kitchensSetting } = useSelector((store) => store.kitchenSettings);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isModified, setIsModified] = useState(false);
  const [coordinates, setCoordinates] = useState([53.52730593794812, 49.35939594058916]);

  useEffect(() => {
    if (kitchensSetting && kitchensSetting.kitchenSettings) {
      const modifyValues = Object.fromEntries(
        Object.entries(kitchensSetting.kitchenSettings).map(([key, value]) => {
          if (key === 'kitchenAccessNumbers' || key === 'testerNumbers') {
            return [key, value ? (typeof value === 'string' ? value.split(';') : value) : []];
          } else {
            return [key, value || ''];
          }
        })
      );

      if (kitchensSetting.kitchenSettings.latitude, kitchensSetting.kitchenSettings.longitude) {
        setCoordinates([kitchensSetting.kitchenSettings.latitude, kitchensSetting.kitchenSettings.longitude]);
      }
      form.setFieldsValue(modifyValues);
    }
  }, [kitchensSetting]);

  const handleValuesChange = () => {
    setIsModified(true);
  };

  const handleMapClick = (e) => {
    const coords = e.get('coords');
    setCoordinates(coords);
    form.setFieldsValue({ mapPoint: coords });
    handleValuesChange();
  };

  const handleSave = () => {
    form.validateFields()
      .then(values => {
        return dispatch(updateKitchensSettings({
          token: localStorage.getItem('token'),
          kitchenId: selectKitchen.id,
          ...values,
          longitude: String(coordinates[1]),
          latitude: String(coordinates[0])
        }));
      })
      .then(() => {
        return dispatch(getKitchensSettings({
          token: localStorage.getItem('token'),
          kitchenId: selectKitchen.id
        }));
      })
      .then(() => {
        setIsModified(false);
      })
      .catch(info => {
        console.log('Ошибка при сохранении:', info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModified(false);
    setCoordinates([53.52730593794812, 49.35939594058916]);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={handleValuesChange}
      initialValues={{
        orgName: '',
        bankBik: '',
        bankName: '',
        contactPhone: '',
        factAddress: '',
        ks: '',
        rs: '',
        urAddress: '',
        inn: '',
        kpp: '',
        emailOrders: '',
        emailDocuments: '',
        emailReviews: '',
        kitchenAddress: '',
        kitchenPhone: '',
        vkGroup: '',
        instagram: '',
        website: '',
        testerNumbers: [],
        kitchenAccessNumbers: [],
      }}
    >
      <Flex
        column
        gap={'32px'}
      >
        {/* Реквизиты для договора и выплат */}
        <Card title={<Title level={4}>Реквизиты для договора и выплат</Title>}>
          <Flex gap='20px' wrap='wrap'>
            <Form.Item style={{flex: '1 1 330px'}} label="Название организации или ИП" name="orgName">
              <Input placeholder="Введите название организации или ИП" disabled={!kitchensSetting?.column?.includes("orgName")}/>
            </Form.Item>
            <Form.Item style={{flex: '1 1 330px'}} label="Контактный номер" name="contactPhone">
              <Input placeholder="Введите контактный номер" disabled={!kitchensSetting?.column?.includes("contactPhone")}/>
            </Form.Item>
            <Form.Item style={{flex: '1 1 330px'}} label="ИНН" name="inn">
              <Input placeholder="Введите ИНН" disabled={!kitchensSetting?.column?.includes("inn")}/>
            </Form.Item>
            <Form.Item style={{flex: '1 1 330px'}} label="КПП" name="kpp">
              <Input placeholder="Введите КПП" disabled={!kitchensSetting?.column?.includes("kpp")}/>
            </Form.Item>

            <Form.Item style={{flex: '1 1 330px'}} label="Расчетный счет" name="rs">
              <Input placeholder="Введите расчетный счет" disabled={!kitchensSetting?.column?.includes("rs")}/>
            </Form.Item>
            <Form.Item style={{flex: '1 1 330px'}} label="Корреспондентский счет" name="ks">
              <Input placeholder="Введите корреспондентский счет" disabled={!kitchensSetting?.column?.includes("ks")}/>
            </Form.Item>
            <Form.Item style={{flex: '1 1 330px'}} label="Фактический адрес" name="factAddress">
              <Input placeholder="Укажите фактический адрес" disabled={!kitchensSetting?.column?.includes("factAddress")}/>
            </Form.Item>
            <Form.Item style={{flex: '1 1 330px'}} label="Юридический адрес" name="urAddress">
              <Input placeholder="Укажите юридический адрес" disabled={!kitchensSetting?.column?.includes("urAddress")}/>
            </Form.Item>

            <Form.Item style={{flex: '1 1 330px'}} label="Название банка" name="bankName">
              <Input placeholder="Введите название банка" disabled={!kitchensSetting?.column?.includes("bankName")}/>
            </Form.Item>

            <Form.Item style={{flex: '1 1 330px'}} label="Бик банка" name="bankBik">
              <Input placeholder="Введите бик банка" disabled={!kitchensSetting?.column?.includes("bankBik")}/>
            </Form.Item>
          </Flex>
        </Card>

        {/* Электронная почта */}
        <Card title={<Title level={4}>Электронная почта для:</Title>}>
          <Flex gap='20px' wrap='wrap'>
            <Form.Item style={{flex: '1 1 330px'}} label="Новых заказов" name="emailOrders" rules={[{ type: 'email', message: 'Введите корректный email' }]}>
              <Input placeholder="Введите email для новых заказов" disabled={!kitchensSetting?.column?.includes("emailOrders")}/>
            </Form.Item>
            <Form.Item style={{flex: '1 1 330px'}} label="Актов и иных документов" name="emailDocuments" rules={[{ type: 'email', message: 'Введите корректный email' }]}>
              <Input placeholder="Введите email для актов и документов" disabled={!kitchensSetting?.column?.includes("emailDocuments")}/>
            </Form.Item>
            <Form.Item style={{flex: '1 1 330px'}} label="Отзывов" name="emailReviews" rules={[{ type: 'email', message: 'Введите корректный email' }]}>
              <Input placeholder="Введите email для отзывов" disabled={!kitchensSetting?.column?.includes("emailReviews")}/>
            </Form.Item>
          </Flex>
        </Card>

        <Flex gap={'32px'} wrap={'wrap'}>
          <div style={{flex: '3 1 330px'}}>
            {/* Информация о кухне */}
            <Card title={<Title level={4}>Информация о кухне (то что видно клиенту)</Title>}>
              <Flex gap='20px' wrap='wrap'>
                <Form.Item style={{flex: '1 1 330px', minWidth: '100%'}} label="Адрес" name="kitchenAddress">
                  <Input placeholder="Введите адрес кухни" disabled={!kitchensSetting?.column?.includes("kitchenAddress")}/>
                </Form.Item>
                <Form.Item style={{flex: '1 1 330px'}} label="Телефон" name="kitchenPhone">
                  <InputMask mask="+7 (999) 999-99-99" maskChar={null} disabled={!kitchensSetting?.column?.includes("kitchenPhone")}>
                    {(inputProps) => <Input {...inputProps} maxLength={18} placeholder="Введите телефон кухни" disabled={!kitchensSetting?.column?.includes("kitchenPhone")}/>}
                  </InputMask>
                </Form.Item>
                <Form.Item style={{flex: '1 1 330px'}} label="Группа ВК" name="vkGroup">
                  <Input placeholder="Ссылка на группу ВК" disabled={!kitchensSetting?.column?.includes("vkGroup")}/>
                </Form.Item>
                <Form.Item style={{flex: '1 1 330px'}} label="Инстаграм" name="instagram">
                  <Input placeholder="Ссылка на инстаграм" disabled={!kitchensSetting?.column?.includes("instagram")}/>
                </Form.Item>
                <Form.Item style={{flex: '1 1 330px'}} label="Сайт" name="website">
                  <Input placeholder="Ссылка на сайт" disabled={!kitchensSetting?.column?.includes("website")}/>
                </Form.Item>
                <Flex style={{width: '100%'}} justify={'flex-end'}>{coordinates[0]}, {coordinates[1]}</Flex>
                <YMaps>
                  <Map
                    defaultState={{ center: coordinates, zoom: 10 }}
                    onClick={handleMapClick}
                    style={{ width: '100%', height: '400px' }}
                  >
                    <Placemark geometry={coordinates} />
                  </Map>
                </YMaps>
              </Flex>
            </Card>
          </div>
          <div style={{flex: '1 1 330px'}}>
            {/* Настройки кухни */}
            <Card title={<Title level={4}>Настройки кухни</Title>}>
              <Flex gap='20px' wrap='wrap'>
                {/* Номера тестировщиков */}
                <Form.List name="testerNumbers">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field) => (
                        <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                          <Form.Item
                            style={{marginBottom: '0', flex: '1 1 330px'}}
                            {...field}
                            rules={[{ required: true, message: 'Введите номер телефона' }]}
                          >
                            <InputMask mask="+7 (999) 999-99-99" maskChar={null} disabled={!kitchensSetting?.column?.includes("testerNumbers")}>
                              {(inputProps) => <Input {...inputProps} maxLength={18} placeholder="Введите телефон тестировщика" disabled={!kitchensSetting?.column?.includes("testerNumbers")}/>}
                            </InputMask>
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} icon={<PlusOutlined/>} disabled={!kitchensSetting?.column?.includes("testerNumbers")} style={{ width: 'auto', height: 'auto', whiteSpace: 'normal' }}>
                          Добавить номер тестировщика
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>

                {/* Номера телефонов кому доступна кухня */}
                <Form.List name="kitchenAccessNumbers">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field) => (
                        <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                          <Form.Item
                            style={{marginBottom: '0', flex: '1 1 330px'}}
                            {...field}
                            rules={[{ required: true, message: 'Введите номер телефона' }]}
                          >
                            <InputMask mask="+7 (999) 999-99-99" maskChar={null} disabled={!kitchensSetting?.column?.includes("kitchenAccessNumbers")}>
                              {(inputProps) => <Input {...inputProps} maxLength={18} placeholder="Введите номер телефона" disabled={!kitchensSetting?.column?.includes("kitchenAccessNumbers")}/>}
                            </InputMask>
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} icon={<PlusOutlined/>} disabled={!kitchensSetting?.column?.includes("kitchenAccessNumbers")} style={{ width: 'auto', height: 'auto', whiteSpace: 'normal' }}>
                          Добавить номер телефона кому доступна кухня
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Flex>
            </Card>
          </div>
        </Flex>

        {isModified && (
          <ActionWrapper>
              <Title level={5}>Сохранить изменения?</Title>
              <Flex gap='20px'>
                  <Button type="primary" onClick={handleSave}>Сохранить</Button>
                  <Button onClick={handleCancel}>Отменить</Button>
              </Flex>
          </ActionWrapper>
        )}
      </Flex>
    </Form>
  );
};

export default SettingsForm;

const ActionWrapper = styled.div`
    position: fixed;
    bottom: 100px;
    right: 100px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: fit-content;
    padding: 24px;
    background: #ffffff;
    border-radius: 24px;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, .15);
`