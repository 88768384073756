import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAdminsSettings, updateAdminsSettings } from '../../../../../redux/reducers/utils/kitchenReducers/kitchenSettingsReducer';
import styled from 'styled-components';
import { Form, Button, InputNumber } from 'antd';
import { Title } from '../../../../../core/styles';
import Flex from '../../../../../components/Flex';
import Card from '../../../../../components/Card';

const AdminsSettingsForm = () => {
  const { selectKitchen } = useSelector((store) => store.kitchen);
  const { adminsSetting } = useSelector((store) => store.kitchenSettings);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    if (adminsSetting) {
      form.setFieldsValue(adminsSetting);
    }
  }, [adminsSetting]);

  const handleValuesChange = () => {
    setIsModified(true);
  };

  const handleSave = () => {
  form.validateFields()
    .then(values => {
      const stringifiedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [key, typeof value === 'number' ? String(value) : value])
      );

      return dispatch(updateAdminsSettings({
        token: localStorage.getItem('token'),
        kitchenId: selectKitchen.id,
        ...stringifiedValues
      }));
    })
    .then(() => {
      return dispatch(getAdminsSettings({ token: localStorage.getItem('token'), kitchenId: selectKitchen.id }));
    })
    .then(() => {
      setIsModified(false);
    })
    .catch(info => {
      console.log('Ошибка при сохранении:', info);
    });
};

  const handleCancel = () => {
    form.resetFields();
    setIsModified(false);
  };

  return (
    <Form
      style={{flex: '1 1 330px'}}
      form={form}
      onValuesChange={handleValuesChange}
      initialValues={adminsSetting}
    >
      <Flex
        column
        gap={'32px'}
      >
        <Card title={<Title level={4}>Ограничения</Title>}>
          <Flex column gap='20px' wrap='wrap'>
            <Form.Item label="Ограничения по кол-ву заказов за рейс" name="quantityRestrictionsOrders">
              <InputNumber style={{minWidth: '120px'}} placeholder="0"/>
            </Form.Item>
            <Form.Item label="Ограничения по сумме заказов за рейс" name="amountRestrictionsOrders">
              <InputNumber style={{minWidth: '120px'}} placeholder="0"/>
            </Form.Item>
            <Form.Item label="Рейтинг кухни от сервиса" name="adminsKitchenRating">
              <InputNumber style={{minWidth: '120px'}} placeholder="5.0" min={0} max={5}/>
            </Form.Item>
          </Flex>
        
          <ActionWrapper>
            {isModified && (
              <>
                <Button type="primary" onClick={handleSave}>Сохранить</Button>
                <Button onClick={handleCancel}>Отменить</Button>
              </>
            )}
          </ActionWrapper>
        </Card>
      </Flex>
    </Form>
  );
};

export default AdminsSettingsForm;

const ActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    height: 35px;
`