import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { MoneyCheckIcon, MoneyTimeIcon, MoneyChangeIcon } from '../../../../../components/Icons';
import { Text, Title } from '../../../../../core/styles';
import Card from '../../../../../components/Card';
import Flex from '../../../../../components/Flex';
import Tooltip from '../../../../../ui/Tooltip';

const MainStats = () => {
    return (
        <Tooltip title={'Скоро'} text={'Этот блок пока в разработке, но скоро он станет рабочим'}>
            <Wrapper disabled>
                <StatCard>
                    <Flex align='center' gap='16px'>
                        <IconWrapper bg='#D9F4EC'>
                            <MoneyCheckIcon color='#49BC9A'/>
                        </IconWrapper>
                        <Flex column>
                            <Text style={{textWrap: 'nowrap'}} secondary>Ваша прибыль</Text>
                            <Title>-</Title>
                        </Flex>
                    </Flex>
                </StatCard>
                <StatCard>
                    <Flex align='center' gap='16px'>
                        <IconWrapper bg='#FFEAD2'>
                            <MoneyTimeIcon color='#FF8800'/>
                        </IconWrapper>
                        <Flex column>
                            <Text style={{textWrap: 'nowrap'}} secondary>Акты на подпись</Text>
                            <Title>-</Title>
                        </Flex>
                    </Flex>
                </StatCard>
                <StatCard>
                    <Flex align='center' gap='16px'>
                        <IconWrapper bg='#FFEAEA'>
                            <MoneyChangeIcon color='#FF4444'/>
                        </IconWrapper>
                        <Flex column>
                            <Text style={{textWrap: 'nowrap'}} secondary>Претензий</Text>
                            <Title>-</Title>
                        </Flex>
                    </Flex>
                </StatCard>
            </Wrapper>
        </Tooltip>
    )
}

export default MainStats;

const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    filter: ${props => props.disabled && 'grayscale(1) brightness(1) contrast(0.8)'}
`

const StatCard = styled(Card)`
    flex: 1 1 250px;
    padding: 24px;
`

const IconWrapper = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${(props) => props.bg && props.bg};
    border-radius: 48px;
`