import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getNews,
  createNews,
  updateNews,
  deleteNews,
} from '../../../../../redux/reducers/utils/kitchenReducers/kitchenDashboardReducer';
import { Button, Modal, Form, Input, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Text, Title } from '../../../../../core/styles';
import styled from 'styled-components';
import { checkAccess } from '../../../../../core/utils';
import moment from 'moment';
import Card from '../../../../../components/Card';
import Flex from '../../../../../components/Flex';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { ArrowBackIcon, ArrowForwardIcon } from '../../../../../components/Icons';

const { TextArea } = Input;

const NewsComponent = ({carousel = false}) => {
  const { isAuth } = useSelector((store) => store.auth);
  const { accessComponentsForUser } = useSelector((store) => store.services);
  const {
    news,
    newsCreateRequestStatus,
    newsUpdateRequestStatus,
    newsDeleteRequestStatus,
  } = useSelector((store) => store.kitchenDashboard);
  const dispatch = useDispatch();
  const [ isCreate, setIsCreate] = useState(false);
  const [ currentPost, setCurrentPost] = useState(null);
  const [form] = Form.useForm();
  const isAdmin = checkAccess(accessComponentsForUser, 'moderation');
  const swiperRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    if (isAuth) {
      dispatch(getNews({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  useEffect(() => {
    if (
      newsCreateRequestStatus === 'resolved' ||
      newsUpdateRequestStatus === 'resolved' ||
      newsDeleteRequestStatus === 'resolved'
    ) {
      dispatch(getNews({ token: localStorage.getItem('token') }));
    }
  }, [
    newsCreateRequestStatus,
    newsUpdateRequestStatus,
    newsDeleteRequestStatus,
  ]);

  const handleCancel = () => {
    setIsCreate(false);
    setCurrentPost(null);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      title: values.title,
      body: values.body,
    }

    if (currentPost) {
      dispatch(updateNews({...requestData, newsAdminId: currentPost.id}));
    } else {
      dispatch(createNews(requestData));
    }

    setIsCreate(false);
    form.resetFields();
  };

  const handleCreate = () => {
    setCurrentPost(null);
    setIsCreate(true);
  }

  const handleUpdate = (post) => {
    setCurrentPost(post);
    setIsCreate(true);
    form.setFieldsValue(post);
  }

  const handleDelete = (id) => {
    const requestData = {
      token: localStorage.getItem('token'),
      newsAdminId: id,
    }

    dispatch(deleteNews(requestData));
    setIsCreate(false);
    form.resetFields();
  }

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goToSlide = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  return (
    <>
      {carousel === true ? 
        <NewsCard>
          {isAdmin && 
            <Flex justify='flex-end'>
              <Button type='primary' shape="circle" icon={<PlusOutlined/>} onClick={handleCreate}/>
            </Flex>
          }
          <SwiperWrapper>
            <Swiper
              ref={swiperRef}
              style={{ width: '100%', height: '100%' }}
              spaceBetween={10}
              slidesPerView={1}
              scrollbar={{ draggable: true }}
              onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex)}
            >
              {news &&
                news
                  .slice()
                  .sort((a, b) => new Date(b.created) - new Date(a.created))
                  .map(post => (
                    <SwiperSlide key={post.id}>
                      <News key={post.id} post={post} isAdmin={isAdmin} onUpdate={handleUpdate} onDelete={handleDelete} />
                    </SwiperSlide>
                  ))
              }
            </Swiper>
            <SwiperNav>
              <SwiperButton onClick={handlePrev}><ArrowBackIcon/></SwiperButton>
              <Dots>
                {news && news.map((_, index) => (
                  <Dot
                    key={index}
                    isActive={activeSlide === index}
                    onClick={() => goToSlide(index)}
                  />
                ))}
              </Dots>
              <SwiperButton onClick={handleNext}><ArrowForwardIcon/></SwiperButton>
            </SwiperNav>
          </SwiperWrapper>
        </NewsCard>
      : 
        <Flex column gap={'16px'} style={{height: '100%'}}>
          {news &&
            news
              .slice(0, 3)
              .sort((a, b) => new Date(b.created) - new Date(a.created))
              .map(post => (
                <NewsCard key={post.id}>
                  <News key={post.id} post={post} onUpdate={handleUpdate} onDelete={handleDelete} />
                </NewsCard>
              ))
          }
        </Flex>
      }
      <Modal
        title="Создание новости"
        open={isCreate}
        onCancel={handleCancel}
        footer={null}
      >
        <Form style={{ width: '100%' }} form={form} name='deliveryService' layout='vertical' onFinish={handleSubmit}>
          <Form.Item label='Заголовок' name='title' rules={[{ required: true, message: 'Введите название' }]}>
            <Input placeholder='Заголовок'/>
          </Form.Item>
          <Form.Item label='Текст' name='body' rules={[{ required: true, message: 'Введите текст' }]}>
            <TextArea rows={2} placeholder='Текст новости'/>
          </Form.Item>
          <Button type='primary' htmlType='submit' style={{ display: 'flex', marginLeft: 'auto' }}>
            {currentPost !== null ? 'сохранить' : 'Создать'}
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default NewsComponent;

const News = ({ post, isAdmin, onUpdate, onDelete }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleClickUpdate = () => {
    onUpdate(post);
  };

  const handleClickDelete = () => {
    onDelete(post.id);
  };

  return (
    <Flex style={{margin: '10px 0'}} column gap='16px'>
      <Flex justify='space-between' gap='16px'>
        <Title level={3}>{post.title}</Title>
        <Flex column align='flex-end' flex='0 0 fit-content'>
          <Text size='12px' color='#B2B2B2'>{moment(post.created).format('DD-MM-YYYY')}</Text>
          <Text size='12px' color='#B2B2B2'>{moment(post.created).format('HH:mm')}</Text>
        </Flex>
      </Flex>
      <Flex style={{minHeight: '64px'}}>
        <Text>
          {post.body.length > 100 ?
            <>
              {expanded ? post.body : `${post.body.slice(0, 100)}...`}
              {expanded ? (
                <Text link onClick={toggleExpanded}> cвернуть</Text>
              ) : (
                <Text link onClick={toggleExpanded}> читать полностью</Text>
              )}
            </>
            :
            <>
              {post.body}
            </>
          }
        </Text>
      </Flex>

      {isAdmin && 
        <Flex
          justify='flex-end'
          gap='10px'
        >
          <Button onClick={handleClickUpdate}>Редактировать</Button>
          <Popconfirm
            title="Удаление поста!"
            description={`Вы действительно хотите удалить пост?`}
            okText="Да"
            cancelText="Нет"
            placement="left"
            onConfirm={handleClickDelete}
          >
            <Button danger={true}>Удалить</Button>
          </Popconfirm>
        </Flex>
      }
    </Flex>
  );
};

const NewsCard = styled(Card)`
  width: 100%;
  min-width: 330px;
  height: 100%;
`
const SwiperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`
const SwiperNav = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  flex-wrap: nowrap;
`
const SwiperButton = styled.button`
  height: 24px;
  color: #B2B2B2;
`
const Dots = styled.div`
  display: flex;
  gap: 8px;
`
const Dot = styled.button`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${({ isActive }) => (isActive ? '#2A2A2A' : '#D9D9D9')};
  border: ${({ isActive }) => (isActive ? 'solid 1px #2A2A2A' : 'solid 1px transparent')};;
  cursor: pointer;
  transition: all .3s;
  
  &:hover {
    background: #2A2A2A;
  }
`