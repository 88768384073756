import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Flex from '../../../../../components/Flex';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { ArrowBackIcon, ArrowForwardIcon, BarChartsIcon, CalendarIcon, ClockIcon, DishIcon, DislikeIcon, DownloadIcon, EyeIcon, FireIcon, LikeIcon, ListIcon, OkIcon, PlanshetIcon, SettingsIcon, SupportIcon, TimeIcon, UsertimeIcon, WarningIcon, WeekendIcon } from '../../../../../components/Icons';
import { CardSection, Text, Title } from '../../../../../core/styles';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../../../../components/Button';
import { Divider, Dropdown, theme } from 'antd';
import { getCalendarMenu } from '../../../../../redux/reducers/utils/kitchenReducers/kitchenDashboardReducer';
import moment from 'moment';
import { sendRequestCreateMenu, setCurrentRace } from '../../../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import Tooltip from '../../../../../ui/Tooltip';

const DaysStatistics = () => {
    const { serverTime } = useSelector((store) => store.common);
    const { selectKitchen } = useSelector((store) => store.kitchen);
    const { calendarMenuGetRequestStatus, calendarMenu } = useSelector((store) => store.kitchenDashboard);
    const dispatch = useDispatch();
    const swiperRef = useRef(null);

    const generateDeliveryDays = () => {
        const startDay = moment().subtract(1, 'days');
        const deliveryDays = [];
    
        for (let i = 0; i <= 6; i++) {
            deliveryDays.push(startDay.clone().add(i, 'days').format('YYYY-MM-DD'));
        }
    
        return deliveryDays;
    };
    
    const deliveryDays = generateDeliveryDays();

    useEffect(() => {
        if(selectKitchen) {
            dispatch(getCalendarMenu({token: localStorage.getItem('token'), kitchenId: selectKitchen.id, deliveryDays: deliveryDays}));
        }
    }, [selectKitchen]);

    const handlePrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };
    
    const handleNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    return (
        <SwiperWrapper>
            <Flex justify={'space-between'} align={'center'} gap={'32px'} style={{width: '100%', marginBottom: '16px'}}>
                <Title level={2} weight='400' color={'#858080'}>Публикация меню</Title>
                <SwiperNav>
                    <SwiperButton onClick={handlePrev}><ArrowBackIcon width='32px' height='32px'/></SwiperButton>
                    <SwiperButton onClick={handleNext}><ArrowForwardIcon width='32px' height='32px'/></SwiperButton>
                </SwiperNav>
            </Flex>
            <Swiper
                ref={swiperRef}
                style={{ width: '100%', height: '100%' }}
                spaceBetween={24}
                slidesPerView={'auto'}
                scrollbar={{ draggable: true }}
            >   
                {calendarMenu && calendarMenu.length > 0 && calendarMenu.map((day) => {
                    const formattedDate = moment(day.deliveryDay).format('dddd, D MMM');
                    const isYesterday = moment(day.deliveryDay).isSame(moment().subtract(1, 'days'), 'day');
                    const isToday = moment(day.deliveryDay).isSame(moment().subtract(0, 'days'), 'day');

                    return (
                        <StyledSlide key={day.deliveryDay}>
                            <DaysStatisticsBlock>
                                <DaysStatisticsTitle>{formattedDate} {isYesterday && <span>Вчера</span>} {isToday && <span>Сегодня</span>}</DaysStatisticsTitle>
                                
                                {day.status.statusId === 1 && day.races && day.races.length > 0 ? (//Работает
                                    day.races.map((race) => (
                                        <RaceBlock key={race.raceId} day={day.deliveryDay} race={race} serverTime={serverTime}/>
                                    ))
                                ) : day.status.statusId === 2 ? (//Не настроена (нет рейсов)
                                    <CardSection style={{alignItems: 'center'}}>
                                        <SupportIcon width='60px' height='60px' color='#E0E0E0'/>
                                        <Flex column align='center' gap='5px'>
                                            <Text size='16px' color='#858080' style={{marginBottom: '4px'}}>Вы не можете опубликовать меню</Text>
                                            <Text size='14px' color='#B2B2B2'>Не завершена настройка. Кухня не подключена к службе доставки. Обратитесь к своему менеджеру</Text>
                                        </Flex>
                                    </CardSection>
                                ) : day.status.statusId === 3 ? (//Не работает кухня
                                    <CardSection style={{alignItems: 'center'}}>
                                        <WeekendIcon width='120px' height='60px' color='#E0E0E0'/>
                                        <Flex column align='center' gap='5px'>
                                            <Text size='16px' color='#858080' style={{marginBottom: '4px'}}>Кухня не работает в этот день</Text>
                                            <Text size='14px' color='#B2B2B2'>Санитарный день, обработка кухни</Text>
                                        </Flex>
                                    </CardSection>
                                ) : day.status.statusId === 4 ? (//Нет продуктов
                                    <CardSection style={{alignItems: 'center'}}>
                                        <DishIcon width='60px' height='60px' color='#E0E0E0'/>
                                        <Flex column align='center' gap='5px'>
                                            <Text size='16px' color='#858080' style={{marginBottom: '4px'}}>Отсутствуют товары в списке</Text>
                                            <Text size='14px' color='#B2B2B2'>Пополните ваш справочник продукции</Text>
                                            <StyledLink to={'/kitchen/assortment'}>Добавить товары <ListIcon width='16px' height='16px'/></StyledLink>
                                        </Flex>
                                    </CardSection>
                                ) : day.status.statusId === 5 ? (//Не работает служба доставки
                                    <CardSection style={{alignItems: 'center'}}>
                                        <CalendarIcon width='60px' height='60px' color='#E0E0E0'/>
                                        <Flex column align='center' gap='5px'>
                                            <Text size='16px' color='#858080' style={{marginBottom: '4px'}}>Служба доставки в этот период не работает</Text>
                                            <Text size='14px' color='#B2B2B2'>Опубликовать меню невозможно</Text>
                                        </Flex>
                                    </CardSection>
                                ) : null }

                            </DaysStatisticsBlock>
                        </StyledSlide>
                    )
                })}
            </Swiper>
        </SwiperWrapper>
    );
};

export default DaysStatistics;

const RaceBlock = ({day, race, serverTime}) => {
    const navigate = useNavigate();
    const { selectKitchen } = useSelector((store) => store.kitchen);
    const dispatch = useDispatch();
    const [statsShow, setStatsShow] = useState(false);
    const timezone = race.timezoneCity;
    const adjustedTimeStart = moment(race.timeStart, "HH:mm").add(timezone, 'hours').format("HH:mm");
    const adjustedTimeEnd = moment(race.timeEnd, "HH:mm").add(timezone, 'hours').format("HH:mm");
    const [haveMenu, setHaveMenu] = useState(false);

    useEffect(() => {
        setHaveMenu(race.isWorkRace && race.menu.length > 0);
    }, [race]);

    const handleStatsShowClick = () => {
        setStatsShow(!statsShow)
    }

    const handleOpenMenu = () => {
        const currentRace = {
            deliveryDay: day,
            kitchenId: selectKitchen.id,
            kitchenName: selectKitchen.name,
            ...race
        }

        dispatch(setCurrentRace(currentRace));
        navigate('/kitchen/menu');
    }

    return (
        <RaceCard>
            <RaceCardHeader>
                <Flex column>
                    <Flex justify={'space-between'} gap={'16px'} style={{height:'40px'}}>
                        <Flex align={'center'} gap={'10px'} style={{textWrap: 'nowrap'}}>
                            <Title level={2} color={race.isWorkRace && race.menu.length === 0 && '#FF4154'}>{adjustedTimeStart}-{adjustedTimeEnd}</Title>
                            {race.isWorkRace && race.menu.length === 1 &&
                                <>
                                    <div style={{color: '#cccccc'}}>|</div>
                                    <Tooltip title={'Ожидайте курьера'} text={'Он подъедет в это время'}>
                                        <Flex align={'center'} gap={'8px'}>
                                            <UsertimeIcon color={'#FF8800'}/>
                                            <Title level={2} color={'#FF8800'}>10:15</Title>
                                        </Flex>
                                    </Tooltip>
                                </>
                            }
                        </Flex>
                        {!haveMenu ?
                            <StyledRedButton size={'small'} onClick={handleOpenMenu}>Создать меню <PlanshetIcon/></StyledRedButton>
                        :
                            <StyledWhiteButton size={'small'} onClick={handleOpenMenu}>Открыть меню <EyeIcon/></StyledWhiteButton>
                        }
                    </Flex>
                    {!haveMenu ?
                        <Text color={'#FF4154'}>Меню не опубликовано</Text>
                    :
                        <Text>Меню опубликовано</Text>
                    }
                </Flex>
            </RaceCardHeader>

            <RaceInfo>
                {race.periods && race.periods.length > 0 ?
                    <Flex column gap={'10px'} style={{width: '100%'}}>
                        {race.periods.map((period) => (
                            <Period period={period} day={day} serverTime={serverTime} timezone={timezone}/>
                        ))}
                    </Flex>
                : race.isWorkRace && race.menu.length === 0 ?
                    <InfoTitle>
                        <TimeIcon/>
                        <Text size='14px'>Время вышло, рекомендуем<br/>публиковать меню заранее</Text>
                    </InfoTitle>
                : race.isWorkRace && race.menu.length === 0 ?
                    <InfoTitle>
                        <WarningIcon/>
                        <Text size='14px'>Меню пока никто не<br/>опубликовал, будьте первыми</Text>
                    </InfoTitle>
                : null}
            </RaceInfo>

            {race.statistics &&
                <>
                    <InfoButton onClick={handleStatsShowClick}>
                        <DownOutlined style={{transform: statsShow && 'rotate(180deg)', transition: 'all .3s'}}/>
                        <BarChartsIcon width={'16px'} height={'16px'}/>
                        <p>подробно</p>
                    </InfoButton>

                    <div style={{height: statsShow ? '150px' : 0, overflow: 'hidden', transition: 'all .3s'}}>
                        <RaceStats>
                            <StatsBlock>
                                <h2>Всего<br/>заказали</h2>
                                <p>{race.statistics.orders.allOrders}</p>
                            </StatsBlock>
                            <StatsBlock>
                                <h2>Новые<br/>позиции</h2>
                                <p>{race.statistics.positions.new}</p>
                            </StatsBlock>
                            <StatsBlock danger={true}>
                                <h2>Полностью<br/>распроданы</h2>
                                <p>{race.statistics.positions.completelySoldOut}</p>
                            </StatsBlock>
                            <StatsBlock>
                                <h2>Общая<br/>сумма</h2>
                                <p>{race.statistics.orders.allSum} Р</p>
                            </StatsBlock>
                        </RaceStats>

                        <RaceLikes>
                            <RaceLike><LikeIcon/> {race.statistics.reviews.countGood}</RaceLike>
                            <RaceUnlike><DislikeIcon/> {race.statistics.reviews.countBad}</RaceUnlike>
                        </RaceLikes>
                    </div>
                </>
            }
        </RaceCard>
    )
}

export const Period = ({period, day, serverTime, timezone}) => {
    const { useToken } = theme;
    const { token } = useToken();
    const items = [
        {
            key: '1',
            label: (
                <div>
                    Распечатать чек-листы
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div>
                    Распечатать производственный заказ
                </div>
            ),
        },
        {
            key: '3',
            label: (
                <div>
                    Распечатать Акт приёма-передачи
                </div>
            ),
        },
    ];
    const contentStyle = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
    };
    const menuStyle = {
        boxShadow: 'none',
    };

    const [currentServerTime, setCurrentServerTime] = useState(moment(serverTime));
    const orderExpDateTime = moment(`${day} ${period.orderExpDate}`, "YYYY-MM-DD HH:mm");
    const clientServerTime = moment(currentServerTime).add(timezone, 'hours');
    const clientOrderExpDateTime = moment(`${day} ${period.orderExpDate}`, "YYYY-MM-DD HH:mm").add(timezone, 'hours');
    const [timeRemaining, setTimeRemaining] = useState(null);

    useEffect(() => {
        const timer = setInterval(() => {
            const updatedServerTime = moment(currentServerTime).add(1, 'second');
            setCurrentServerTime(updatedServerTime);

            const diffMinutes = orderExpDateTime.diff(updatedServerTime, 'minutes');
            if (diffMinutes < 30 && diffMinutes >= 0) {
                setTimeRemaining(orderExpDateTime.diff(updatedServerTime, 'seconds'));
            } else {
                setTimeRemaining(null);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [currentServerTime, orderExpDateTime]);

    const formatRemainingTime = () => {
        if (timeRemaining === null) return null;
        const minutes = Math.floor(timeRemaining / 60);
        const seconds = timeRemaining % 60;
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const isExpired = currentServerTime.isAfter(orderExpDateTime);

    return (
        <Flex justify={'space-between'} align={'center'} gap={'16px'} style={{width: '100%', minHeight: '32px'}}>
            <InfoTitle>
                {isExpired ? <OkIcon/> : <ClockIcon/>}
                <Text size='14px' ellipsis>{period.kitchenRacePolygons[0].polygonName}</Text>
            </InfoTitle>
            {isExpired ? 
                <Dropdown
                    menu={{ items }}
                    trigger={['click']}
                    placement='bottomRight'
                    dropdownRender={(menu) => (
                        <div style={contentStyle}>
                        {React.cloneElement(menu, {
                            style: menuStyle,
                        })}
                        <Divider
                            style={{
                            margin: 0,
                            }}
                        />
                        <Flex justify={'space-between'} align={'flex-start'} gap={'16px'} style={{padding: '4px 12px'}}>
                            <div>
                                <Text secondary size='14px'>Документы отправлены на почту:</Text>
                                <Text secondary size='14px'>zakaz@kuhna.ru, bugalter@yandex.ru</Text>
                            </div>
                            <StyledIconLink to={'/kitchen/settings'}><SettingsIcon width={'20px'} height={'20px'}/></StyledIconLink>
                        </Flex>
                        </div>
                    )}
                >
                    <StyledGreenButton size="small">
                        Заказы <DownloadIcon width="16px" height="16px" />
                    </StyledGreenButton>
                </Dropdown>
            :
                <>
                    <Flex justify={'space-between'} align={'center'} gap={'16px'} style={{marginLeft: 'auto'}}>
                        {timeRemaining !== null ? (
                            <Flex align={'flex-end'}>
                                <FireIcon />
                                <Text align='right' secondary size='14px'>
                                    приём заказов<br />
                                    <span style={{ fontSize: '14px', color: '#2a2a2a' }}>ещё {formatRemainingTime()}</span>
                                </Text>
                            </Flex>
                        ) :
                            <Text align='right' secondary size='14px'>
                                приём заказов<br />
                                <span style={{ fontSize: '14px', color: '#2a2a2a' }}>до {clientOrderExpDateTime.format("HH:mm")}</span>
                            </Text>
                        }
                    </Flex>
                </>
            }
        </Flex>
    )
}

const SwiperWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
`
const StyledSlide = styled(SwiperSlide)`
    width: 500px;
    user-select: none;
    @media (max-width: 500px) {
        width: 100%;
    }
`
const SwiperNav = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: nowrap;
`
const SwiperButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 64px;
    height: 64px;
    background: #ffffff;
    border-radius: 24px;
    color: #000000;
    transition: all .3s;
    &:hover {
        scale: 1.05;
    }
`
const DaysStatisticsBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 25px 20px;
    background: #ffffff;
    border-radius: 32px;
    font-size: 18px;
`
const DaysStatisticsTitle = styled.h2`
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px #eeeeee;
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
    span {
        font-size: 18px;
    }
`
const RaceCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px 20px;
    background: #f8f8f8;
    border: solid 1px #e8e8e8;
    border-radius: 24px;
`
const RaceCardHeader = styled.div`
    display: flex;
    flex-direction: column;

    h2 {
        margin-bottom: 5px;
        font-size: 22px;
        font-weight: 500;
    }
    span {
        font-size: 22px;
        font-weight: 500;
        color: #d8b542
    }
    p{
        font-size: 16px;
        font-weight: 400;
    }
`
const RaceButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    background: linear-gradient(#34c065, #5be697);
    border-radius: 35%;
    cursor: pointer;
`
const RaceInfo = styled.div`
    margin-top: 20px;
    display: flex;
    gap: 50px;
`
const InfoTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    overflow: hidden;
    svg {
        min-width: 24px;
    }
`
const InfoButton = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
`
const RaceStats = styled.div`
    margin-top: 20px;
    display: flex;
    gap: 10px;
`
const StatsBlock = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: ${(props) => props.danger ? '#fbc0c0' : '#ffffff'};
    border: #cccccc;
    border-radius: 10px;
    h2 {
        margin-bottom: 5px;
        font-size: 10px;
    }
    p {
        font-size: 16px;
    }
`
const RaceLikes = styled.div`
    margin-top: 10px;
    flex: 1;
    display: flex;
    padding: 10px;
    background-color: #ffffff;
    border: #cccccc;
    border-radius: 10px;
    font-size: 16px;
`
const RaceLike = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: green;
`
const RaceUnlike = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
`
const StyledLink = styled(Link)`
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 40px;
    padding: 0 16px;
    background: #2A2A2A;
    border-radius: 12px;
    font-size: 12px;
    color: #ffffff;
    cursor: pointer;
    transition: all .3s;
    &:hover {
        color: #ffffff;
        scale: 1.03;
    }
`
const StyledIconLink = styled(Link)`
    color: #2A2A2A;
    &:hover {
        color: var(--color-accent);
    }
`
const StyledRedButton = styled(Button)`
    background: #EA394B;
    color: #ffffff;
    &:hover {
        background: #cf3343;
    }
`
const StyledWhiteButton = styled(Button)`
    background: #ffffff;
    border: solid 1px #EAEAEA;
`
const StyledGreenButton = styled(Button)`
    margin-left: auto;
    width: fit-content;
    min-width: auto;
    height: 32px;
    background: #49BC9A;
    color: #ffffff;
    &:hover {
        background: #40a486;
    }
`