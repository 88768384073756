import apiInstance from '../../../../api';
import {call, put, select} from 'redux-saga/effects';
import {
  //Notification Logs
  sendRequestNotificationLogsGetSuccess,
  sendRequestNotificationLogsGetError,
  setNotificationLogs,
} from '../../../reducers/utils/servicesLogsReducer';
import { selectCommonState } from '../../../reducers/utils/commonReducer';

//Notification Logs
export function* handleGetNotificationLogs(action) {
  const api = '/push/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestNotificationLogsGetSuccess());
      yield put(setNotificationLogs(response.data.data));
    } else {
      yield put(sendRequestNotificationLogsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestNotificationLogsGetError());
  }
}
