import apiInstance from '../../../../../api';
import {call, put, select} from 'redux-saga/effects';
import {
  //Modifier Groups
  sendRequestModifierGroupsGetSuccess,
  sendRequestModifierGroupsGetError,
  setModifierGroups,
  sendRequestModifierGroupsCreateSuccess,
  sendRequestModifierGroupsCreateError,
  sendRequestModifierGroupsUpdateSuccess,
  sendRequestModifierGroupsUpdateError,
  sendRequestModifierGroupsDeleteSuccess,
  sendRequestModifierGroupsDeleteError,

  //Modifier Groups Products
  sendRequestModifierGroupsProductsGetSuccess,
  sendRequestModifierGroupsProductsGetError,
  setModifierGroupsProducts,
  sendRequestModifierGroupsProductsCreateSuccess,
  sendRequestModifierGroupsProductsCreateError,
  sendRequestModifierGroupsProductUpdateSuccess,
  sendRequestModifierGroupsProductUpdateError,
  sendRequestModifierGroupsProductDeleteSuccess,
  sendRequestModifierGroupsProductDeleteError,
} from '../../../../reducers/utils/kitchenReducers/kitchenMenuReducer';
import { selectCommonState } from '../../../../reducers/utils/commonReducer';

//Modifier Groups
export function* handleGetModifierGroups(action) {
  const api = '/products/modifiersGroups/getModifiersGroupsByKitchenId?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestModifierGroupsGetSuccess());
      yield put(setModifierGroups(response.data.data.modifiersGroups));
    } else {
      yield put(sendRequestModifierGroupsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestModifierGroupsGetError());
  }
}

export function* handleCreateModifierGroups(action) {
  const api = '/products/modifiersGroups/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestModifierGroupsCreateSuccess());
    } else {
      yield put(sendRequestModifierGroupsCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestModifierGroupsCreateError());
  }
}

export function* handleUpdateModifierGroups(action) {
  const api = '/products/modifiersGroups/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestModifierGroupsUpdateSuccess());
    } else {
      yield put(sendRequestModifierGroupsUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestModifierGroupsUpdateError());
  }
}

export function* handleDeleteModifierGroups(action) {
  const api = '/products/modifiersGroups/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestModifierGroupsDeleteSuccess());
    } else {
      yield put(sendRequestModifierGroupsDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestModifierGroupsDeleteError());
  }
}

//Modifier Groups Products
export function* handleGetModifierGroupsProducts(action) {
  const api = '/products/modifiersGroupsProducts/getByModifierGroupId?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestModifierGroupsProductsGetSuccess());
      yield put(setModifierGroupsProducts(response.data.data.modifiersGroups));
    } else {
      yield put(sendRequestModifierGroupsProductsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestModifierGroupsProductsGetError());
  }
}

export function* handleCreateModifierGroupsProduct(action) {
  const api = '/products/modifiersGroupsProducts/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestModifierGroupsProductsCreateSuccess());
    } else {
      yield put(sendRequestModifierGroupsProductsCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestModifierGroupsProductsCreateError());
  }
}

export function* handleUpdateModifierGroupsProduct(action) {
  const api = '/products/modifiersGroupsProducts/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestModifierGroupsProductUpdateSuccess());
    } else {
      yield put(sendRequestModifierGroupsProductUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestModifierGroupsProductUpdateError());
  }
}

export function* handleDeleteModifierGroupsProduct(action) {
  const api = '/products/modifiersGroupsProducts/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestModifierGroupsProductDeleteSuccess());
    } else {
      yield put(sendRequestModifierGroupsProductDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestModifierGroupsProductDeleteError());
  }
}
