import React from 'react';
import styled from 'styled-components';
import { InputNumber as AntdInputNumber } from 'antd';

const InputNumber = ({ placeholder, min, max, value, formatter, prefix, suffix, error, width, onClick, onChange }) => {
    return (
        <StyledInputNumber
            placeholder={placeholder}
            min={min}
            max={max}
            value={value}
            formatter={formatter}
            prefix={prefix}
            suffix={suffix}
            error={error}
            width={width}
            onClick={onClick}
            onChange={onChange}
        />
    )
}

export default InputNumber;

const StyledInputNumber = styled(AntdInputNumber)`
    display: flex;
    align-items: center;
	height: 40px;
	max-width: ${({width}) => width ? width : 'auto'};
	border-color: ${({error}) => error && 'red'};
	transition: all .3s;
`