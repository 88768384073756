import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";
import apiInstance from '../../../../api';
import { selectCommonState, setServerTime } from '../commonReducer';

const initialState = {
  //News
  newsGetRequestStatus: null,
  newsCreateRequestStatus: null,
  newsUpdateRequestStatus: null,
  newsDeleteRequestStatus: null,
  productsStatisticsGetRequestStatus: null,
  reviewsGetRequestStatus: null,
  popularProductsGetRequestStatus: null,
  calendarMenuGetRequestStatus: null,

  news: [],
  productsStatistics: [],
  reviews: null,
  popularProducts: null,
  calendarMenu: [],
};

export const getNews = createAsyncThunk(
  'kitchenDashboard/getNews',
  async (data, { getState, rejectWithValue }) => {
    const api = '/news-admin/get?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data.newsAdmin;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const createNews = createAsyncThunk(
  'kitchenDashboard/createNews',
  async (data, { getState, rejectWithValue }) => {
    const api = '/news-admin/create?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const updateNews = createAsyncThunk(
  'kitchenDashboard/updateNews',
  async (data, { getState, rejectWithValue }) => {
    const api = '/news-admin/update?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const deleteNews = createAsyncThunk(
  'kitchenDashboard/deleteNews',
  async (data, { getState, rejectWithValue }) => {
    const api = '/news-admin/delete?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const getProductsStatistics = createAsyncThunk(
  'kitchenDashboard/getProductsStatistics',
  async (data, { getState, rejectWithValue }) => {
    const api = '/dashboard/getProductsStatistics?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data.productsStatistics;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const getReviews = createAsyncThunk(
  'kitchenDashboard/getReviews',
  async (data, { getState, rejectWithValue }) => {
    const api = '/dashboard/getReviews?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const getPopularProducts = createAsyncThunk(
  'kitchenDashboard/getPopularProducts',
  async (data, { getState, rejectWithValue }) => {
    const api = '/dashboard/getPopularProducts?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const getCalendarMenu = createAsyncThunk(
  'kitchenDashboard/getCalendarMenu',
  async (data, { getState, dispatch, rejectWithValue }) => {
    const api = '/dashboard/getCalendarMenuPublication?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        dispatch(setServerTime(response.data.data.serverTime));
        return response.data.data.calendarMenu;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

const kitchenDashboardSlice = createSlice({
  name: 'kitchenDashboard',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getNews.pending, (state) => {
        state.newsGetRequestStatus = 'pending';
      })
      .addCase(getNews.fulfilled, (state, action) => {
        state.newsGetRequestStatus = 'resolved';
        state.news = action.payload;
      })
      .addCase(getNews.rejected, (state) => {
        state.newsGetRequestStatus = 'rejected';
      })
      .addCase(createNews.pending, (state) => {
        state.newsCreateRequestStatus = 'pending';
      })
      .addCase(createNews.fulfilled, (state) => {
        state.newsCreateRequestStatus = 'resolved';
      })
      .addCase(createNews.rejected, (state) => {
        state.newsCreateRequestStatus = 'rejected';
      })
      .addCase(updateNews.pending, (state) => {
        state.newsUpdateRequestStatus = 'pending';
      })
      .addCase(updateNews.fulfilled, (state) => {
        state.newsUpdateRequestStatus = 'resolved';
      })
      .addCase(updateNews.rejected, (state) => {
        state.newsUpdateRequestStatus = 'rejected';
      })
      .addCase(deleteNews.pending, (state) => {
        state.newsDeleteRequestStatus = 'pending';
      })
      .addCase(deleteNews.fulfilled, (state) => {
        state.newsDeleteRequestStatus = 'resolved';
      })
      .addCase(deleteNews.rejected, (state) => {
        state.newsDeleteRequestStatus = 'rejected';
      })
      .addCase(getProductsStatistics.pending, (state) => {
        state.productsStatisticsGetRequestStatus = 'pending';
      })
      .addCase(getProductsStatistics.fulfilled, (state, action) => {
        state.productsStatisticsGetRequestStatus = 'resolved';
        state.productsStatistics = action.payload;
      })
      .addCase(getProductsStatistics.rejected, (state) => {
        state.productsStatisticsGetRequestStatus = 'rejected';
      })
      .addCase(getReviews.pending, (state) => {
        state.reviewsGetRequestStatus = 'pending';
      })
      .addCase(getReviews.fulfilled, (state, action) => {
        state.reviewsGetRequestStatus = 'resolved';
        state.reviews = action.payload;
      })
      .addCase(getReviews.rejected, (state) => {
        state.reviewsGetRequestStatus = 'rejected';
      })
      .addCase(getPopularProducts.pending, (state) => {
        state.popularProductsGetRequestStatus = 'pending';
      })
      .addCase(getPopularProducts.fulfilled, (state, action) => {
        state.popularProductsGetRequestStatus = 'resolved';
        state.popularProducts = action.payload;
      })
      .addCase(getPopularProducts.rejected, (state) => {
        state.popularProductsGetRequestStatus = 'rejected';
      })
      .addCase(getCalendarMenu.pending, (state) => {
        state.calendarMenuGetRequestStatus = 'pending';
      })
      .addCase(getCalendarMenu.fulfilled, (state, action) => {
        state.calendarMenuGetRequestStatus = 'resolved';
        state.calendarMenu = action.payload;
      })
      .addCase(getCalendarMenu.rejected, (state) => {
        state.calendarMenuGetRequestStatus = 'rejected';
      })
      .addCase(PURGE, () => {
        return initialState;
      })
  },
  reducers: {},
});

export const {} = kitchenDashboardSlice.actions;

export default kitchenDashboardSlice.reducer;
