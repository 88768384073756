import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import TopProductsCard from './TopProductsCard';
import { getPopularProducts } from '../../../../../redux/reducers/utils/kitchenReducers/kitchenDashboardReducer';
import { HeartIcon, RatingStarIcon } from '../../../../../components/Icons';

const TopProductsGrid = () => {
    const { selectKitchen } = useSelector((store) => store.kitchen);
    const {
        popularProductsGetRequestStatus,
        popularProducts,
    } = useSelector((store) => store.kitchenDashboard);
    const dispatch = useDispatch();

    useEffect(() => {
        if(selectKitchen) {
            dispatch(getPopularProducts({token: localStorage.getItem('token'), kitchenId: selectKitchen.id}));
        }
    }, [selectKitchen]);

    return (
        <Wrapper>
            <TopProductsCard
                title={'Популярные товары за последние 30 дней'}
                text={'Эти позиции заказывают больше всего'}
                secondText={'продано позиций'}
                link={'/kitchen/assortment'}
                isLoading={popularProductsGetRequestStatus === 'pending' ? true : false}
                products={popularProducts?.popularProductsIn30DaysByOrders}
            />
            <TopProductsCard
                title={'Дефицитные товары за последние 30 дней'}
                text={'Эти позиции  рекомендуется производить больше'}
                secondText={'распроданы полностью'}
                link={'/kitchen/assortment'}
                isLoading={popularProductsGetRequestStatus === 'pending' ? true : false}
                products={popularProducts?.deficitProductsIn30Days}
            />
            <TopProductsCard
                title={'Высокий рейтинг за последние 30 дней'}
                text={'Эти позиции пользователи рекомендуют'}
                secondText={'рейтинг'}
                link={'/kitchen/assortment'}
                valueIcon={<RatingStarIcon color='#FFC94A'/>}
                isLoading={popularProductsGetRequestStatus === 'pending' ? true : false}
                products={popularProducts?.popularProductsIn30DaysByReviews}
            />
            <TopProductsCard
                title={'Любимые товары за последние 30 дней'}
                text={'Эти позиции пользователи чаще откладывают в избранное'}
                secondText={'добавили в избранное'}
                link={'/kitchen/assortment'}
                valueIcon={<HeartIcon color='#EA394B'/>}
                isLoading={popularProductsGetRequestStatus === 'pending' ? true : false}
                products={popularProducts?.popularProductsIn30DaysByLikes}
            />
        </Wrapper>
    );
};

export default TopProductsGrid;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
