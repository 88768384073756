import { takeLatest } from 'redux-saga/effects';
import {
  //Kitchen Contracts
  handleGetKitchenContracts,
  handleCreateKitchenContracts,
  handleUpdateKitchenContracts,
  handleDeleteKitchenContracts,

  //Kitchen Contract Commissions
  handleGetKitchenContractCommissions,
  handleCreateKitchenContractCommissions,
  handleUpdateKitchenContractCommissions,
  handleDeleteKitchenContractCommissions,
} from './kitchenSettingsHandler';
import {
  //Kitchen Contracts
  sendRequestKitchenContractsGet,
  sendRequestKitchenContractsCreate,
  sendRequestKitchenContractsUpdate,
  sendRequestKitchenContractsDelete,

  //Kitchen Contract Commissions
  sendRequestKitchenContractCommissionsGet,
  sendRequestKitchenContractCommissionsCreate,
  sendRequestKitchenContractCommissionsUpdate,
  sendRequestKitchenContractCommissionsDelete,
} from '../../../../reducers/utils/kitchenReducers/kitchenSettingsReducer';

export function* kitchenSettingsWatcherSaga() {
  //Kitchen Contracts
  yield takeLatest(sendRequestKitchenContractsGet.type, handleGetKitchenContracts);
  yield takeLatest(sendRequestKitchenContractsCreate.type, handleCreateKitchenContracts);
  yield takeLatest(sendRequestKitchenContractsUpdate.type, handleUpdateKitchenContracts);
  yield takeLatest(sendRequestKitchenContractsDelete.type, handleDeleteKitchenContracts);

  //Kitchen Contract Commissions
  yield takeLatest(sendRequestKitchenContractCommissionsGet.type, handleGetKitchenContractCommissions);
  yield takeLatest(sendRequestKitchenContractCommissionsCreate.type, handleCreateKitchenContractCommissions);
  yield takeLatest(sendRequestKitchenContractCommissionsUpdate.type, handleUpdateKitchenContractCommissions);
  yield takeLatest(sendRequestKitchenContractCommissionsDelete.type, handleDeleteKitchenContractCommissions);
}