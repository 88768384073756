import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { ArrowBackIcon } from '../components/Icons';

const BackButton = ({ style, className, text, link, onClick }) => {
    const { isMobile } = useSelector((store) => store.common);

    const Wrapper = link ? StyledLink : StyledButton;

    return (
        <Wrapper
            style={style}
            className={className}
            isMobile={isMobile}
            to={link}
            onClick={onClick}
        >
            <IconWrapper isMobile={isMobile}>
                <ArrowBackIcon width='32px' height='32px' color={isMobile ? '#2A2A2A' : '#ffffff'} />
            </IconWrapper>
            <p>{text}</p>
        </Wrapper>
    );
};

export default BackButton;

const buttonStyles = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${({ isMobile }) => (isMobile ? '16px' : '24px')};
    width: ${({ isMobile }) => (isMobile ? '100%' : 'fit-content')};
    font-size: ${({ isMobile }) => (isMobile ? '20px' : '24px')};
    color: var(--color-font);
    p {
        flex: 1;
        text-align: center;
    }
    &:hover {
        color: var(--color-accent);
    }
`;

const StyledLink = styled(Link)`
    ${buttonStyles}
`;

const StyledButton = styled.button`
    ${buttonStyles}
    background: none;
    border: none;
    cursor: pointer;
`;

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: ${({ isMobile }) => (isMobile ? '32px' : '64px')};
    height: ${({ isMobile }) => (isMobile ? '32px' : '64px')};
    border-radius: 24px;
    background: ${({ isMobile }) => (isMobile ? 'none' : '#EA394B')};
    color: #ffffff;
    transition: all .3s;
`;
