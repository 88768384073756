import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectKitchen } from '../../../../../redux/reducers/utils/kitchenReducers/kitchenReducer';
import { Button, Tag, Typography } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { formatDate } from '../../../../../core/utils';
import Tooltip from '../../../../../ui/Tooltip';

const { Title, Text } = Typography;

// 1. Жёлтая. Ожидает формирования платежки. Когда акт подписан кухней и нами (мы автоматом подписываем) и не создано для этого дня ещё платёжки
// 2. Оранжевая. То же самое, но платёжка есть
// 3. Зелёная. Тоже самое и платёжка оплачена
// 4. Серая - акт не подписан
// 5. Нет акта, нет данных за этот день

const getAllDates = (settlements) => {
  const allDates = settlements?.flatMap(settlement => 
    settlement.payments.map(payment => payment.date)
  );
  
  const uniqueDates = [...new Set(allDates)];
  
  uniqueDates.sort((a, b) => {
    const dateA = new Date(a.split('.').reverse().join('-'));
    const dateB = new Date(b.split('.').reverse().join('-'));
    return dateA - dateB;
  });
  
  return uniqueDates;
};

const groupByWeeks = (dates) => {
  const weeks = {};
  dates.forEach(date => {
    const weekStart = moment(date, 'YYYY.MM.DD').startOf('isoWeek').format('DD.MM.YYYY');
    const weekEnd = moment(date, 'YYYY.MM.DD').endOf('isoWeek').format('DD.MM.YYYY');
    const weekKey = `${weekStart} - ${weekEnd}`;
    if (!weeks[weekKey]) {
      weeks[weekKey] = { dates: [], total: 0 };
    }
    weeks[weekKey].dates.push(date);
  });
  return weeks;
};

const calculateTotals = (settlements, dates) => {
  const totals = {};
  dates.forEach(date => {
    const datePayments = settlements.flatMap(settlement =>
      settlement.payments.filter(payment => payment.date === date)
    );
    const totalAmount = datePayments.reduce((total, payment) => total + parseFloat(payment.amount), 0);
    const totalPaid = datePayments.filter(payment => payment.status === 3).reduce((total, payment) => total + parseFloat(payment.amount), 0);
    const totalUnpaid = datePayments.filter(payment => payment.status === 2).reduce((total, payment) => total + parseFloat(payment.amount), 0);

    totals[date] = {
      totalAmount,
      totalPaid,
      totalUnpaid,
    };
  });
  return totals;
};

const calculateWeekTotals = (settlements, weeks) => {
  const weekTotals = {};
  Object.keys(weeks).forEach(weekKey => {
    const weekDates = weeks[weekKey].dates;
    const weekPayments = settlements.flatMap(settlement =>
      settlement.payments.filter(payment => weekDates.includes(payment.date))
    );
    const weekTotal = weekPayments.reduce((total, payment) => total + parseFloat(payment.amount), 0);
    const weekPaid = weekPayments.filter(payment => payment.status === 3).reduce((total, payment) => total + parseFloat(payment.amount), 0);
    const weekUnpaid = weekPayments.filter(payment => payment.status === 2).reduce((total, payment) => total + parseFloat(payment.amount), 0);

    weekTotals[weekKey] = {
      weekTotal,
      weekPaid,
      weekUnpaid,
    };
  });
  return weekTotals;
};

const FinancialSettlementTable = ({ dataSource, activeOrders, cellClick, resetActiveOrders }) => {
  const navigate = useNavigate();
  const {kitchens} = useSelector((store) => store.kitchen);
  const dispatch = useDispatch();
  const [isGroupedByWeeks, setIsGroupedByWeeks] = useState(false);
  const allDates = getAllDates(dataSource);
  const weeks = groupByWeeks(allDates);
  const totals = isGroupedByWeeks ? calculateWeekTotals(dataSource, weeks) : calculateTotals(dataSource, allDates);

  const handleOpenAct = (actId, kitchenid) => {
    const kitchen = kitchens.find(kitchen => kitchen.id === kitchenid);

    navigate(`/kitchens/finance?act=${actId}`);
    dispatch(setSelectKitchen(kitchen));
  }

  if (dataSource.length === 0) {
    return;
  }

  return (
    <>
      <Button style={{width: 'fit-content'}} onClick={() => {
        setIsGroupedByWeeks(!isGroupedByWeeks);
        resetActiveOrders();
      }}>
        {isGroupedByWeeks ? 'Группировать по дням' : 'Группировать по неделям'}
      </Button>
      <TableWrapper>
        <Table>
          <thead>
            <Tr>
              <Th><Title level={5} style={{ margin: 0 }}>Договор</Title></Th>
              {isGroupedByWeeks
                ? Object.keys(weeks).map((weekKey, index) => (
                    <Th key={index}><Title level={5} style={{ margin: 0 }}>{weekKey}</Title></Th>
                  ))
                : allDates.map((date, index) => (
                    <Th key={index} isSunday={moment(date, 'YYYY.MM.DD').day() === 0}><Title level={5} style={{ margin: 0 }}>{formatDate(date)}</Title></Th>
                  ))}
            </Tr>
          </thead>
          <tbody>
            {/* Итоговые строки */}
            <Tr>
              <Td><Text>Итого</Text></Td>
              {isGroupedByWeeks
                ? Object.keys(weeks).map((weekKey, index) => (
                    <Td key={index}><Text>{totals[weekKey]?.weekTotal || 0}</Text></Td>
                  ))
                : allDates.map((date, index) => (
                    <Td key={index}><Text>{totals[date]?.totalAmount || 0}</Text></Td>
                  ))}
            </Tr>
            <Tr>
              <Td><Text>Итого оплачено</Text></Td>
              {isGroupedByWeeks
                ? Object.keys(weeks).map((weekKey, index) => (
                    <Td key={index}><Text>{totals[weekKey]?.weekPaid || 0}</Text></Td>
                  ))
                : allDates.map((date, index) => (
                    <Td key={index}><Text>{totals[date]?.totalPaid || 0}</Text></Td>
                  ))}
            </Tr>
            <Tr>
              <Td><Text>Итого не оплачено</Text></Td>
              {isGroupedByWeeks
                ? Object.keys(weeks).map((weekKey, index) => (
                    <Td key={index}><Text>{totals[weekKey]?.weekUnpaid || 0}</Text></Td>
                  ))
                : allDates.map((date, index) => (
                    <Td key={index}><Text>{totals[date]?.totalUnpaid || 0}</Text></Td>
                  ))}
            </Tr>
            {dataSource.map((settlement) => (
              <Tr key={settlement.idActWork}>
                <Td><Text>{settlement.contract}</Text></Td>
                {isGroupedByWeeks
                  ? Object.keys(weeks).map((weekKey, index) => {
                      const weekDates = weeks[weekKey].dates;
                      const weekPayments = settlement.payments.filter(payment => weekDates.includes(payment.date));
                      const weekTotal = weekPayments.reduce((total, payment) => total + parseFloat(payment.amount), 0);
                      const isActive = weekPayments.every(payment =>
                        activeOrders.some(order => order.idActWork === payment.idActWork)
                      );
                      const isStatus1 = weekPayments.every(item => item.status === 1);

                      console.log(weekPayments)
                      return (
                        <Td
                          key={index}
                          isActive={weekPayments.length > 0 && isActive}
                          status={weekPayments.length > 0 && isStatus1 ? 1 : (weekPayments.length > 0 && !isStatus1 ? 5 : '')}
                          onClick={() => cellClick(weekPayments)}
                        >
                          {weekPayments.length > 0 ? <Text>{weekTotal}</Text> : <Text>-</Text>}
                        </Td>
                      );
                    })
                  : allDates.map((date, index) => {
                      const payment = settlement.payments.find(payment => payment.date === date);
                      const isActive = activeOrders.some(order => order.idActWork === payment?.idActWork);
                      return (
                        <Tooltip title={payment?.status === 3 || payment?.status === 2 ? (
                            <div>
                              <Tag color={payment?.status === 3 ? 'green' : 'red'}>{payment?.status === 3 ? 'Оплачено' : 'Не оплачено'}</Tag>
                              <Text style={{color: '#000'}}>{payment?.paymentOrder} {payment?.paymentOrderLink && <a href={payment?.paymentOrderLink} target='_blank'>ссылка на документ</a>}</Text>
                            </div>
                          ) : (payment?.status === 4 && (
                            <div>
                              <Tag color='gray'>Акт не подписан</Tag>
                              <Text style={{color: '#000'}}>{payment?.actWorkName} <Typography.Link onClick={() => handleOpenAct(payment?.idActWork, payment?.kitchenId)}>ссылка на акт</Typography.Link></Text>
                            </div>
                          ))}
                        >
                          <Td 
                            key={index}
                            isActive={isActive}
                            status={payment?.status}
                            onClick={() => cellClick(payment)}
                          >
                            {payment ? <Text>{payment.amount}</Text> : <Text>-</Text>}
                          </Td>
                        </Tooltip>
                      );
                    })}
              </Tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </>
  );
};

export default FinancialSettlementTable;

const TableWrapper = styled.div`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin: 20px 0;
`;
const Table = styled.table`
  width: fit-content;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
`;
const Th = styled.th`
  padding: 12px;
  background: ${props => props.isSunday ? '#e99998' : '#e6e6e6'};
  color: ${props => props.isSunday ? 'white' : 'black'};
  border-bottom: 2px solid #ddd;
`;
const Td = styled.td`
  padding: 8px;
  min-width: 80px;
  max-width: 80px;
  background: ${props => props.status === 1 ? '#fff2cd' : (
                         props.status === 2 ? '#f9ca9c' : (
                         props.status === 3 ? '#d8ead2' : (
                         props.status === 4 ? '#d9d9d9' : (
                         props.status === 5 ? '#c9daf8' : ''))))};
  border: 1px solid #ddd;
  box-shadow: ${props => props.isActive ? '0 0 50px 50px rgba(0, 0, 0, 0.15) inset' : ''};
  cursor: pointer;

  &:first-child {
    min-width: 250px;
    max-width: 250px;
  }

  &:not(:first-child) {
    text-align: center;
  }

  &:not(:first-child):hover {
    box-shadow: ${props => props.isActive ? '0 0 50px 50px rgba(0, 0, 0, 0.15) inset' : '0 0 2px 1px rgba(0, 0, 0, 0.25) inset'};
    transition: all .5s;
  }
`;
const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }

  &:hover {
    background-color: #f1f1f1;
  }
`;
