import apiInstance from '../../../../../api';
import {call, put, select} from 'redux-saga/effects';
import {
  sendRequestNotificationsGetSuccess,
  sendRequestNotificationsGetError,
  setNotifications,
  sendRequestNotificationsPushSuccess,
  sendRequestNotificationsPushError,
  setDevices,
} from '../../../../reducers/utils/clientsReducers/clientsNotificationsReducer';
import { selectCommonState } from '../../../../reducers/utils/commonReducer';

export function* handleGetNotifications(action) {
  const api = '/customerNotifications/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestNotificationsGetSuccess());
      yield put(setNotifications(response.data.data));
    } else {
      yield put(sendRequestNotificationsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestNotificationsGetError());
  }
}

export function* handlePushNotification(action) {
  const api = '/customerNotifications/selectDevices?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  let formData = new FormData();

  if (data.customerFile) { formData.append('customerFile', action.payload.customerFile); }
  if (data.imgMessege) { formData.append('imgMessege', action.payload.imgMessege); }
  if (data.imgPush) { formData.append('imgPush', action.payload.imgPush); }
  formData.append('data', JSON.stringify( data ));

  try {
    const response = yield call(() => apiInstance.post(fullApi, formData));

    if (response.data.result) {
      yield put(sendRequestNotificationsPushSuccess());
      yield put(setDevices(response.data.data.countDevices));
    } else {
      yield put(sendRequestNotificationsPushError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestNotificationsPushError());
  }
}