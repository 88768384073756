import apiInstance from '../../../../../api';
import { call, put, select } from 'redux-saga/effects';
import {
  //Delivery Statuses Orders
  sendRequestDeliveryStatusesOrdersGetSuccess,
  sendRequestDeliveryStatusesOrdersGetError,
  setDeliveryStatusesOrders,

  //Delivery Service
  sendRequestDeliveryServicesGetSuccess,
  sendRequestDeliveryServicesGetError,
  setDeliveryServices,
  sendRequestDeliveryServicesCreateSuccess,
  sendRequestDeliveryServicesCreateError,
  sendRequestDeliveryServicesUpdateSuccess,
  sendRequestDeliveryServicesUpdateError,
  updateCurrentDeliveryService,
  sendRequestDeliveryServicesDeleteSuccess,
  sendRequestDeliveryServicesDeleteError,

  //Delivery Service Races
  sendRequestDeliveryServiceRacesGetSuccess,
  sendRequestDeliveryServiceRacesGetError,
  setDeliveryServiceRaces,
  sendRequestDeliveryServiceRacesCreateSuccess,
  sendRequestDeliveryServiceRacesCreateError,
  sendRequestDeliveryServiceRacesUpdateSuccess,
  sendRequestDeliveryServiceRacesUpdateError,
  updateCurrentDeliveryServiceRace,
  sendRequestDeliveryServiceRacesDeleteSuccess,
  sendRequestDeliveryServiceRacesDeleteError,

  //Delivery Services Couriers
  sendRequestDeliveryServiceCouriersGetSuccess,
  sendRequestDeliveryServiceCouriersGetError,
  setDeliveryServiceCouriers,
  updateCurrentDeliveryServiceCourier,
  sendRequestDeliveryServiceCouriersCreateSuccess,
  sendRequestDeliveryServiceCouriersCreateError,
  sendRequestDeliveryServiceCouriersUpdateSuccess,
  sendRequestDeliveryServiceCouriersUpdateError,
  sendRequestDeliveryServiceCouriersDeleteSuccess,
  sendRequestDeliveryServiceCouriersDeleteError,

  //Delivery Services Polygons
  sendRequestDeliveryServicePolygonsGetSuccess,
  sendRequestDeliveryServicePolygonsGetError,
  setDeliveryServicePolygons,
  sendRequestDeliveryServicePolygonCreateSuccess,
  sendRequestDeliveryServicePolygonCreateError,
  sendRequestDeliveryServicePolygonUpdateSuccess,
  sendRequestDeliveryServicePolygonUpdateError,
  sendRequestDeliveryServicePolygonDeleteSuccess,
  sendRequestDeliveryServicePolygonDeleteError,
  sendRequestDeliveryServicePolygonsMassUpdateSuccess,
  sendRequestDeliveryServicePolygonsMassUpdateError,

  //Delivery Service Race Couriers
  sendRequestDeliveryServiceRaceCouriersGetSuccess,
  sendRequestDeliveryServiceRaceCouriersGetError,
  setDeliveryServiceRaceCouriers,
  updateDeliveryServiceRaceCourierById,
  sendRequestDeliveryServiceRaceCourierEnableSuccess,
  sendRequestDeliveryServiceRaceCourierEnableError,
  sendRequestDeliveryServiceRaceCourierDisableSuccess,
  sendRequestDeliveryServiceRaceCourierDisableError,

  //Delivery Service Race Polygons
  sendRequestDeliveryServiceRacePolygonsGetSuccess,
  sendRequestDeliveryServiceRacePolygonsGetError,
  setDeliveryServiceRacePolygons,
  updateDeliveryServiceRacePolygonById,
  sendRequestDeliveryServiceRacePolygonEnableSuccess,
  sendRequestDeliveryServiceRacePolygonEnableError,
  sendRequestDeliveryServiceRacePolygonDisableSuccess,
  sendRequestDeliveryServiceRacePolygonDisableError,

  //Delivery Service Race Kitchens
  sendRequestDeliveryServiceRaceKitchensGetSuccess,
  sendRequestDeliveryServiceRaceKitchensGetError,
  setDeliveryServiceRaceKitchens,
  sendRequestDeliveryServiceRaceKitchenAddSuccess,
  sendRequestDeliveryServiceRaceKitchenAddError,
  sendRequestDeliveryServiceRaceKitchenUpdateSuccess,
  sendRequestDeliveryServiceRaceKitchenUpdateError,
  sendRequestDeliveryServiceRaceKitchenTermsCreateSuccess,
  sendRequestDeliveryServiceRaceKitchenTermsCreateError,
  sendRequestDeliveryServiceRaceKitchenTermsUpdateSuccess,
  sendRequestDeliveryServiceRaceKitchenTermsUpdateError,
  sendRequestDeliveryServiceRaceKitchenTermsDeleteSuccess,
  sendRequestDeliveryServiceRaceKitchenTermsDeleteError,

  //Delivery Service Not Working
  sendRequestDeliveryServiceNotWorkingGetSuccess,
  sendRequestDeliveryServiceNotWorkingGetError,
  setDeliveryServiceNotWorking,
  sendRequestDeliveryServiceNotWorkingCreateSuccess,
  sendRequestDeliveryServiceNotWorkingCreateError,
  sendRequestDeliveryServiceNotWorkingDeleteSuccess,
  sendRequestDeliveryServiceNotWorkingDeleteError,
} from '../../../../reducers/utils/deliveryReducers/deliveryServicesReducer';
import { selectCommonState } from '../../../../reducers/utils/commonReducer';

//Delivery Statuses Orders
export function* handleGetDeliveryStatusesOrders(action) {
  const api = '/order/getStatusesOrders?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryStatusesOrdersGetSuccess());
      yield put(setDeliveryStatusesOrders(response.data.data.statuses));
    } else {
      yield put(sendRequestDeliveryStatusesOrdersGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryStatusesOrdersGetError());
  }
}

//Delivery Service
export function* handleGetDeliveryServices(action) {
  const api = '/delivery-services/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServicesGetSuccess());
      yield put(setDeliveryServices(response.data.data.deliveryServices));
    } else {
      yield put(sendRequestDeliveryServicesGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServicesGetError());
  }
}

export function* handleCreateDeliveryServices(action) {
  const api = '/delivery-services/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServicesCreateSuccess());
    } else {
      yield put(sendRequestDeliveryServicesCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServicesCreateError());
  }
}

export function* handleUpdateDeliveryServices(action) {
  const api = '/delivery-services/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServicesUpdateSuccess());
      yield put(updateCurrentDeliveryService(data));
    } else {
      yield put(sendRequestDeliveryServicesUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServicesUpdateError());
  }
}

export function* handleDeleteDeliveryServices(action) {
  const api = '/delivery-services/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServicesDeleteSuccess());
    } else {
      yield put(sendRequestDeliveryServicesDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServicesDeleteError());
  }
}

//Delivery Service Races
export function* handleGetDeliveryServiceRaces(action) {
  const api = '/delivery-services/races/getByDeliveryServiceId?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceRacesGetSuccess());
      yield put(setDeliveryServiceRaces(response.data.data.races));
    } else {
      yield put(sendRequestDeliveryServiceRacesGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceRacesGetError());
  }
}

export function* handleCreateDeliveryServiceRaces(action) {
  const api = '/delivery-services/races/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceRacesCreateSuccess());
    } else {
      yield put(sendRequestDeliveryServiceRacesCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceRacesCreateError());
  }
}

export function* handleUpdateDeliveryServiceRaces(action) {
  const api = '/delivery-services/races/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceRacesUpdateSuccess());
      yield put(updateCurrentDeliveryServiceRace(data));
    } else {
      yield put(sendRequestDeliveryServiceRacesUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceRacesUpdateError());
  }
}

export function* handleDeleteDeliveryServiceRaces(action) {
  const api = '/delivery-services/races/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceRacesDeleteSuccess());
    } else {
      yield put(sendRequestDeliveryServiceRacesDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceRacesDeleteError());
  }
}

//Delivery Services Couriers
export function* handleGetDeliveryServiceCouriers(action) {
  const api = '/courier/getCouriersByDeliveryServiceId?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceCouriersGetSuccess());
      yield put(setDeliveryServiceCouriers(response.data.data.couriers));
    } else {
      yield put(sendRequestDeliveryServiceCouriersGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceCouriersGetError());
  }
}

export function* handleCreateDeliveryServiceCourier(action) {
  const api = '/courier/addCourierByPhone?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceCouriersCreateSuccess());
    } else {
      yield put(sendRequestDeliveryServiceCouriersCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceCouriersCreateError());
  }
}

export function* handleUpdateDeliveryServiceCourier(action) {
  const api = '/courier/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceCouriersUpdateSuccess());
      yield put(updateCurrentDeliveryServiceCourier(data));
    } else {
      yield put(sendRequestDeliveryServiceCouriersUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceCouriersUpdateError());
  }
}

export function* handleDeleteDeliveryServiceCourier(action) {
  const api = '/courier/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceCouriersDeleteSuccess());
    } else {
      yield put(sendRequestDeliveryServiceCouriersDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceCouriersDeleteError());
  }
}

//Delivery Services Polygons
export function* handleGetDeliveryServicePolygons(action) {
  const api = '/polygons/getByDeliveryServiceId?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServicePolygonsGetSuccess());
      yield put(setDeliveryServicePolygons(response.data.data.polygons));
    } else {
      yield put(sendRequestDeliveryServicePolygonsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServicePolygonsGetError());
  }
}

export function* handleCreateDeliveryServicePolygon(action) {
  const api = '/polygons/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServicePolygonCreateSuccess());
    } else {
      yield put(sendRequestDeliveryServicePolygonCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServicePolygonCreateError());
  }
}

export function* handleUpdateDeliveryServicePolygon(action) {
  const api = '/polygons/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServicePolygonUpdateSuccess());
    } else {
      yield put(sendRequestDeliveryServicePolygonUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServicePolygonUpdateError());
  }
}

export function* handleDeleteDeliveryServicePolygon(action) {
  const api = '/polygons/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServicePolygonDeleteSuccess());
    } else {
      yield put(sendRequestDeliveryServicePolygonDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServicePolygonDeleteError());
  }
}

export function* handleMassUpdateDeliveryServicePolygons(action) {
  const api = '/polygons/addPoligons?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServicePolygonsMassUpdateSuccess());
    } else {
      yield put(sendRequestDeliveryServicePolygonsMassUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServicePolygonsMassUpdateError());
  }
}

//Delivery Service Race Couriers
export function* handleGetDeliveryServiceRaceCouriers(action) {
  const api = '/delivery-services/races/couriers/getCouriersForRace?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceRaceCouriersGetSuccess());
      yield put(setDeliveryServiceRaceCouriers(response.data.data.racesCouriers));
    } else {
      yield put(sendRequestDeliveryServiceRaceCouriersGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceRaceCouriersGetError());
  }
}

export function* handleEnableDeliveryServiceRaceCourier(action) {
  const api = '/delivery-services/races/couriers/addInRace?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceRaceCourierEnableSuccess());
      yield put(updateDeliveryServiceRaceCourierById({courierId: data.courierId, isEnabled: 1}));
    } else {
      yield put(sendRequestDeliveryServiceRaceCourierEnableError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceRaceCourierEnableError());
  }
}

export function* handleDisableDeliveryServiceRaceCourier(action) {
  const api = '/delivery-services/races/couriers/disableInRace?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceRaceCourierDisableSuccess());
      yield put(updateDeliveryServiceRaceCourierById({courierId: data.courierId, isEnabled: 0}));
    } else {
      yield put(sendRequestDeliveryServiceRaceCourierDisableError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceRaceCourierDisableError());
  }
}

//Delivery Service Race Polygons
export function* handleGetDeliveryServiceRacePolygons(action) {
  const api = '/delivery-services/races/polygons/getPolygonsForRace?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceRacePolygonsGetSuccess());
      yield put(setDeliveryServiceRacePolygons(response.data.data.racePolygons));
    } else {
      yield put(sendRequestDeliveryServiceRacePolygonsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceRacePolygonsGetError());
  }
}

export function* handleEnableDeliveryServiceRacePolygon(action) {
  const api = '/delivery-services/races/polygons/addInRace?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceRacePolygonEnableSuccess());
      yield put(updateDeliveryServiceRacePolygonById({polygonId: data.polygonId, isEnabled: 1}));
    } else {
      yield put(sendRequestDeliveryServiceRacePolygonEnableError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceRacePolygonEnableError());
  }
}

export function* handleDisableDeliveryServiceRacePolygon(action) {
  const api = '/delivery-services/races/polygons/disableInRace?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceRacePolygonDisableSuccess());
      yield put(updateDeliveryServiceRacePolygonById({polygonId: data.polygonId, isEnabled: 0}));
    } else {
      yield put(sendRequestDeliveryServiceRacePolygonDisableError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceRacePolygonDisableError());
  }
}

//Delivery Service Race Kitchens
export function* handleGetDeliveryServiceRaceKitchens(action) {
  const api = '/delivery-services/races/polygons/kitchens/getKitchenRacePolygonForRace?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceRaceKitchensGetSuccess());
      yield put(setDeliveryServiceRaceKitchens(response.data.data.kitchenRacePolygons));
    } else {
      yield put(sendRequestDeliveryServiceRaceKitchensGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceRaceKitchensGetError());
  }
}

export function* handleAddDeliveryServiceRaceKitchen(action) {
  const api = '/delivery-services/races/polygons/kitchens/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceRaceKitchenAddSuccess());
    } else {
      yield put(sendRequestDeliveryServiceRaceKitchenAddError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceRaceKitchenAddError());
  }
}

export function* handleUpdateDeliveryServiceRaceKitchen(action) {
  const api = '/delivery-services/races/polygons/kitchens/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceRaceKitchenUpdateSuccess());
    } else {
      yield put(sendRequestDeliveryServiceRaceKitchenUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceRaceKitchenUpdateError());
  }
}

export function* handleCreateDeliveryServiceRaceKitchenTerms(action) {
  const api = '/delivery-services/races/polygons/kitchens/delivery-price/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceRaceKitchenTermsCreateSuccess());
    } else {
      yield put(sendRequestDeliveryServiceRaceKitchenTermsCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceRaceKitchenTermsCreateError());
  }
}

export function* handleUpdateDeliveryServiceRaceKitchenTerms(action) {
  const api = '/delivery-services/races/polygons/kitchens/delivery-price/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceRaceKitchenTermsUpdateSuccess());
    } else {
      yield put(sendRequestDeliveryServiceRaceKitchenTermsUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceRaceKitchenTermsUpdateError());
  }
}

export function* handleDeleteDeliveryServiceRaceKitchenTerms(action) {
  const api = '/delivery-services/races/polygons/kitchens/delivery-price/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceRaceKitchenTermsDeleteSuccess());
    } else {
      yield put(sendRequestDeliveryServiceRaceKitchenTermsDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceRaceKitchenTermsDeleteError());
  }
}

//Delivery Service Not Working
export function* handleGetDeliveryServiceNotWorking(action) {
  const api = '/delivery-services/NotWorking/getByRaceId?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceNotWorkingGetSuccess());
      yield put(setDeliveryServiceNotWorking(response.data.data.deliveryServiceNotWorking));
    } else {
      yield put(sendRequestDeliveryServiceNotWorkingGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceNotWorkingGetError());
  }
}

export function* handleCreateDeliveryServiceNotWorking(action) {
  const api = '/delivery-services/NotWorking/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceNotWorkingCreateSuccess());
    } else {
      yield put(sendRequestDeliveryServiceNotWorkingCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceNotWorkingCreateError());
  }
}

export function* handleDeleteDeliveryServiceNotWorking(action) {
  const api = '/delivery-services/NotWorking/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestDeliveryServiceNotWorkingDeleteSuccess());
    } else {
      yield put(sendRequestDeliveryServiceNotWorkingDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestDeliveryServiceNotWorkingDeleteError());
  }
}
