import React from 'react';
import styled from 'styled-components';
import { Input as AntdInput } from 'antd';

const Input = ({ type, placeholder, value, error, width, onClick, onChange }) => {
    return (
		<StyledInput
			type={type}
			placeholder={placeholder}
			value={value}
			error={error}
			width={width}
			onClick={onClick}
			onChange={onChange}
		/>
    )
}

export default Input;

const StyledInput = styled(AntdInput)`
	display: flex;
	align-items: center;
	height: 40px;
	max-width: ${({width}) => width ? width : 'auto'};
	border-color: ${({error}) => error && 'red'};
	transition: all .3s;
`