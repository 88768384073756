import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCourierToAppoint,
  selectOrderArray,
  setFirstPlacemarkToGroupOrders,
  setSecondPlacemarkToGroupOrders,
  sendRequestCouriersDetailGet,
  updateCourierLocationAndRoute,
  setCouriersDetail,
  setCurrentCourierId,
  setDetailCouriersShow,
  sendRequestWhereCouriersGet,
} from '../../../../../redux/reducers/utils/deliveryReducers/deliveryMapReducer';
import { Flex, message, Space } from 'antd';
import { WifiOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { formatPrice } from '../../../../../core/utils'
import CourierProgressBar from './CourierProgressBar';
import moment from 'moment';

const CourierCard = ({ courier }) => {
  const {
    selectedCourierToAppoint,
    couriersRoute,
    selectedFilters,
    selectedCourierLocationAndRoute,
  } = useSelector((store) => store.deliveryMap);
  const dispatch = useDispatch();

  const handleCardClick = () => {
    const newSelectedCourier = selectedCourierToAppoint === courier ? null : courier;
    dispatch(selectCourierToAppoint(newSelectedCourier));
    newSelectedCourier !== null
      ? message.success(`Выбран курьер ${courier.name}, выберите заказы на карте`)
      : message.error(`Курьер ${courier.name}, отменен`)
    dispatch(selectOrderArray([]));
    dispatch(setFirstPlacemarkToGroupOrders(null));
    dispatch(setSecondPlacemarkToGroupOrders(null));
  };

  const handleProgressBarClick = () => {
    const showAllCourier = selectedCourierLocationAndRoute.showAllCourier;
    const isCourierInTrack = selectedCourierLocationAndRoute.trackCourierIds.includes(courier.courierId);
  
    if (isCourierInTrack) {
      const updatedTrackCourierIds = selectedCourierLocationAndRoute.trackCourierIds.filter(id => id !== courier.courierId);
      dispatch(updateCourierLocationAndRoute({ locationCourierIds: showAllCourier ? selectedCourierLocationAndRoute.locationCourierIds : updatedTrackCourierIds, trackCourierIds: updatedTrackCourierIds }));
    } else {
      const updatedTrackCourierIds = [...selectedCourierLocationAndRoute.trackCourierIds, courier.courierId];
      dispatch(updateCourierLocationAndRoute({ locationCourierIds: showAllCourier ? selectedCourierLocationAndRoute.locationCourierIds : updatedTrackCourierIds, trackCourierIds: updatedTrackCourierIds }));
    }
  };

  const handleCourierClick = () => {
    dispatch(setCouriersDetail([]));
    dispatch(setDetailCouriersShow(true));
    dispatch(setCurrentCourierId(courier.courierId));

    const requestData = {
      token: localStorage.getItem('token'),
      courierId: courier.courierId,
      deliveryDay: selectedFilters.date,
      raceId: selectedFilters.delivery,
    }

    dispatch(sendRequestCouriersDetailGet(requestData));

    const requestData2 = {
      token: localStorage.getItem('token'),
      locationCourierIds: [courier.courierId],
      trackCourierIds: [courier.courierId],
      deliveryDay: selectedFilters.date,
    };

    dispatch(sendRequestWhereCouriersGet(requestData2));

    dispatch(updateCourierLocationAndRoute({ showAllCourier: false, locationCourierIds: [courier.courierId], trackCourierIds: [courier.courierId] }));
  }

  const hasUnaddedAddresses = courier.numberAddressesRoute < courier.numberAddresses

  return (
    <Flex
      style={{
        width: '100%',
        padding: '4px 10px',
        background: selectedCourierToAppoint !== null && selectedCourierToAppoint.courierId === courier.courierId ? '#e6f7ff' : 'inherit'
      }}
      justify="space-between"
      align="center"
    >
      <Space style={{ width: '100px' }}>
        <StyledMarkWrapper onClick={handleCardClick} selected={selectedCourierToAppoint !== null && selectedCourierToAppoint.courierId === courier.courierId} couriercolor={courier.color}>
          <StyledMark selected={selectedCourierToAppoint !== null && selectedCourierToAppoint.courierId === courier.courierId} couriercolor={courier.color}></StyledMark>
        </StyledMarkWrapper>
        <div style={{cursor: 'pointer'}} onClick={handleCourierClick}>
          <div>{courier.prefix}-{courier.name}</div>
          <div>
            <span style={{color: courier.isOnline ? '#2bff00' : '#ff0000'}}><WifiOutlined/></span>
            <span style={{color: courier.GPS ? '#2bff00' : '#ff0000'}}>GPS</span>
          </div>
        </div>
      </Space>
      <CourierProgressBar
        addresses={courier.numberAddresses}
        addressesDelivered={courier.numberAddressesDelivered}
        timeStart={courier.firstDeliveryTimeTimestamp ? moment(courier.firstDeliveryTimeTimestamp * 1000).format('HH:mm') : null}
        timeFinish={courier.lastDeliveryTimeTimestamp ? moment(courier.lastDeliveryTimeTimestamp * 1000).format('HH:mm') : null}
        active={couriersRoute.find(item => item.courierId === courier.courierId)}
        hasError={hasUnaddedAddresses}
        onClick={handleProgressBarClick}
      />
      <Flex vertical align='flex-end' style={{ width: '100px' }}>
        { courier.numberAddresses !== 0 &&
        <>
          <div>{formatPrice(courier.salary.sum)} р</div>
          <div>
            <div>{courier.salary.value} {courier.salary.key}</div>
          </div>
        </>
        }
      </Flex>
    </Flex>
  );
};

export default CourierCard;

const StyledMarkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border: ${({ selected }) => (selected ? '3px solid' : '1px solid')};
  border-radius: 40%;
  border-color: ${({ selected, couriercolor }) => (selected ? '#000000' : couriercolor)};
  overflow: hidden;
  transition: all .3s;
  cursor: pointer;
`;
const StyledMark = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ selected }) => (selected ? '25px' : '17px')};
  height: ${({ selected }) => (selected ? '25px' : '17px')};
  border-radius: ${({ selected }) => (selected ? '0' : '17px')};
  background: ${({ couriercolor }) => (couriercolor)};
  transition: all .3s;
`;
