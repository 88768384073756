import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Menu } from 'antd';
import { checkAccess } from '../core/utils'
import { setCurrentModule } from '../redux/reducers/utils/navReducer';

const SiderMenuComponent = ({modules, onClose}) => {
  const { currentModule } = useSelector((store) => store.nav);
  const { accessComponentsForUser } = useSelector((store) => store.services);
  const dispatch = useDispatch();

  const handleModuleChange = (e) => {
    if (onClose) {
      onClose();
    }
    dispatch(setCurrentModule(e.key));
  };

  const accessModules = modules?.map(module => 
    checkAccess(accessComponentsForUser, module.key) ? module : null
  ).filter(Boolean);
  
  return <Menu mode="inline" selectedKeys={[currentModule]} items={accessModules} onClick={handleModuleChange}/>
};

export default SiderMenuComponent;


