import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getAllProducts, addMenu, updateAllProducts, toggleMenuStatus } from '../../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import styled from 'styled-components';
import BackButton from '../../../../ui/BackButton';
import { CardSection, Container, Page, ScrolledCol, VerticalSpace } from '../../../../core/styles';
import { CalendarIcon, ClockIcon, OkIcon, PlayIcon, StopIcon, TimeIcon, UsertimeIcon, WarningIcon } from '../../../../components/Icons';
import Title from '../../../../ui/Title';
import Flex from '../../../../components/Flex';
import ProductTabs from './components/ProductTabs';
import Menu from './components/Menu';
import Card from '../../../../components/Card';
import Text from '../../../../ui/Text';
import Button from '../../../../ui/Button';
import Tooltip from '../../../../ui/Tooltip';
import moment from 'moment';
import { Period } from '../dashboardPage/components/DaysStatistics';

const MenuPage = () => {
    const navigate = useNavigate();
    const { serverTime } = useSelector((store) => store.common);
    const { isAuth } = useSelector((store) => store.auth);
    const { isMobile } = useSelector((store) => store.common);
    const { allProducts, currentRace } = useSelector((store) => store.kitchenMenu);
    const dispatch = useDispatch();
    const [isEdited, setIsEdited] = useState(currentRace?.menu.length === 0 ? true : false);
    const [haveMenu, setHaveMenu] = useState(currentRace?.menu.length > 0 ? true : false);
    const [isBlocked, setIsBlocked] = useState(currentRace?.menu[0]?.status === 'draft');
    const timezone = currentRace?.timezoneCity;
    const adjustedTimeStart = moment(currentRace?.timeStart, "HH:mm").add(timezone, 'hours').format("HH:mm");
    const adjustedTimeEnd = moment(currentRace?.timeEnd, "HH:mm").add(timezone, 'hours').format("HH:mm");

    if (!currentRace) {
        console.log(currentRace);
        navigate('/kitchen/dashboard');
    }

    useEffect(() => {
		if (currentRace) {
            const requestData = {
                token: localStorage.getItem('token'),
                day: currentRace.deliveryDay,
                kitchenId: currentRace.kitchenId,
                raceId: currentRace.raceId,
            }
        
            dispatch(getAllProducts(requestData));
		}
	}, [currentRace]);

    const handleSaveProduct = (updateProducts, modifiedProducts, statusMenu = 'publish') => {
        const requestData = {
            token: localStorage.getItem('token'),
            kitchenId: currentRace.kitchenId,
            menu: {
                days: [
                    {
                        day: currentRace.deliveryDay,
                        races: [
                            {
                                raceId: 1,
                                statusMenu: statusMenu, //'publish' /'draft'
                                disableOldProducts: false, //Для копирования меню на другие дни. Нужно указать на какую дату
                                products: modifiedProducts
                                    .map(({ productId, price, amountForOrder, status }) => ({
                                        productId,
                                        price,
                                        amountForOrder: amountForOrder || 0,
                                        status
                                    })),
                            }
                        ]
                    }
                ]
            }
        }
    
        dispatch(addMenu(requestData))
            .then(() => {
                dispatch(updateAllProducts(updateProducts));
                setIsEdited(false);
                setHaveMenu(true);
            })
            .catch((error) => {
                console.error("Ошибка при добавлении меню:", error);
            });
    }

    const stopMenu = () => {
        dispatch(toggleMenuStatus({
            token: localStorage.getItem('token'),
            menuId: currentRace.menu[0].id,
            enabled: true,
        }))
        .then(() => {
            setIsBlocked(true);
        })
        .catch((error) => {
            console.error("Ошибка при обновлении статуса меню:", error);
        });
    }

    const startMenu = () => {
        dispatch(toggleMenuStatus({
            token: localStorage.getItem('token'),
            menuId: currentRace.menu[0].id,
            enabled: false,
        }))
        .then(() => {
            setIsBlocked(false);
        })
        .catch((error) => {
            console.error("Ошибка при обновлении статуса меню:", error);
        });
    }

    if (isMobile) {
        return (
            <Page>
                <ScrolledCol>
                    <MobileHeader>
                        <BackButton text={'Публикация меню'} link={'/kitchen/dashboard'}/>
                        <CalendarIcon width='32px' height='32px' color='#b2b2b2'/>
                    </MobileHeader>

                    {isEdited ?
                        <Card style={{flex: '0 0 auto', marginBottom: '16px'}}>
                            <RaceInfo column align='flex-start'>
                                <Title level={3}>{moment(currentRace.deliveryDay).format('dddd D MMMM')}</Title>
                                <Flex align={'center'} gap={'10px'} style={{marginTop: '20px', textWrap: 'nowrap'}}>
                                    <Text size='16px'>{adjustedTimeStart}-{adjustedTimeEnd}</Text>
                                    <div style={{color: '#cccccc'}}>|</div>
                                    <Flex align={'center'} gap={'8px'}>
                                        <UsertimeIcon width='20px' height='20px' color={'#FF8800'}/>
                                        <Text size='16px' color='#FF8800'>10:15</Text>
                                    </Flex>
                                </Flex>
                            </RaceInfo>
                        </Card>
                    : null}

                    {isEdited ? 
                        <ProductTabs products={allProducts} onSave={handleSaveProduct}/>
                    : null}
                </ScrolledCol>
            </Page>
        )
    }

    return (
        <Page>
			<ScrolledCol>
				<Container>
					<VerticalSpace>

                        <Header>
                            <BackButton
                                text={haveMenu ? isEdited ? 'Опубликованное меню' : 'Главная страница' : 'Главная страница'}
                                link={haveMenu ? isEdited ? undefined : '/kitchen/dashboard' : '/kitchen/dashboard'}
                                onClick={haveMenu && isEdited ? () => setIsEdited(false) : undefined}
                            />
                            {isEdited ?
                                <RaceInfo column align='flex-end'>
                                    <Title level={1}><CalendarIcon width='36px' height='36px' color='#b2b2b2'/> {moment(currentRace.deliveryDay).format('dddd D MMMM')}</Title>
                                    <Flex align={'center'} gap={'10px'} style={{marginTop: '20px', textWrap: 'nowrap'}}>
                                        <Title level={2}>{adjustedTimeStart}-{adjustedTimeEnd}</Title>
                                        <div style={{color: '#cccccc'}}>|</div>
                                        <Flex align={'center'} gap={'8px'}>
                                            <UsertimeIcon color={'#FF8800'}/>
                                            <Title level={2} color='#FF8800'>10:15</Title>
                                        </Flex>
                                    </Flex>
                                </RaceInfo>
                            : null}
                        </Header>

                        {isEdited ? 
                            <ProductTabs products={allProducts} onSave={handleSaveProduct}/>
                        :
                            <Flex gap='24px'>
                                <Flex flex='2'><Menu isBlocked={isBlocked} currentRace={currentRace} timeStart={adjustedTimeStart} timeEnd={adjustedTimeEnd} products={allProducts}/></Flex>
                                <Flex column flex='1' gap='24px'>
                                    <MenuActions>
                                        <Button color='#2A2A2A' style={{width: '100%'}} onClick={() => setIsEdited(true)}>Редактировать меню</Button>
                                        {isBlocked ?
                                            <Tooltip title={'Возобновить приём заказов'} text={'На данный момент заказы приостановлены'} placement="topRight" open={true}>
                                                <div><Button type='icon' color='#49BC9A' onClick={startMenu}><PlayIcon/></Button></div>
                                            </Tooltip>
                                        :
                                            <Tooltip title={'Приостановить приём заказов'} text={'Меню не будет удалено'} placement="topRight">
                                                <div><Button type='icon' color='#EA394B' onClick={stopMenu}><StopIcon/></Button></div>
                                            </Tooltip>
                                        }
                                    </MenuActions>
                                    <MenuInfo>
                                        <Title level={3}>Заказы</Title>
                                        <RaceInfo>
                                            {currentRace?.periods && currentRace?.periods.length > 0 ?
                                                <Flex column gap={'10px'} style={{width: '100%'}}>
                                                    {currentRace?.periods.map((period) => (
                                                        <Period period={period} day={currentRace?.deliveryDay} serverTime={serverTime} timezone={timezone}/>
                                                    ))}
                                                </Flex>
                                            : currentRace?.isWorkRace && currentRace?.menu.length === 0 ?
                                                <InfoTitle>
                                                    <TimeIcon/>
                                                    <Text size='14px'>Время вышло, рекомендуем<br/>публиковать меню заранее</Text>
                                                </InfoTitle>
                                            : currentRace?.isWorkRace && currentRace?.menu.length === 0 ?
                                                <InfoTitle>
                                                    <WarningIcon/>
                                                    <Text size='14px'>Меню пока никто не<br/>опубликовал, будьте первыми</Text>
                                                </InfoTitle>
                                            : null}
                                        </RaceInfo>
                                    </MenuInfo>
                                    <MenuStats>
                                        <CardSection>
                                            <MenuStatsHeader>
                                                <Title level={3}>Товаров в меню</Title>
                                                <Text strong size='24px'>{allProducts && allProducts.length ? allProducts.filter(product => product.status === 'on').length : 0}</Text>
                                            </MenuStatsHeader>
                                            <MenuStatsList>
                                                <MenuStatsListItem>
                                                    <p>Без фото</p><span>{allProducts && allProducts.length ? allProducts.filter(product => product.status === 'on' && product.filePathPublic === '').length : 0}</span>
                                                </MenuStatsListItem>
                                                <MenuStatsListItem>
                                                    <p>Без КБЖУ</p><span>{allProducts && allProducts.length ? allProducts.filter(product => product.status === 'on' && (Number(product.calories) === 0 || Number(product.carbs) === 0 || Number(product.fats) === 0 || Number(product.proteins) === 0)).length : 0}</span>
                                                </MenuStatsListItem>
                                                <MenuStatsListItem>
                                                    <p>Хлеб</p><span>{allProducts && allProducts.length ? allProducts.filter(product => product.status === 'on' && product.categoryId === 50).length : 0}</span>
                                                </MenuStatsListItem>
                                                <MenuStatsListItem>
                                                    <p>Приборы</p><span>{allProducts && allProducts.length ? allProducts.filter(product => product.status === 'on' && product.categoryId === 10).length : 0}</span>
                                                </MenuStatsListItem>   
                                            </MenuStatsList>
                                        </CardSection>
                                    </MenuStats>
                                </Flex>
                            </Flex>
                        }

                    </VerticalSpace>
				</Container>
			</ScrolledCol>
		</Page>
    );
};

export default MenuPage;

const MobileHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
    padding: 20px 16px;
    width: 100%;
    background: #ffffff;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
    overflow: hidden;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 32px;
    flex-wrap: wrap;
    margin-bottom: 32px;
`
const MenuActions = styled.div`
    display: flex;
    gap: 16px;
`
const MenuInfo = styled(Card)`
    flex: 0 0 auto;
`
const RaceInfo = styled(Flex)`

`
const InfoTitle = styled.div`
    display: flex;
    gap: 10px;
    font-size: 14px;
`
const MenuStats = styled(Card)`
    flex: 0 0 auto;
`
const MenuStatsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: solid 1px #eeeeee;
`

const MenuStatsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`
const MenuStatsListItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    font-size: 18px;
`