import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  currentRoute: '/',
  currentModule: 'kitchen',
};

const navSlice = createSlice({
  name: 'nav',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    setCurrentRoute: (state, action) => {
      state.currentRoute = action.payload;
    },
    setCurrentModule: (state, action) => {
      state.currentModule = action.payload;
    },
    // updateCurrentModule: (state, action) => {
    //   const updatedKeys = action.payload;

    //   return {
    //     ...state,
    //     currentModule: {
    //       ...state.currentModule,
    //       ...updatedKeys,
    //     },
    //   };
    // },
  },
});

export const {
  setCurrentRoute,
  setCurrentModule,
} = navSlice.actions;

export default navSlice.reducer;
