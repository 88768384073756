import React, { useState, useEffect } from 'react';
import { Table, Modal, Form, Input, Popconfirm, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const EntityTable = ({entityData, currentRecord, onCellClick, onUpdate, onDelete}) => {
  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      width: '300px',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => onCellClick(record),
      }),
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => onCellClick(record),
      }),
    },
    {
      title: '',
      key: 'actions',
      width: '160px',
      render: (_, record) => (
        <AccessAction
          record={record}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      ),
    },
  ];

  return (
    <Table
      dataSource={entityData}
      columns={columns}
      size="small"
      pagination={{
        defaultPageSize: 5,
      }}
      onRow={(record) => ({
        style: { background: record.id === currentRecord?.id ? '#e6f7ff' : '' },
      })}
    />
  );
};

export default EntityTable;

const AccessAction = ({record, onUpdate, onDelete}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(record);
  }, [record]);

  const handleEdit = () => {
    setIsModalVisible(true);
  }

  const handleSubmit = (values) => {
    onUpdate(values, record.id);
    setIsModalVisible(false);
    form.resetFields();
  }

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleDelete = () => {
    onDelete(record.id);
  };

  return (
    <Action>
      <Button onClick={handleEdit}><EditOutlined/></Button>
      <Modal
        title="Редактирование метода"
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Form
          form={form}
          name='updateForm'
          onFinish={handleSubmit}
        >
          <Form.Item label="Название" name="name">
            <Input/>
          </Form.Item>
          <Form.Item label="Описание" name="description">
            <Input/>
          </Form.Item>
          <Button type="primary" htmlType='submit'>
            Сохранить
          </Button>
        </Form>
      </Modal>
      <Popconfirm
        title="Удаление метода доступа!"
        description={`Вы действительно хотите удалить метод доступа ${record.name}`}
        okText="Да"
        cancelText="Нет"
        placement="left"
        onConfirm={handleDelete}
      >
        <Button danger={true}>Удалить</Button>
      </Popconfirm>
    </Action>
  );
};

const Action = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`