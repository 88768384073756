import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Image, Popconfirm, Flex } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ImageUploadAndCrop from './ImageUploadAndCrop';

const ImageComponent = ({ isAdmin, onUploadImage, onRemoveImage }) => {
  const { selectKitchen } = useSelector((store) => store.kitchen);

  return (
    <div style={{display: 'flex', flex: '1', minWidth: '330px'}}>
      {selectKitchen && selectKitchen.filePathPublic ? 
        <div style={{position: 'relative', maxWidth: '500px', height: '250px', borderRadius: '25px', overflow: 'hidden'}}>
          <Image
            style={{ width: '500px', height: '250px', objectFit: 'cover'}}
            alt="example"
            src={selectKitchen.filePathPublic}
            mask='false'
            preview={{
              toolbarRender: (_,{}) => (<Flex size={12} className="toolbar-wrapper"></Flex>),
            }}
            fallback={`data:image/svg+xml;charset=utf-8,${encodeURIComponent(`<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="40" height="40" rx="12" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2676 12.6584C18.4166 12.3847 21.5835 12.3847 24.7325 12.6584L26.2428 12.7897C27.6878 12.9153 28.8585 14.015 29.0742 15.4494C29.5279 18.4661 29.5279 21.5337 29.0742 24.5504C29.0345 24.8143 28.9625 25.0668 28.8624 25.3041C28.7961 25.4614 28.5913 25.4853 28.4764 25.3589L24.055 20.4954C23.8552 20.2756 23.5446 20.1945 23.2629 20.2884L20.7321 21.132L17.0606 17.0016C16.9234 16.8472 16.7286 16.7563 16.5221 16.7502C16.3156 16.7441 16.1158 16.8235 15.9697 16.9695L11.0556 21.8837C10.9044 22.0349 10.6464 21.9386 10.6344 21.7251C10.5161 19.6312 10.6133 17.5282 10.9259 15.4494C11.1416 14.015 12.3123 12.9153 13.7574 12.7897L15.2676 12.6584ZM22.0001 16.9999C22.0001 16.1715 22.6716 15.4999 23.5001 15.4999C24.3285 15.4999 25.0001 16.1715 25.0001 16.9999C25.0001 17.8283 24.3285 18.4999 23.5001 18.4999C22.6716 18.4999 22.0001 17.8283 22.0001 16.9999Z" fill="#B2B2B2"/>
              <path d="M10.9614 24.0992C10.906 24.1546 10.8797 24.2326 10.8908 24.3102C10.9022 24.3903 10.9139 24.4703 10.9259 24.5504C11.1416 25.9848 12.3123 27.0844 13.7574 27.21L15.2676 27.3413C18.4166 27.6151 21.5835 27.6151 24.7325 27.3413L26.2428 27.21C26.6674 27.1731 27.0683 27.0521 27.4283 26.8632C27.5647 26.7916 27.5877 26.6113 27.4841 26.4973L23.3833 21.9864C23.3167 21.9131 23.2132 21.8861 23.1192 21.9174L20.7372 22.7114C20.4526 22.8063 20.1388 22.7224 19.9395 22.4982L16.6441 18.7908C16.5485 18.6833 16.3822 18.6784 16.2805 18.7801L10.9614 24.0992Z" fill="#B2B2B2"/>
              </svg>
            `)}`}
          />
          {isAdmin && <Popconfirm
            title="Удаление картинки!"
            description={`Вы действительно хотите удалить картинку`}
            okText="Да"
            cancelText="Нет"
            placement="bottom"
            onConfirm={onRemoveImage}
          >
            <Button shape="circle" style={{position: 'absolute', top: '5px', right: '5px'}} icon={<DeleteOutlined/>}/>
          </Popconfirm>}
        </div>
      :
        <ImageUploadAndCrop
          isAdmin={isAdmin}
          onFileUpload={onUploadImage}
        />
      }
    </div>
  );
};

export default ImageComponent;
