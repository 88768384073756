import apiInstance from '../../../../api';
import {call, put, select} from 'redux-saga/effects';
import {
  //Code To Phone
  sendCodeToPhoneSuccess,
  sendCodeToPhoneError,

  //Token By Session
  createTokenBySessionSuccess,
  createTokenBySessionError,

  //Logout
  logoutSuccess,
  logoutError,

  //Give Role To New User
  sendRequestGiveRoleToNewUserSuccess,
  sendRequestGiveRoleToNewUserError,

  //User Profile
  sendRequestUserProfileSuccess,
  sendRequestUserProfileError,
  setUserProfile,
} from '../../../reducers/utils/authReducer';
import { selectCommonState } from '../../../reducers/utils/commonReducer';

//Code To Phone
export function* handleSendCodeToPhone(action) {
  const api = '/auth/sendCodeToPhone?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      localStorage.setItem('session', response.data.data.session);
      yield put(sendCodeToPhoneSuccess());
    } else {
      yield put(sendCodeToPhoneError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendCodeToPhoneError());
  }
}

//Token By Session
export function* handleCreateTokenBySession(action) {
  const api = '/auth/createTokenBySession?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      localStorage.setItem('token', response.data.data.token);
      yield put(createTokenBySessionSuccess(response.data.data.isNewUser));
    } else {
      yield put(createTokenBySessionError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(createTokenBySessionError());
  }
}

//Logout
export function* handleLogout(action) {
  const api = '/auth/logout?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(logoutSuccess());
    } else {
      yield put(logoutError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(logoutError());
  }
}

//Give Role To New User
export function* handleGiveRoleToNewUser(action) {
  const api = '/access/roleUsers/createDefaultRole?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestGiveRoleToNewUserSuccess());
    } else {
      yield put(sendRequestGiveRoleToNewUserError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestGiveRoleToNewUserError());
  }
}

//User Profile
export function* handleCreateUserProfile(action) {
  const api = '/user/getProfile?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestUserProfileSuccess());
      yield put(setUserProfile(response.data.data));
    } else {
      yield put(sendRequestUserProfileError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestUserProfileError());
  }
}
