import apiInstance from '../../../../../api';
import {call, put, select} from 'redux-saga/effects';
import {
  //Search
  sendRequestFinancialSettlementGetSuccess,
  sendRequestFinancialSettlementGetError,
  setFinancialSettlement,
} from '../../../../reducers/utils/moderationReducers/financialSettlementReducer';
import { selectCommonState } from '../../../../reducers/utils/commonReducer';

//Search
export function* handleFinancialSettlement(action) {
  const api = '/kitchen/actWorks/getPaymentsToKitchens?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestFinancialSettlementGetSuccess());
      yield put(setFinancialSettlement(response.data.data.paymentsToKitchensTable));
    } else {
      yield put(sendRequestFinancialSettlementGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestFinancialSettlementGetError());
  }
}
