import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  setIsShowRaceKitchenDrawer,
  setCurrentRaceKitchen,
  sendRequestDeliveryServiceRaceKitchenAdd,
  sendRequestDeliveryServiceRaceKitchenUpdate,
} from '../../../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Flex, Drawer, Typography, Button, Form, Input, Switch, Space, TimePicker } from 'antd';
import KitchenTermsComponent from "./KitchenTermsComponent";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const { Title, Text } = Typography;
const format = 'HH:mm';

const KitchenDrawerComponent = () => {
  const { isShowRaceKitchenDrawer, deliveryServiceRaceKitchens, currentRaceKitchen } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = useState(false);

  useEffect(() => {
    if (currentRaceKitchen !== null) {
      dispatch(setCurrentRaceKitchen(deliveryServiceRaceKitchens.find(kitchen => kitchen.kitchenId === currentRaceKitchen.kitchenId && kitchen.racePolygonId === currentRaceKitchen.racePolygonId)));
    }
  }, [deliveryServiceRaceKitchens]);

  useEffect(() => {
    if (currentRaceKitchen) {
      setIsFormChanged(false);
      form.setFieldsValue({
          shutdownTime: currentRaceKitchen.shutdownTime 
            ? dayjs(currentRaceKitchen.shutdownTime, 'HH:mm').add(currentRaceKitchen.timezoneCity, 'hours')
            : null,
          minimumOrderAmount: currentRaceKitchen.minimumOrderAmount,
          timeTransferringOrderToCourier: currentRaceKitchen.timeTransferringOrderToCourier 
            ? dayjs(currentRaceKitchen.timeTransferringOrderToCourier, 'HH:mm').add(currentRaceKitchen.timezoneCity, 'hours')
            : null,
        });
    }
  }, [currentRaceKitchen, form]);

  const handleUpdateOrganization = () => {
    form.validateFields()
      .then((values) => {
        const requestData = {
          token: localStorage.getItem('token'),
          kitchenRacePolygonId: currentRaceKitchen.kitchenRacePolygonId,
          kitchenId: currentRaceKitchen.kitchenId,
          racePolygonId: currentRaceKitchen.racePolygonId,
          shutdownTime: dayjs(values.shutdownTime).subtract(currentRaceKitchen.timezoneCity, 'hours').format('HH:mm'),
          minimumOrderAmount: values.minimumOrderAmount,
          timeTransferringOrderToCourier: dayjs(values.timeTransferringOrderToCourier).subtract(currentRaceKitchen.timezoneCity, 'hours').format('HH:mm'),
          isEnabled: 1,
        };

        console.log(requestData);
        dispatch(sendRequestDeliveryServiceRaceKitchenUpdate(requestData));
        handleCloseDrawer();
      })
      .catch((errorInfo) => {
        console.error('Ошибка при валидации формы:', errorInfo);
      });
  };

  const handleCloseDrawer = () => {
    form.resetFields();
    setIsFormChanged(false);
    dispatch(setIsShowRaceKitchenDrawer(false));
    dispatch(setCurrentRaceKitchen(null));
  };

  const handleFormChange = () => {
    setIsFormChanged(true);
  };

  const onChange = (record, checked) => {
    const createData = {
      token: localStorage.getItem('token'),
      kitchenId: record.kitchenId,
      racePolygonId: record.racePolygonId,
      shutdownTime: record.shutdownTime,
      serviceFee: record.serviceFee,
      minimumOrderAmount: record.minimumOrderAmount,
      timeTransferringOrderToCourier: record.timeTransferringOrderToCourier,
    }
    const updateData = {
      token: localStorage.getItem('token'),
      kitchenRacePolygonId: record.kitchenRacePolygonId,
      kitchenId: record.kitchenId,
      racePolygonId: record.racePolygonId,
      shutdownTime: record.shutdownTime,
      serviceFee: record.serviceFee,
      minimumOrderAmount: record.minimumOrderAmount,
      timeTransferringOrderToCourier: record.timeTransferringOrderToCourier,
      isEnabled: checked,
    }

    if (record.kitchenRacePolygonId === 0) {
      console.log('add kitchenRacePolygonId', record.kitchenRacePolygonId, createData);
      dispatch(sendRequestDeliveryServiceRaceKitchenAdd(createData));
    } else {
      console.log('update kitchenRacePolygonId', record.kitchenRacePolygonId, updateData);
      dispatch(sendRequestDeliveryServiceRaceKitchenUpdate(updateData));
    }
  };
console.log(currentRaceKitchen)
  return (
    <Drawer
      title={
        <Flex gap={20}>
          { isFormChanged &&
            <>
              <Button type="primary" onClick={handleUpdateOrganization}>Сохранить</Button>
              <Button onClick={handleCloseDrawer}>Отменить</Button>
            </>
          }
        </Flex>
      }
      placement="right"
      onClose={handleCloseDrawer}
      open={isShowRaceKitchenDrawer}
      width='40%'
      mask={false}
    >
      <Title level={3}>{currentRaceKitchen?.name}</Title>
      <Space style={{marginBottom: '20px'}}>
        <Switch checked={currentRaceKitchen?.isEnabled} onChange={(checked) => onChange(currentRaceKitchen, checked)}/>
        {currentRaceKitchen?.polygonName}
      </Space>
      <Form
        form={form}
        onValuesChange={handleFormChange}
        // initialValues={currentRaceKitchen && {
        //   shutoff: currentRaceKitchen.shutoff,
        //   minimumOrderAmount: currentRaceKitchen.minimumOrderAmount,
        //   timeTransferringOrderToCourier: currentRaceKitchen.timeTransferringOrderToCourier 
        //     ? dayjs(currentRaceKitchen.timeTransferringOrderToCourier, 'HH:mm') 
        //     : null,
        // }}
        disabled={!currentRaceKitchen?.isEnabled}
      >
        <Form.Item label="Стоп приёма заказов" name="shutdownTime" rules={[{ required: true, message: 'Обязательное поле!' }]}>
          <TimePicker
            format={format}
            placeholder="Выберите время"
            style={{ width: '100%' }} 
          />
        </Form.Item>
        <Form.Item label="Мин. сумма заказа" name="minimumOrderAmount" rules={[{ required: true, message: 'Обязательное поле!' }]}><Input/></Form.Item>
        <Form.Item label="Время передачи заказов" name="timeTransferringOrderToCourier" rules={[{ required: true, message: 'Обязательное поле!' }]}>
          <TimePicker
            format={format}
            placeholder="Выберите время"
            style={{ width: '100%' }} 
          />
        </Form.Item>
      </Form>

      <Flex vertical gap='small'>
        <Title level={4}>Условия доставки</Title>
        <KitchenTermsComponent/>
      </Flex>
    </Drawer>
  );
}

export default KitchenDrawerComponent;
