import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSteps, updateSteps } from '../../../../../redux/reducers/utils/kitchenReducers/kitchenReducer';
import { Form, Switch } from 'antd';
import Card from '../../../../../components/Card';
import styled from 'styled-components';
import { Title } from '../../../../../core/styles';

const AdminCheckList = () => {
  const { selectKitchen } = useSelector((store) => store.kitchen);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if(selectKitchen && !selectKitchen.stepsStartKitchen) {
      dispatch(getSteps({token: localStorage.getItem('token'), kitchenId: selectKitchen.id}));
    }
  }, [selectKitchen])

  useEffect(() => {
    if (selectKitchen && selectKitchen.stepsStartKitchen) {
      form.setFieldsValue({
        concludeContract: selectKitchen.stepsStartKitchen.concludeContract || false,
        fillListProducts: selectKitchen.stepsStartKitchen.fillListProducts || false,
        dishesAndBagsBeenPurchased: selectKitchen.stepsStartKitchen.dishesAndBagsBeenPurchased || false,
        testRunBeen: selectKitchen.stepsStartKitchen.testRunBeen || false,
      });
    }
  }, [selectKitchen]);

  const handleValuesChange = (changedValues, allValues) => {
    console.log('Все значения:', allValues);
    
    dispatch(updateSteps({
      token: localStorage.getItem('token'),
      kitchenId: selectKitchen.id,
      ...allValues
    }))
    .then(() => {
      return dispatch(getSteps({token: localStorage.getItem('token'), kitchenId: selectKitchen.id}));
    })
  };

  return (
    <StyledCard title={<Title level={4}>Шаги запуска кухни</Title>}>
      <Form 
        form={form}
        // labelCol={{ span: 20 }}
        // wrapperCol={{ span: 4 }}
        initialValues={{
          concludeContract: selectKitchen?.stepsStartKitchen?.concludeContract || false,
          fillListProducts: selectKitchen?.stepsStartKitchen?.fillListProducts || false,
          dishesAndBagsBeenPurchased: selectKitchen?.stepsStartKitchen?.dishesAndBagsBeenPurchased || false,
          testRunBeen: selectKitchen?.stepsStartKitchen?.testRunBeen || false,
        }}
        onValuesChange={handleValuesChange}
      >
        <Form.Item label="Заключён договор" name="concludeContract">
          <Switch/>
        </Form.Item>

        <Form.Item label="Заполнен список товаров" name="fillListProducts">
          <Switch/>
        </Form.Item>

        <Form.Item label="Закуплена посуда и пакеты" name="dishesAndBagsBeenPurchased">
          <Switch/>
        </Form.Item>

        <Form.Item label="Проведен тестовый запуск" name="testRunBeen">
          <Switch/>
        </Form.Item>
      </Form>
    </StyledCard>
  );
};

export default AdminCheckList;

const StyledCard = styled(Card)`
  flex: 1 1 330px;
`
