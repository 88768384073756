import React from 'react';
import { useSelector } from 'react-redux';
import Loader from '../../../../components/Loader';
import DeliveryLeftPanel from './components/DeliveryLeftPanel';
import DeliveryRightPanel from './components/DeliveryRightPanel';
import DeliveryMap from './components/DeliveryMap';

const DeliveryMapPage = () => {
  const {
    assignCourierToAddressesStatus,
    addressChangeGeopointStatus,
    updateAddressCommentStatus,
    updateOrderCommentStatus,
  } = useSelector((store) => store.deliveryMap);

  return (
    <>
      {(
        assignCourierToAddressesStatus === 'pending' ||
        addressChangeGeopointStatus === 'pending' ||
        updateAddressCommentStatus === 'pending' ||
        updateOrderCommentStatus === 'pending'
      ) && <Loader />}
      <DeliveryLeftPanel/>
      <DeliveryRightPanel/>
      <DeliveryMap/>
    </>
  );
};

export default DeliveryMapPage;
