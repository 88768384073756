import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestUserKitchenCreate } from '../../../../../redux/reducers/utils/kitchenReducers/kitchenReducer';
import { Flex, Typography, Button, Modal, Form, Input, Select } from 'antd';
import styled from 'styled-components';
import { PlusIcon } from '../../../../../components/Icons';

const { Title } = Typography;

const NewKitchenCreateComponent = ({ green = false }) => {
  const { cities } = useSelector((store) => store.settingsCatalogs);
  const dispatch = useDispatch();
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [form] = Form.useForm();

  const citiesOptions = cities?.map((item) => ({
    ...item,
    value: item.id,
    label: item.name,
  }));

  const handleCancel = () => {
    setCreateModalVisible(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    const requestData ={
      token: localStorage.getItem('token'),
      name: values.name,
      cityId: values.city,
    };

    dispatch(sendRequestUserKitchenCreate(requestData));
    setCreateModalVisible(false);
    form.resetFields();
  };

  return (
    <>
      <StyledCreateButton green={green} onClick={() => setCreateModalVisible(true)}>
        Добавить кухню
        <PlusIcon/>
      </StyledCreateButton>

      <Modal
        title="Создание новой кухни"
        open={createModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form style={{ width: '100%' }} form={form} name='deliveryService' layout='vertical' onFinish={handleSubmit}>
          <Form.Item label='Название' name='name' rules={[{ required: true, message: 'Введите название' }]}>
            <Input placeholder='Например "Кафе Весна"'/>
          </Form.Item>
          <Form.Item label='Город' name='city' rules={[{ required: true, message: 'Выберите город' }]}>
            <Select
              showSearch
              placeholder="Выберите город"
              optionFilterProp="children"
              options={citiesOptions}
              filterOption={(input, option) =>
                (option?.name?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionRender={(option) => (
                <Flex vertical>
                  <Title level={5}>{option.data.name}</Title>
                </Flex>
              )}
            />
          </Form.Item>
          <Button type='primary' htmlType='submit' style={{ display: 'flex', marginLeft: 'auto' }}>
            Создать
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default NewKitchenCreateComponent;

const StyledCreateButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 25px;
  height: ${({green}) => green === true ? '60px' : '67px'};
  background: ${({green}) => green === true ? '#49BC9A' : 'transparent'};
  border: ${({green}) => green === true ? 'dashed 1px #49BC9A' : 'dashed 1px var(--color-font)'};
  border-radius: 24px;
  font-size: ${({green}) => green === true ? '20px' : '16px'};
  line-height: 19px;
  color: ${({green}) => green === true ? '#ffffff' : 'var(--color-font)'};
  text-wrap: nowrap;
  transition: all .3s;
  &:hover {
    scale: 1.05;
  }
`