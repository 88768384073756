import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectOrder,
  setPlacemarkDragActivate,
  setPlacemarkDragCancel,
  updateMapDataPoints,
  sendRequestForAddressChangeGeopoint,
  sendRequestForUpdateAddressComment,
  fetchAddAddressToLocationMidpoint,
  fetchUpdateAddressToLocationMidpoint,
  fetchRemoveAddressToLocationMidpoint,
  fetchAddAddressToLocationEntrance,
  fetchUpdateAddressToLocationEntrance,
  fetchRemoveAddressToLocationEntrance,
} from '../../../../../redux/reducers/utils/deliveryReducers/deliveryMapReducer';
import { ReactComponent as IconStar } from '../../../../../assets/icons/icon-star.svg';
import { ReactComponent as IconUser } from '../../../../../assets/icons/icon-user.svg';
import { ReactComponent as IconCash } from '../../../../../assets/icons/icon-сash.svg';
import { ReactComponent as IconEdit } from '../../../../../assets/icons/icon-edit.svg';
import { ReactComponent as IconOK } from '../../../../../assets/icons/icon-success.svg';
import { Button, Select } from 'antd';
import { CloseOutlined, ImportOutlined, SelectOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import OrderCard from './OrderCard';
import Tooltip from '../../../../../ui/Tooltip';

const { Option } = Select;

const AddressDetailComponent = () => {
  const {
    selectedOrder,
    addressChangeGeopointStatus,
    updateAddressCommentStatus,
  } = useSelector((store) => store.deliveryMap);
  const dispatch = useDispatch();
  const [comment, setComment] = useState('')
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);
  const [midpointValue, setMidpointValue] = useState(0);
  const [entranceValue, setEntranceValue] = useState(0);

  useEffect(() => {
    setMidpointValue(selectedOrder.meetingPoints && selectedOrder.meetingPoints.length > 0 ? selectedOrder.meetingPoints[0].meetingPointId : 0)
    setEntranceValue(selectedOrder.entrances && selectedOrder.entrances.length > 0 ? selectedOrder.entrances[0].locationEntranceId : 0)
  }, [selectedOrder])

  useEffect(() => {
    if (addressChangeGeopointStatus === 'resolved') {
      dispatch(setPlacemarkDragCancel());
    }
  }, [addressChangeGeopointStatus, dispatch]);

  const handleEditCommentActivate = () => {
    setIsEditing(true);
  };

  const handleEditCommentSubmit = () => {
    const updatedPoints = [{
      id: selectedOrder.addressId,
      addressComment: comment,
    }];
    const requestData = {
      token: localStorage.getItem('token'),
      addressId: selectedOrder.addressId,
      comment: comment,
    };
    dispatch(updateMapDataPoints(updatedPoints));
    dispatch(sendRequestForUpdateAddressComment(requestData));
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  }

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.select();
    }
  }, [isEditing]);

  useEffect(() => {
    setComment('');
    setIsEditing(false);
  }, [selectedOrder]);

  const handleClose = () => {
    dispatch(selectOrder(null));
  };

  const handleChangeMidpoint = (value) => {
    setMidpointValue(value);
    if (value !== 0) {
      if (selectedOrder.meetingPoints.length === 0) {
        dispatch(fetchAddAddressToLocationMidpoint({
          token: localStorage.getItem('token'),
          addressId: selectedOrder.addressId,
          meetingPointId: value,
        }));
      } else {
        dispatch(fetchUpdateAddressToLocationMidpoint({
          token: localStorage.getItem('token'),
          addressId: selectedOrder.addressId,
          meetingPointId: value,
          locationsMeetingPointsAddressesId: selectedOrder.meetingPoints[0].id,
        }));
      }
    } else {
      dispatch(fetchRemoveAddressToLocationMidpoint({
        token: localStorage.getItem('token'),
        locationsMeetingPointsAddressesId: selectedOrder.meetingPoints[0].id,
      }));
    }
  };

  const handleChangeEntrance = (value) => {
    setMidpointValue(value);
    if (value !== 0) {
      if (selectedOrder.entrances.length === 0) {
        dispatch(fetchAddAddressToLocationEntrance({
          token: localStorage.getItem('token'),
          addressId: selectedOrder.addressId,
          locationEntranceId: value,
        }));
      } else {
        dispatch(fetchUpdateAddressToLocationEntrance({
          token: localStorage.getItem('token'),
          addressId: selectedOrder.addressId,
          locationEntranceId: value,
          locationEntranceAddressId: selectedOrder.entrances[0].id,
        }));
      }
    } else {
      dispatch(fetchRemoveAddressToLocationEntrance({
        token: localStorage.getItem('token'),
        locationEntranceAddressId: selectedOrder.entrances[0].id,
      }));
    }
  };

  return (
    <StyledOrderComponentWrapper>
      <StyledOrderComponent>
        <Button
          style={{ position: 'absolute', top: '5px', right: '5px', zIndex: 800 }}
          type='text'
          icon={<CloseOutlined/>}
          onClick={handleClose}
        />
        Подробно о заказе
        <StyledRow>
          <StyledId>ID {selectedOrder.addressId}</StyledId>
          {selectedOrder.isNewAddress && <><IconStar /> <StyledNewUserCount>{selectedOrder.countUsersNeverOrdered}</StyledNewUserCount></>}
          {selectedOrder.orders &&
            <>
              <StyledOrders><IconUser /> {selectedOrder.orders.length}</StyledOrders>
              <StyledPayment><IconCash /> {selectedOrder.orders.reduce((total, order) => total + order.payment.finalPrice, 0)}</StyledPayment>
            </>
          }
        </StyledRow>
        <StyledRow>
          <StyledAddress>{selectedOrder.address}</StyledAddress>
        </StyledRow>
        <StyledName>{selectedOrder.addressName}</StyledName>
        <StyledComment>
          {!isEditing ? 
            <>
              <StyledCommentValue>{selectedOrder.addressComment}</StyledCommentValue>
              <StyledChangeCommentButton onClick={handleEditCommentActivate}><IconEdit /></StyledChangeCommentButton>
            </>
            :
            <>
              <StyledCommentInput onChange={handleCommentChange} ref={inputRef} disabled={!isEditing} />
              <StyledChangeCommentButton onClick={handleEditCommentSubmit}><IconOK /></StyledChangeCommentButton>
            </>
          }
        </StyledComment>
        {selectedOrder.locationMidpoints && selectedOrder.locationMidpoints.length > 0 &&
          <StyledRow>
            <Select 
              style={{ width: '100%' }} 
              placeholder="Выберите митпоинт"
              value={midpointValue}
              onChange={handleChangeMidpoint}
            >
              <Option key={0} value={0}>Не выбрано</Option>
              {selectedOrder.locationMidpoints.map(item => (
                <Option key={item.id} value={item.id}>{item.name}</Option>
              ))}
            </Select>
            <Tooltip title="Выбор мидпоинта">
              <ImportOutlined/>
            </Tooltip>
          </StyledRow>
        }
        {selectedOrder.locationEntrance && selectedOrder.locationEntrance.length > 0 &&
          <StyledRow>
            <Select 
              style={{ width: '100%' }} 
              placeholder="Выберите вход"
              value={entranceValue}
              onChange={handleChangeEntrance}
            >
              <Option key={0} value={0}>Не выбрано</Option>
              {selectedOrder.locationEntrance.map(item => (
                <Option key={item.id} value={item.id}>{item.name}</Option>
              ))}
            </Select>
            <Tooltip title="Выбор входа">
              <SelectOutlined/>
            </Tooltip>
          </StyledRow>
        }
        {selectedOrder.orders &&
          <>
            <StyledOrderCounter>Найдено: {selectedOrder.orders.length} заказов</StyledOrderCounter>
            <StyledOrderCards>
              {selectedOrder.orders.map((order, index) => (
                <OrderCard key={order.id} selectedOrder={selectedOrder} order={order} index={index} />
              ))}
            </StyledOrderCards>
          </>
        }
      </StyledOrderComponent>
    </StyledOrderComponentWrapper>
  );
};

export default AddressDetailComponent;

const StyledOrderComponentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 300px;
`;
const StyledOrderComponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: #ffffff;
  padding: 10px 20px;
  width: 100%;
  border: 1px solid #f1f1f1;
  font-size: 15px;
`;
const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px 5px;
`;
const StyledId = styled.div`
  font-size: 20px;
`;
const StyledNewUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;
const StyledNewUserCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background-color: #ffd954;
`;
const StyledOrders = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;
const StyledPayment = styled.div`
  display: flex;
  gap: 5px;
`;
const StyledAddress = styled.div`
  font-size: 13px;
  color: #3498DB;
`;
const StyledPlacemarkDragButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: #f1f1f1;
  border-radius: 5px;
  color: #222222;
  cursor: pointer;
  transition: all .3s;
  &:hover {
    background: #999999;
  }
`;
const StyledName = styled.div`
  font-size: 11px;
  color: #999999;
`;
const StyledComment = styled.div`
  position: relative;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
  background: #f1f1f1;
  border-radius: 5px;
`;
const StyledCommentValue = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  width: 100%;
  height: 100%;
  background: transparent;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  outline: none;
`;
const StyledCommentInput = styled.input`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  width: 100%;
  height: 100%;
  background: transparent;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  outline: none;
`;
const StyledChangeCommentButton = styled.div`
  position: relative;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: all .3s;
  cursor: pointer;
  &:hover {
    background: #999999;
  }
`;
const StyledOrderCounter = styled.div`
  margin-top: 20px;
  color: #999999;
`;
const StyledOrderCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: calc(100vh - 270px);
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
  }
`;