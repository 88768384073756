import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestKitchens } from '../../../../../redux/reducers/utils/kitchenReducers/kitchenReducer';
import welcomePackImage from '../../../../../assets/img/packs/welcomePack.png';
import NewKitchenCreateComponent from './NewKitchenCreateComponent';
import { sendRequestAccessGetComponentsForUser } from '../../../../../redux/reducers/utils/servicesReducer';
import { Text, Title } from '../../../../../core/styles';
import styled from 'styled-components';
import { CloseIcon, PlusIcon } from '../../../../../components/Icons';
import NewsComponent from './NewsComponent';

const faqData = [
  {
    question: 'Как заполнить меню?',
    answer: `Заполнение меню — это искусство, требующее внимания к деталям и понимания вкусовой палитры ваших гостей.
             Начните с выбора ключевых категорий: закуски, основные блюда, десерты и напитки.
             Каждая категория должна отражать концепцию вашего заведения, будь то уютное кафе или элегантный ресторан.`,
  },
  {
    question: 'Как подготовить фотографии блюд?',
    answer: `Тщательно отбирайте блюда для фотосессии, подбирайте освещение и задний фон.`,
  },
  {
    question: 'Какую одноразовую посуду использовать?',
    answer: 'Используйте биоразлагаемую посуду для уменьшения вреда окружающей среде.',
  },
  {
    question: 'Как быть, если у нас каждый день разное меню?',
    answer: 'Регулярно обновляйте меню на сайте и в социальных сетях, чтобы клиенты были в курсе актуального меню.',
  },
  {
    question: 'Как происходит выплата и какая комиссия сервиса?',
    answer: 'Выплата происходит на банковский счет, комиссия составляет 10%.',
  },
];

function WelcomeComponent() {
  const { userKitchenCreateRequestStatus } = useSelector((store) => store.kitchen);
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };


  useEffect(() => {
    if (userKitchenCreateRequestStatus === 'resolved') {
      dispatch(sendRequestAccessGetComponentsForUser({ token: localStorage.getItem('token') }));
      dispatch(sendRequestKitchens({ token: localStorage.getItem('token'), offset: 0, limit: 10000 }));
    }
  }, [userKitchenCreateRequestStatus]);

  return (
    <Wrapper>
      <Header>
      <MainCol>
        <WelcomeTitle>
          <div>
            <StyledTitle>Добро пожаловать<br/>в проект “Сытый”</StyledTitle>
            <StyledText style={{marginTop: '32px'}}>Добавьте свою кухню и принимайте новые заказы!</StyledText>
            <StyledText style={{marginTop: '18px'}}>Курьеры Сытого доставят их в любую точку города</StyledText>
          </div>
          <div>
            <img src={welcomePackImage} alt=''/>
          </div>
        </WelcomeTitle>
        <Steps>
          <Step>
            <StepNumber active>1</StepNumber>
            <StepContent>
              <NewKitchenCreateComponent green={true}/>
            </StepContent>
          </Step>
          <Step>
            <StepNumber>2</StepNumber>
            <StepContent>Заключить договор и заполнить реквизиты</StepContent>
          </Step>
          <Step>
            <StepNumber>3</StepNumber>
            <StepContent>Заполнить список товаров</StepContent>
          </Step>
          <Step>
            <StepNumber>4</StepNumber>
            <StepContent>Закупить посуду и пакеты</StepContent>
          </Step>
          <Step>
            <StepNumber>5</StepNumber>
            <StepContent>Провести тестовый запуск совместно с представителем Сытого</StepContent>
          </Step>
          <Step>
            <StepNumber>6</StepNumber>
            <StepContent>Супер! Получайте заказы от новых клиентов!</StepContent>
          </Step>
        </Steps>
      </MainCol>
      <SecondCol>
        <NewsComponent/>
      </SecondCol>
      
      </Header>

      <FAQWrapper>
        <h3>Часто задаваемые вопросы</h3>
        {faqData.map((faq, index) => (
          <FAQItem key={index}>
            <FAQQuestion isActive={activeIndex === index} onClick={() => toggleFAQ(index)}>
              <span>{faq.question}</span>
              <PlusIcon/>
            </FAQQuestion>
            <FAQAnswer isActive={activeIndex === index}>{faq.answer}</FAQAnswer>
          </FAQItem>
        ))}
      </FAQWrapper>
    </Wrapper>
  );
}

export default WelcomeComponent;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`
const Header = styled.div`
  display: flex;
  gap: 24px;
`
const MainCol = styled.div`
  flex: 3 1;
  display: flex;
  flex-direction: column;
`
const StyledTitle = styled(Title)`
  font-size: 44px;
  font-weight: 700;
`
const StyledText = styled(Text)`
  font-size: 24px;
`
const SecondCol = styled.div`
  flex: 0 0 330px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 0;
`
const WelcomeTitle = styled.div`
  display: flex;
  gap: 25px;
  flex-wrap: wrap-reverse;
  div {
    flex: 1;
    min-width: 330px;
  }
  img {
    width: 200px;
    height: 200px;
    object-fit: contain;
  }
`
const Steps = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const Step = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: #858080;
`
const StepNumber = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-right: 18px;
  min-width: 42px;
  height: 60px;
  background: ${(props) => props.active ? '#49BC9A' : '#EAEAEA'};
  border-radius: 4px;
  color: ${(props) => props.active ? '#ffffff' : '#858080'};
  &:after {
    content: "";
    position: absolute;
    width: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
  }
  &:after {
    left: 98%;
    width: 0;
    border-left: 18px solid  ${(props) => props.active ? '#49BC9A' : '#EAEAEA'};
  }
`
const StepContent = styled.div`

`
const FAQWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 200px;
  padding: 40px 60px;
  background: #ffffff;
  border-radius: 32px;
  h3 {
    font-size: 24px;
  }
`
const FAQItem = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
  &:last-child {
    border-bottom: none;
  }
`
const FAQQuestion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;

  padding: 16px 0;
  transition: all 0.3s ease;
  
  svg {
    transition: transform 0.3s ease;
    transform: ${(props) => (props.isActive ? 'rotate(135deg)' : 'rotate(0deg)')};
  }
  
  &:hover {
    color: #EA394B;
  }
`;

const FAQAnswer = styled.div`
  max-height: ${(props) => (props.isActive ? '1000px' : '0')};
  overflow: hidden;
  transition: max-height 0.4s ease, opacity 0.4s ease;
  opacity: ${(props) => (props.isActive ? '1' : '0')};
  padding: 0 32px;
  p {
    margin: 0;
    padding: 0;
    color: #181A1B;
    font-size: 16px;
    line-height: 24px;
  }
`;
