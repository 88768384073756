import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";
import apiInstance from '../../../../api';
import { selectCommonState, setServerTime } from '../commonReducer';

const initialState = {
  userInfoGetRequestStatus: null,
  userInfoUpdateRequestStatus: null,

  userName: null,
  userTimezone: 4,
};

export const getUserInfo = createAsyncThunk(
  'settingsProfile/getUserInfo',
  async (data, { getState, rejectWithValue }) => {
    const api = '/settings/user/get?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data.user;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const updateUserInfo = createAsyncThunk(
  'settingsProfile/updateUserInfo',
  async (data, { getState, rejectWithValue }) => {
    const api = '/settings/user/update?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

const kitchenDashboardSlice = createSlice({
  name: 'settingsProfile',
  initialState,
  extraReducers: (builder) => {
    builder
      // getUserInfo
      .addCase(getUserInfo.pending, (state) => { state.userInfoGetRequestStatus = 'pending'; })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        const data = action.payload
        state.userInfoGetRequestStatus = 'resolved';
        state.userName = data.name;
        state.userTimezone = data.timezone;
      })
      .addCase(getUserInfo.rejected, (state) => { state.userInfoGetRequestStatus = 'rejected'; })
      // updateUserInfo
      .addCase(updateUserInfo.pending, (state) => { state.userInfoUpdateRequestStatus = 'pending'; })
      .addCase(updateUserInfo.fulfilled, (state) => { state.userInfoUpdateRequestStatus = 'resolved'; })
      .addCase(updateUserInfo.rejected, (state) => { state.userInfoUpdateRequestStatus = 'rejected'; })
      //PURGE
      .addCase(PURGE, () => { return initialState; })
  },
  reducers: {},
});

export const {} = kitchenDashboardSlice.actions;

export default kitchenDashboardSlice.reducer;
