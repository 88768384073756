import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import styled from 'styled-components';
import Card from '../../../../../components/Card';

const StatisticsComponent = () => {
  const [timeRange, setTimeRange] = useState('weekly'); // По умолчанию "по неделям"
  const [ordersData, setOrdersData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [ratingData, setRatingData] = useState([]);

  // Фейковые данные для каждого временного диапазона
  const dailyData = {
    orders: [
      { date: '2024-10-01', value: 7 },
      { date: '2024-10-02', value: 12 },
      { date: '2024-10-03', value: 25 },
      { date: '2024-10-04', value: 11 },
      { date: '2024-10-05', value: 23 },
      { date: '2024-10-06', value: 35 },
      { date: '2024-10-07', value: 38 },
    ],
    sales: [
      { date: '2024-10-01', value: 5000 },
      { date: '2024-10-02', value: 15000 },
      { date: '2024-10-03', value: 20000 },
      { date: '2024-10-04', value: 10000 },
      { date: '2024-10-05', value: 25000 },
      { date: '2024-10-06', value: 30000 },
      { date: '2024-10-07', value: 35000 },
    ],
    rating: [
      { date: '2024-10-01', value: 4.0 },
      { date: '2024-10-02', value: 3.5 },
      { date: '2024-10-03', value: 4.2 },
      { date: '2024-10-04', value: 4.0 },
      { date: '2024-10-05', value: 3.8 },
      { date: '2024-10-06', value: 4.1 },
      { date: '2024-10-07', value: 3.9 },
    ],
  };

  const weeklyData = {
    orders: [
      { date: '2024-07-01', value: 20 },
      { date: '2024-07-08', value: 41 },
      { date: '2024-07-15', value: 30 },
      { date: '2024-07-22', value: 35 },
      { date: '2024-07-29', value: 50 },
    ],
    sales: [
      { date: '2024-07-01', value: 20000 },
      { date: '2024-07-08', value: 29587 },
      { date: '2024-07-15', value: 24000 },
      { date: '2024-07-22', value: 32000 },
      { date: '2024-07-29', value: 41000 },
    ],
    rating: [
      { date: '2024-07-01', value: 3.5 },
      { date: '2024-07-08', value: 2.0 },
      { date: '2024-07-15', value: 3.8 },
      { date: '2024-07-22', value: 4.1 },
      { date: '2024-07-29', value: 4.5 },
    ],
  };

  const monthlyData = {
    orders: [
      { date: '2024-01', value: 120 },
      { date: '2024-02', value: 90 },
      { date: '2024-03', value: 150 },
      { date: '2024-04', value: 200 },
      { date: '2024-05', value: 170 },
      { date: '2024-06', value: 190 },
      { date: '2024-07', value: 210 },
    ],
    sales: [
      { date: '2024-01', value: 130000 },
      { date: '2024-02', value: 95000 },
      { date: '2024-03', value: 130000 },
      { date: '2024-04', value: 180000 },
      { date: '2024-05', value: 175000 },
      { date: '2024-06', value: 192000 },
      { date: '2024-07', value: 200000 },
    ],
    rating: [
      { date: '2024-01', value: 4.0 },
      { date: '2024-02', value: 3.7 },
      { date: '2024-03', value: 4.2 },
      { date: '2024-04', value: 4.1 },
      { date: '2024-05', value: 4.3 },
      { date: '2024-06', value: 4.4 },
      { date: '2024-07', value: 4.5 },
    ],
  };

  useEffect(() => {
    // При изменении временного диапазона обновляем данные
    switch (timeRange) {
      case 'daily':
        setOrdersData(dailyData.orders);
        setSalesData(dailyData.sales);
        setRatingData(dailyData.rating);
        break;
      case 'weekly':
        setOrdersData(weeklyData.orders);
        setSalesData(weeklyData.sales);
        setRatingData(weeklyData.rating);
        break;
      case 'monthly':
        setOrdersData(monthlyData.orders);
        setSalesData(monthlyData.sales);
        setRatingData(monthlyData.rating);
        break;
      default:
        break;
    }
  }, [timeRange]);

  const getChartOption = () => ({
    title: {
      text: '',
    },
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: ordersData.map((data) => data.date),
    },
    yAxis: [
      {
        type: 'value',
        name: 'Заказы',
        min: 0,
        max: timeRange === 'monthly' ? 250 : 50,
        position: 'left',
      },
      {
        type: 'value',
        name: 'Сумма',
        min: 0,
        max: timeRange === 'monthly' ? 250000 : 50000,
        position: 'right',
        offset: 50,
      },
      {
        type: 'value',
        name: 'Рейтинг',
        min: 0,
        max: 5,
        position: 'right',
      },
    ],
    series: [
      {
        name: 'Заказы',
        type: 'line',
        data: ordersData.map((data) => data.value),
        yAxisIndex: 0,
        smooth: true,
        showSymbol: false,
        lineStyle: {
          color: '#49BC9A',
        },
      },
      {
        name: 'Сумма',
        type: 'line',
        data: salesData.map((data) => data.value),
        yAxisIndex: 1,
        smooth: true,
        showSymbol: false,
        lineStyle: {
          color: '#FF8800',
        },
      },
      {
        name: 'Рейтинг',
        type: 'line',
        data: ratingData.map((data) => data.value),
        yAxisIndex: 2,
        smooth: true,
        showSymbol: false,
        lineStyle: {
          color: '#C3E1FF',
        },
        areaStyle: {
          color: '#C3E1FF',
          opacity: 0.5,
        },
      },
    ],
  });

  const handleTimeRangeChange = (range) => {
    setTimeRange(range);
    // Обновление данных по выбору периода (если нужно)
  };

  return (
    <Card>
      <ButtonGroup>
        <Button
          active={timeRange === 'daily' && 'active'}
          onClick={() => handleTimeRangeChange('daily')}
        >
          по дням
        </Button>
        <Button
          active={timeRange === 'weekly' && 'active'}
          onClick={() => handleTimeRangeChange('weekly')}
        >
          по неделям
        </Button>
        <Button
          active={timeRange === 'monthly' && 'active'}
          onClick={() => handleTimeRangeChange('monthly')}
        >
          по месяцам
        </Button>
      </ButtonGroup>

      <ReactECharts option={getChartOption()} />
    </Card>
  );
};

export default StatisticsComponent;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const Button = styled.div`
  display: block;
  padding: 16px;
  background: ${(props) => props.active ? '#2A2A2A' : '#F9F9F9'};
  border: ${(props) => props.active ? 'solid 1px #2A2A2A' : 'solid 1px #EAEAEA'};
  border-radius: 100px;
  line-height: .75;
  color: ${(props) => props.active ? '#ffffff' : '#2A2A2A'};
  cursor: pointer;
  transition: all .2s;
  &:hover {
    scale: 1.025;
  }
`;