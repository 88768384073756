import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CardSection, Text, Title } from "../../../../../core/styles";
import styled, { keyframes } from "styled-components";
import Card from "../../../../../components/Card";
import { CutleryIcon, DishIcon, PlusIcon, ShevronRightIcon, SpinerIcon, UserDirIcon } from "../../../../../components/Icons";
import dishes from '../../../../../assets/img/dishes.png'
import { Link } from "react-router-dom";
import Flex from "../../../../../components/Flex";
import { Divider } from "antd";
import { getProductsStatistics } from "../../../../../redux/reducers/utils/kitchenReducers/kitchenDashboardReducer";

const ListProductCard = React.forwardRef(({ highlighted }, ref) => {
    const { selectKitchen } = useSelector((store) => store.kitchen);
    const { productsStatisticsGetRequestStatus, productsStatistics } = useSelector((store) => store.kitchenDashboard);
    const dispatch = useDispatch();

    useEffect(() => {
        if(selectKitchen) {
            dispatch(getProductsStatistics({token: localStorage.getItem('token'), kitchenId: selectKitchen.id}));
          }
    }, [selectKitchen]);

    return(
        <Wrapper ref={ref}>
            <StyledLink to={'/kitchen/assortment'}>
                <Flex column flex='1'>
                    <Title style={{marginBottom: '6px'}} level={2} weight='400'>Мои товары</Title>
                    <Text secondary size='16px' align='left'>Справочник всей продукции кухни</Text>
                </Flex>
                <div><ShevronRightIcon width='32px' height='32px'/></div>
            </StyledLink>
            <StyledCard  className={highlighted ? 'highlighted' : ''}>
                {productsStatisticsGetRequestStatus === 'pending' ?
                    <StyledCardSection>
                        <SpinerIcon/>
                        <Text size='16px' color='#858080' style={{marginTop: '12px'}}>Загружаем продукты</Text>
                        <Text size='14px' color='#B2B2B2' style={{marginTop: '4px'}}>Пожалуйста подождите...</Text>
                    </StyledCardSection>
                :
                    <CardSection>
                        <Flex style={{marginBottom: '20px'}} justify='space-between' align='baseline'>
                            <Title level={3}>Всего товаров</Title>
                            {productsStatistics && <ProductCount>{productsStatistics.countProducts}</ProductCount>}
                        </Flex>

                        {productsStatistics && productsStatistics.countProducts > 0 && 
                            <>
                                {productsStatistics.noPhoto > 0 && <ListProductProgress title={'Без фото'} progress={productsStatistics.noPhoto} maxProgress={productsStatistics.countProducts}/>}
                                {productsStatistics.noKBZHU > 0 && <ListProductProgress title={'Без КБЖУ'} progress={productsStatistics.noKBZHU} maxProgress={productsStatistics.countProducts}/>}

                                <Flex style={{marginTop: '20px'}} justify='space-between' align='center' gap='16px'>
                                    <Text size='18px'>{productsStatistics.countTableware === 0 ? 'Нет приборов' : 'Приборов'}</Text>
                                    <Text size='18px' style={{display: 'flex', gap: '12px'}}><CutleryIcon/> <span style={{color: productsStatistics.countTableware === 0 && 'red'}}>{productsStatistics.countTableware}</span></Text>
                                </Flex>

                                <Divider/>

                                {productsStatistics.onModeration > 0 && 
                                    <Flex justify='space-between' align='center' gap='16px'>
                                        <Text size='24px' secondary>На модерации</Text>
                                        <Text size='24px' secondary style={{display: 'flex', gap: '8px', alignItems: 'center'}}><UserDirIcon/> {productsStatistics.onModeration}</Text>
                                    </Flex>
                                }
                            </>
                        }

                        <StyledButton to={'/kitchen/assortment'}>
                            {productsStatistics && productsStatistics.countProducts === 0 && <Text size='16px' color='#B2B2B2' align='center'>Список пуст, без товаров невозможно опубликовать меню</Text>}
                            <DishIcon width='60px' height='60px' color='#E0E0E0'/>
                            <Flex align='center' gap='5px'>
                                <Text size='16px' color='#858080'>Добавить товары</Text>
                                <PlusIcon color='#858080'/>
                            </Flex>
                        </StyledButton>
                    </CardSection>
                }
            </StyledCard>
        </Wrapper>
    )
})

export default ListProductCard;

const ListProductProgress = ({ title, progress, maxProgress }) => {
    const danger = (progress / maxProgress) * 100 > 80 ? true : false;
    return (
        <Flex column style={{marginTop: '24px'}}>
            <Flex justify='space-between' align='center' gap='16px'>
                <Title level={4}>{title}</Title>
                <ProgressInfo>
                    {progress} / {maxProgress}
                </ProgressInfo>
            </Flex>
            <ProgressBarWrapper>
                <ProgressBar danger={danger} progress={(progress / maxProgress) * 100} />
            </ProgressBarWrapper>
        </Flex>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`
const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 16px;
    width: fit-content;
    height: 64px;
    color: #000000;
    transition: all .3s;
    &:hover {
        color: var(--color-accent);
    }
`
const highlight = keyframes`
    0% {
        box-shadow: none;
    }
    50% {
        box-shadow: 0 0 25px 5px var(--color-accent);
    }
    100% {
        box-shadow: none;
    }
`
const StyledCard = styled(Card)`
    &.highlighted {
        animation: ${highlight} 2s;
    }
`
const ProductCount = styled.div`
    font-size: 24px;
    font-weight: 600;
`
const StyledButton = styled(Link)`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 16px;
    border: dashed 1px var(--color-font);
    border-radius: 24px;
    transition: all .2s;
    cursor: pointer;

    &:hover {
        scale: 1.025;
    }
`
const ProgressInfo = styled.div`
    font-size: 18px;
    color: #888;
`
const ProgressBarWrapper = styled.div`
    position: relative;
    height: 5px;
    background-color: #e5e5e5;
    border-radius: 5px;
    margin-top: 20px;
`;
const ProgressBar = styled.div`
    height: 100%;
    width: ${(props) => props.progress}%;
    background-color: ${(props) => props.danger ? 'var(--color-accent)': 'var(--color-font)'};
    border-radius: 5px;
`
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const StyledCardSection = styled(CardSection)`
    justify-content: center;
    align-items: center;
    svg {
        animation: ${rotate} 1s linear infinite;
    }
`