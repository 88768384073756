import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCurrentDeliveryServiceRace,
  sendRequestDeliveryServiceRacesUpdate,
  sendRequestDeliveryServiceRacesDelete,
} from '../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Flex, Modal, Select, Table, Form, TimePicker, Popconfirm, Input, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import RaceCreateModalComponent from './RaceCreateModalComponent';
import PrintTable from '../../../../../../../components/PrintTable';
import SuperTimeComponent from '../../../../../../../components/SuperTimeComponent';

const { Search } = Input;

const RacesTableComponent = () => {
  const navigate = useNavigate();
  const { deliveryServiceRaces } = useSelector((store) => store.deliveryServices);
  const { cities } = useSelector((store) => store.settingsCatalogs);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const modifyCurrentRaces = deliveryServiceRaces?.map(item => {  
    const city = cities?.find(city => city.id === item.cityId);
    const cityName = city ? city?.name : '';
  
    return { ...item, key: item.id, cityName: cityName };
  });

  const filteredCurrentRaces = modifyCurrentRaces?.filter((item) =>
    item.cityId?.toString().toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleCellClick = (record) => {
    dispatch(setCurrentDeliveryServiceRace(record));
    navigate(`races/${record.id}`);
  };

  const columns = [
    {
      title: 'Город',
      dataIndex: 'cityName',
      key: 'cityName',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Начало\u00A0доставки',
      key: 'timeStart',
      render: (_, record) => (
        <SuperTimeComponent serverTime={record.timeStart} cityTimezone={record.timezoneCity}/>
      ),
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Завершение\u00A0доставки',
      key: 'timeEnd',
      render: (_, record) => (
        <SuperTimeComponent serverTime={record.timeEnd} cityTimezone={record.timezoneCity}/>
      ),
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Продолжительность\u00A0доставки',
      key: 'timeDelivery',
      render: (_, record) => (
        <div>{record.timeDelivery ? `${record.timeDelivery}, мин` : '-'}</div>
      ),
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Интервал',
      key: 'CourierCutoff',
      render: (_, record) => (
        <Flex align='center' gap={'15px'}><SuperTimeComponent serverTime={record.initialCourierCutoff} cityTimezone={record.timezoneCity}/><span>-</span><SuperTimeComponent serverTime={record.finalCourierCutoff} cityTimezone={record.timezoneCity}/></Flex>
      ),
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: '',
      key: 'actions',
      render: (_, record) => (
        <AccessAction
          deliveryServiceRace={record}
        />
      ),
    },
  ];

  return (
    <>
      <Flex gap={20} wrap='wrap' style={{ margin: '20px 0' }}>
        <Search
          placeholder="Поиск по названию или описанию"
          onChange={(e) => handleSearch(e.target.value)}
          style={{ maxWidth: '500px' }}
        />
        <RaceCreateModalComponent/>
      </Flex>
      <PrintTable>
        <Table
          dataSource={filteredCurrentRaces}
          columns={columns}
          size="small"
          pagination={{
            defaultPageSize: 50,
          }}
        />
      </PrintTable>
    </>
  );
};

export default RacesTableComponent;

const AccessAction = ({deliveryServiceRace}) => {
  const { cities } = useSelector((store) => store.settingsCatalogs);
  const { currentDeliveryService } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();
  const [ isCreate, setIsCreate] = useState(false);
  const [form] = Form.useForm();
  const [ timezone, setTimezone] = useState(deliveryServiceRace.timezoneCity);

  dayjs.extend(customParseFormat);
  const timeFormat = 'HH:mm';

  const handleOpen = () => {
    setIsCreate(true);
  };

  const handleCancel = () => {
    setIsCreate(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      raceId: deliveryServiceRace.id,
      cityId: values.city,
      deliveryServiceId: currentDeliveryService.id,
      timeStart: moment(new Date(values.timeStart)).subtract(timezone, 'hours').format("HH:mm"),
      timeEnd: moment(new Date(values.timeEnd)).subtract(timezone, 'hours').format("HH:mm"),
      initialCourierCutoff: values.initialCourierCutoff ? moment(new Date(values.initialCourierCutoff)).subtract(timezone, 'hours').format("HH:mm") : "00:00",
      finalCourierCutoff: values.finalCourierCutoff ? moment(new Date(values.finalCourierCutoff)).subtract(timezone, 'hours').format("HH:mm") : "00:00",
    }

    dispatch(sendRequestDeliveryServiceRacesUpdate(requestData));
    setIsCreate(false);
    form.resetFields();
  }

  const cityOptions = cities?.map((item) => ({
    ...item,
    value: item?.id,
    label: item?.name,
    timezone: item.timezone,
  }));

  const handleChange = (value, option) => {
    setTimezone(option.timezone);
  };

  const handleDelete = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      raceId: deliveryServiceRace.id,
    }

    dispatch(sendRequestDeliveryServiceRacesDelete(requestData));
  };

  return (
    <Action>
      <Button
        icon={<EditOutlined />}
        onClick={handleOpen}
      >
      </Button>
      <Modal
        title="Редактирование службы доставки"
        open={isCreate}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          style={{ width: '100%' }}
          form={form}
          name='deliveryService'
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={{
            city: {value: deliveryServiceRace.cityId, label: cities?.find(city => city.id.toString() === deliveryServiceRace.cityId.toString())?.name}.value,
            timeStart: dayjs(deliveryServiceRace.timeStart, timeFormat).add(deliveryServiceRace.timezoneCity, 'hour'),
            timeEnd: dayjs(deliveryServiceRace.timeEnd, timeFormat).add(deliveryServiceRace.timezoneCity, 'hour'),
            initialCourierCutoff: dayjs(deliveryServiceRace.initialCourierCutoff, timeFormat).add(deliveryServiceRace.timezoneCity, 'hour'),
            finalCourierCutoff: dayjs(deliveryServiceRace.finalCourierCutoff, timeFormat).add(deliveryServiceRace.timezoneCity, 'hour'),
          }}
        >
          <Form.Item label='Город' name='city' rules={[{ required: true, message: 'Выберите город' }]}>
            <Select
              showSearch
              placeholder="Выберите город"
              optionFilterProp="children"
              options={cityOptions}
              filterOption={(input, option) =>
                (option?.name?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionRender={(option) => (
                <Flex>{option.data.label} (GMT{option.data.timezone >= 0 ? `+${option.data.timezone}` : option.data.timezone})</Flex>
              )}
              onChange={handleChange}
            />
          </Form.Item>
          <Flex justify='space-between' gap='large'>
            <Form.Item style={{flex: '1' }} label="Начало доставки" name="timeStart" rules={[{ required: true, message: 'Укажите начало доставки' }]}>
              <TimePicker style={{width: '100%' }} format="HH:mm"/>
            </Form.Item>
            <Form.Item style={{flex: '1' }} label="Завершение доставки" name="timeEnd" rules={[{ required: true, message: 'Укажите завершение доставки' }]}>
              <TimePicker style={{width: '100%' }} format="HH:mm"/>
            </Form.Item>
          </Flex>
          <Flex justify='space-between' gap='large'>
            <Form.Item style={{flex: '1' }} label="Время начала работы курьера" name="initialCourierCutoff">
              <TimePicker style={{width: '100%' }} format="HH:mm"/>
            </Form.Item>
            <Form.Item style={{flex: '1' }} label="Время окончания работы курьера" name="finalCourierCutoff">
              <TimePicker style={{width: '100%' }} format="HH:mm"/>
            </Form.Item>
          </Flex>
          <Button type='primary' htmlType='submit' style={{ display: 'flex', marginLeft: 'auto' }}>Сохранить</Button>
        </Form>
      </Modal>
      <Popconfirm
        title="Удаление службы доставки!"
        description={`Вы действительно хотите удалить рейс?`}
        okText="Да"
        cancelText="Нет"
        placement="left"
        onConfirm={handleDelete}
      >
        <Button danger={true}>Удалить</Button>
      </Popconfirm>
    </Action>
  );
};
const Action = styled.div`
  position: relative;
  z-index: 600;
  display: flex;
  gap: 5px;
  align-items: center;
`