import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestModifierGroupsUpdate,
  sendRequestModifierGroupsDelete,
} from '../../../../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import { Flex, Form, InputNumber, Popconfirm, Input, Button } from 'antd';

const EditableForm = ({modifier}) => {
  const navigate = useNavigate();
  const {
    currentModifierGroupId,
  } = useSelector((store) => store.kitchenMenu);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (modifier) {
      form.setFieldsValue({
        name: modifier.name,
        public_name: modifier.public_name,
        min: modifier.min,
        max: modifier.max,
      });
    }
  }, [modifier, form]);

  const handleSubmit = () => {
    form.validateFields()
    .then((values) => {
      const requestData = {
        token: localStorage.getItem('token'),
        modifierGroupId: currentModifierGroupId,
        name: values.name,
        public_name: values.public_name,
        min: values.min,
        max: values.max,
        isEnabled: true,
      };

      dispatch(sendRequestModifierGroupsUpdate(requestData));
      form.resetFields();
    })
    .catch((errorInfo) => {
      console.error('Ошибка при валидации формы:', errorInfo);
    });
  }

  const handleDelete = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      modifierGroupId: modifier.id,
    }

    dispatch(sendRequestModifierGroupsDelete(requestData));
    navigate(`/kitchens/menumodifiers`);
    // setCurrentDeliveryService(null);
  };

  return (
    <Flex vertical>
      <Form
        style={{ width: '100%' }}
        form={form}
        name='modifiersGroup'
        layout='vertical'
        initialValues={{
          name: modifier.name,
          public_name: modifier.public_name,
          min: modifier.min,
          max: modifier.max,
        }}
      >
        <Form.Item label='Название группы модификаторов' name='name' rules={[{ required: true, message: 'Обязательное поле' }]}>
          <Input placeholder='Название группы модификаторов'/>
        </Form.Item>
        <Form.Item label='Публичное название группы модификаторов' name='public_name' rules={[{ required: true, message: 'Обязательное поле' }]}>
          <Input placeholder='Публичное название группы модификаторов'/>
        </Form.Item>
        <Flex>
          <Form.Item label='Минимальное кол-во модификаторов' name='min' rules={[{ required: true, message: 'Обязательное поле' }]}>
            <InputNumber placeholder='1'/>
          </Form.Item>
          <Form.Item label='Максимальное кол-во модификаторов' name='max' rules={[{ required: true, message: 'Обязательное поле' }]}>
            <InputNumber placeholder='3'/>
          </Form.Item>
        </Flex>
      </Form>
      <Flex gap='large'>
        <Button type='primary' htmlType='submit' onClick={handleSubmit} style={{ display: 'flex', marginLeft: 'auto' }}>Сохранить</Button>
        <Popconfirm
          title="Удаление группы модификаторов!"
          description={`Вы действительно хотите удалить группу модификаторов?`}
          okText="Да"
          cancelText="Нет"
          placement="left"
          onConfirm={handleDelete}
        >
          <Button danger={true}>Удалить</Button>
        </Popconfirm>
      </Flex>
    </Flex>
  );
};

export default EditableForm;
