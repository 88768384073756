import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { persistor } from '../redux/configureStore';
import { setCurrentModule, setCurrentRoute } from '../redux/reducers/utils/navReducer';
import { setGlobalLoader } from '../redux/reducers/utils/commonReducer';
import { sendRequestUserProfile, sendRequestGiveRoleToNewUser } from '../redux/reducers/utils/authReducer';
import { sendRequestAccessGetComponentsForUser } from '../redux/reducers/utils/servicesReducer';
import { setApiUrl } from '../redux/reducers/utils/commonReducer.js';
import Router from './Router';
import { GlobalStyle } from './styles';
import { Button, Input, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import GlobalLoader from '../components/GlobalLoader.jsx';
import ScrollToTop from '../components/ScrollToTop.jsx';
import useDeviceInfo from '../hooks/useDeviceInfo.js';

const App = () => {
  useDeviceInfo();
  const { isAuth, isNewUser, giveRoleToNewUserRequestStatus, userProfileRequestStatus } = useSelector((store) => store.auth);
  const { apiUrl } = useSelector((store) => store.common);
  const { accessGetComponentsForUserRequestStatus } = useSelector((store) => store.services);
  const { globalLoader } = useSelector((store) => store.common);
  const { currentRoute } = useSelector((store) => store.nav);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const isDev = searchParams.get('dev') === 'true';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serverAddress, setServerAddress] = useState('');

  const currentAppVersion = '1.00.09';

  const storedAppVersion = localStorage.getItem('appVersion');
  if (storedAppVersion !== currentAppVersion) {
    localStorage.clear();
    localStorage.setItem('appVersion', currentAppVersion);
    dispatch(setCurrentRoute('/auth'));
    persistor.purge();
  }

  useEffect(() => {
    console.log(location.pathname, location.pathname.substring(1))
    if (location.pathname === '/') {
      dispatch(setCurrentRoute('/kitchen/dashboard'));
      dispatch(setCurrentModule('kitchen'));
      navigate('/kitchen/dashboard');
    }
    if (location.pathname !== currentRoute) {
      dispatch(setCurrentRoute(location.pathname.substring(1)));
      dispatch(setCurrentModule(location.pathname.substring(1).split('/')[0]));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      giveRoleToNewUserRequestStatus === 'pending' ||
      userProfileRequestStatus === 'pending' ||
      accessGetComponentsForUserRequestStatus === 'pending'
    ) {
      dispatch(setGlobalLoader(true));
    } else {
      dispatch(setGlobalLoader(false));
    }
  }, [
    giveRoleToNewUserRequestStatus,
    userProfileRequestStatus,
    accessGetComponentsForUserRequestStatus,
  ]);

  useEffect(() => {
    if (isAuth) {
      if (isNewUser) {
        dispatch(sendRequestGiveRoleToNewUser({ token: localStorage.getItem('token') }));
      } else {
        dispatch(sendRequestGiveRoleToNewUser({ token: localStorage.getItem('token') }));
        dispatch(sendRequestUserProfile({ token: localStorage.getItem('token') }));
        dispatch(sendRequestAccessGetComponentsForUser({ token: localStorage.getItem('token') }));
      }
    } else {
      dispatch(setCurrentRoute('/auth'));
      localStorage.removeItem('token');
      localStorage.removeItem('session');
      persistor.purge();
    }
  }, [isAuth, isNewUser]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.onerror = function(message, source, lineno, colno, error) {
        alert("Произошла критическая ошибка: " + message);
        console.log(message);

        const isConfirmed = window.confirm("Перезапустить приложение?");
        if (isConfirmed) {
          localStorage.clear();
          window.location.reload();
        }
      };
    }
  }, []);

  const handleSwitchToSerjURL = () => {
    dispatch(setApiUrl('http://api.menu4me.ru/api/v1/admin'));
  };

  const handleSwitchToOfficeURL = () => {
    dispatch(setApiUrl('https://dolina.menuforme.online/api/v1/admin'));
  };

  const handleSwitchToDevURL = () => {
    dispatch(setApiUrl('https://bottest2.menu4me.ru/api/v1/admin'));
  };

  const handleSwitchToProdURL = () => {
    dispatch(setApiUrl('https://api-test.menuforme.online/api/v1/admin'));
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setServerAddress('');
  };

  const handleInputChange = (e) => {
    setServerAddress(e.target.value);
  };

  const handleOk = () => {
    dispatch(setApiUrl(serverAddress));
    setIsModalOpen(false);
    setServerAddress('');
  };

  return (
    <>
      <GlobalStyle/>
      {isAuth && globalLoader && location.pathname !== '/services/roleaccess' && <GlobalLoader/>}
      
      <Router/>
      
      <ScrollToTop/>

      {isDev &&
        <div style={{ position: 'fixed', bottom: 20, left: 10, display: 'flex', flexDirection: 'column' }}>
          <Button onClick={showModal}><PlusOutlined /></Button>
          <Button type={apiUrl === 'http://api.menu4me.ru/api/v1/admin' ? 'primary' : 'default'} onClick={handleSwitchToSerjURL}>Serj</Button>
          <Button type={apiUrl === 'https://dolina.menuforme.online/api/v1/admin' ? 'primary' : 'default'} onClick={handleSwitchToOfficeURL}>Office</Button>
          <Button type={apiUrl === 'https://bottest2.menu4me.ru/api/v1/admin' ? 'primary' : 'default'} onClick={handleSwitchToDevURL}>Dev</Button>
          <Button type={apiUrl === 'https://api-test.menuforme.online/api/v1/admin' ? 'primary' : 'default'} onClick={handleSwitchToProdURL}>Prod</Button>
          <Modal
            title="Введите адрес сервера"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="ОК"
            cancelText="Отмена"
          >
            <Input 
              placeholder="https://server.ru/"
              value={serverAddress}
              onChange={handleInputChange}
            />
          </Modal>
        </div>
      }
    </>
  );
}

export default App;
