import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { setIsShowOrganizationDrawer, setCurrentOrganization } from '../../../../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';
import { Flex, Drawer, Popconfirm, Button, Form, Input } from 'antd';

const OrganizationDrawerComponent = ({ isShowOrganizationDrawer, currentOrganization, onCreateOrganization, onUpdateOrganization, onDeleteOrganization }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = useState(false);

  const handleCreateOrganization = () => {
    form.validateFields()
      .then((values) => {
        onCreateOrganization(values);
        form.resetFields();
        handleCloseDrawer();
      })
      .catch((errorInfo) => {
        console.error('Ошибка при валидации формы:', errorInfo);
      });
  };

  const handleUpdateOrganization = () => {
    form.validateFields()
      .then((values) => {
        onUpdateOrganization({ ...values, organizationId: currentOrganization.id });
        handleCloseDrawer();
      })
      .catch((errorInfo) => {
        console.error('Ошибка при валидации формы:', errorInfo);
      });
  };

  const handleDeleteOrganization = () => {
    onDeleteOrganization(currentOrganization.id);
    handleCloseDrawer();
  }

  const handleCloseDrawer = () => {
    form.resetFields();
    setIsFormChanged(false);
    dispatch(setIsShowOrganizationDrawer(false));
    dispatch(setCurrentOrganization(null));
  };

  const handleFormChange = () => {
    setIsFormChanged(true);
  };

  useEffect(() => {
    if (currentOrganization) {
      setIsFormChanged(false);
      form.setFieldsValue(currentOrganization);
    }
  }, [currentOrganization, form]);

  return (
    <Drawer
      title={
        <>
          {currentOrganization ?
            <Flex gap={20}>
              { isFormChanged &&
                <>
                  <Button type="primary" onClick={handleUpdateOrganization}>Сохранить</Button>
                  <Button onClick={handleCloseDrawer}>Отменить</Button>
                </>
              }
              <Popconfirm
                title="Удаление организации!"
                description={`Вы действительно хотите удалить организацию ${currentOrganization.urName}`}
                okText="Да"
                cancelText="Нет"
                placement="left"
                onConfirm={handleDeleteOrganization}
              >
                <Button danger={true}>Удалить организацию</Button>
              </Popconfirm>
            </Flex>
            :
            <Button type="primary" onClick={handleCreateOrganization}>Создать</Button>
          }
        </>
      }
      placement="right"
      onClose={handleCloseDrawer}
      open={isShowOrganizationDrawer}
      width='40%'
      mask={false}
    >
      <Form
        form={form}
        onValuesChange={handleFormChange}
      >
        <Form.Item label="Название" name="name" rules={[{ required: true, message: 'Обязательное поле!' }]}><Input /></Form.Item>
        <Form.Item label="Описание" name="desc" rules={[{ required: true, message: 'Обязательное поле!' }]}><Input /></Form.Item>
        <Form.Item label="Юр. имя" name="urName" rules={[{ required: true, message: 'Обязательное поле!' }]}><Input /></Form.Item>
        <Form.Item label="ИНН" name="inn" rules={[{ required: true, message: 'Обязательное поле!' }, { pattern: /^\d{10,12}$/, message: 'Введите от 10 до 12 цифр' }]}><Input maxLength={12}/></Form.Item>
        <Form.Item label="КПП" name="kpp" rules={[{ required: true, message: 'Обязательное поле!' }, { pattern: /^\d{9}$/, message: 'Введите 9 цифр КПП' }]}><Input maxLength={9}/></Form.Item>
        <Form.Item label="Юр. адрес" name="urAddres" rules={[{ required: true, message: 'Обязательное поле!' }]}><Input /></Form.Item>
        <Form.Item label="Факт. адрес" name="factAddres" rules={[{ required: true, message: 'Обязательное поле!' }]}><Input /></Form.Item>
        <Form.Item label="ФИО" name="fio" rules={[{ required: true, message: 'Обязательное поле!' }]}><Input /></Form.Item>
        <Form.Item label="Почта" name="email" rules={[{ required: true, message: 'Обязательное поле!' }]}><Input /></Form.Item>
        <Form.Item label="Тел" name="phone" rules={[{ required: true, message: 'Обязательное поле!' }]}><Input /></Form.Item>
        <Form.Item label="Банк" name="bank_name" rules={[{ required: true, message: 'Обязательное поле!' }]}><Input /></Form.Item>
        <Form.Item label="р/с" name="bank_accAt" rules={[{ required: true, message: 'Обязательное поле!' }, { pattern: /^\d{20}$/, message: 'Введите 20 цифр р/с' }]}><Input maxLength={20}/></Form.Item>
        <Form.Item label="к/с" name="bank_corrAcc" rules={[{ required: true, message: 'Обязательное поле!' }, { pattern: /^\d{20}$/, message: 'Введите 20 цифр к/с' }]}><Input maxLength={20}/></Form.Item>
        <Form.Item label="БИК" name="bank_rcbic" rules={[{ required: true, message: 'Обязательное поле!' }, { pattern: /^\d{9}$/, message: 'Введите 9 цифр БИК' }]}><Input maxLength={9}/></Form.Item>
      </Form>
    </Drawer>
  );
}

export default OrganizationDrawerComponent;
