import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

const SuperTimeComponent = ({ serverTime, cityTimezone = 4, userTimezone = 4 }) => {
    const adjustedCityTime = moment(serverTime, "HH:mm").add(cityTimezone, 'hours').format("HH:mm");
    const adjustedUserTime = moment(serverTime, "HH:mm").add(userTimezone, 'hours').format("HH:mm");

    const formattedUserTimezone = userTimezone > 0 ? `+${userTimezone}` : userTimezone;

    return (
        <Wrapper>
            <CityTime>{adjustedCityTime}</CityTime>
            {cityTimezone !== userTimezone && (
                <UserTime>{adjustedUserTime} (UTC{formattedUserTimezone})</UserTime>
            )}
        </Wrapper>
    );
};

export default SuperTimeComponent;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const CityTime = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: #000000;
`;

const UserTime = styled.div`
    font-size: 12px;
    color: #b8b8b8;
`;
