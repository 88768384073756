import { takeLatest } from 'redux-saga/effects';
import {
  //Modifier Groups
  handleGetModifierGroups,
  handleCreateModifierGroups,
  handleUpdateModifierGroups,
  handleDeleteModifierGroups,

  //Modifier Groups Products
  handleGetModifierGroupsProducts,
  handleCreateModifierGroupsProduct,
  handleUpdateModifierGroupsProduct,
  handleDeleteModifierGroupsProduct,
} from './kitchenMenuHandler';
import {
  //Modifier Groups
  sendRequestModifierGroupsGet,
  sendRequestModifierGroupsCreate,
  sendRequestModifierGroupsUpdate,
  sendRequestModifierGroupsDelete,

  //Modifier Groups Products
  sendRequestModifierGroupsProductsGet,
  sendRequestModifierGroupsProductsCreate,
  sendRequestModifierGroupsProductUpdate,
  sendRequestModifierGroupsProductDelete,
} from '../../../../reducers/utils/kitchenReducers/kitchenMenuReducer';

export function* kitchenMenuWatcherSaga() {
  //Modifier Groups
  yield takeLatest(sendRequestModifierGroupsGet.type, handleGetModifierGroups);
  yield takeLatest(sendRequestModifierGroupsCreate.type, handleCreateModifierGroups);
  yield takeLatest(sendRequestModifierGroupsUpdate.type, handleUpdateModifierGroups);
  yield takeLatest(sendRequestModifierGroupsDelete.type, handleDeleteModifierGroups);

  //Modifier Groups Products
  yield takeLatest(sendRequestModifierGroupsProductsGet.type, handleGetModifierGroupsProducts);
  yield takeLatest(sendRequestModifierGroupsProductsCreate.type, handleCreateModifierGroupsProduct);
  yield takeLatest(sendRequestModifierGroupsProductUpdate.type, handleUpdateModifierGroupsProduct);
  yield takeLatest(sendRequestModifierGroupsProductDelete.type, handleDeleteModifierGroupsProduct);
}