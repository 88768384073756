import apiInstance from '../../../../api';
import {call, put, select} from 'redux-saga/effects';
import {
  //Access Roles
  sendRequestAccessRolesGetSuccess,
  sendRequestAccessRolesGetError,
  setAccessRoles,

  //Access Get Components For User
  sendRequestAccessGetComponentsForUserSuccess,
  sendRequestAccessGetComponentsForUserError,
  setAccessComponentsForUser,

  //Access Components
  sendRequestAccessComponentsGetSuccess,
  sendRequestAccessComponentsGetError,
  setAccessComponents,
  sendRequestAccessComponentsCreateSuccess,
  sendRequestAccessComponentsCreateError,
  sendRequestAccessComponentsUpdateSuccess,
  sendRequestAccessComponentsUpdateError,
  sendRequestAccessComponentsDeleteSuccess,
  sendRequestAccessComponentsDeleteError,

  //Access Role Components
  sendRequestAccessRoleComponentsGetSuccess,
  sendRequestAccessRoleComponentsGetError,
  setAccessRoleComponents,
  sendRequestAccessRoleComponentsCreateSuccess,
  sendRequestAccessRoleComponentsCreateError,
  sendRequestAccessRoleComponentsUpdateSuccess,
  sendRequestAccessRoleComponentsUpdateError,
  sendRequestAccessRoleComponentsDeleteSuccess,
  sendRequestAccessRoleComponentsDeleteError,

  //Access Public Methods
  sendRequestAccessPublicMethodsGetSuccess,
  sendRequestAccessPublicMethodsGetError,
  setAccessPublicMethods,
  sendRequestAccessPublicMethodsCreateSuccess,
  sendRequestAccessPublicMethodsCreateError,
  sendRequestAccessPublicMethodsUpdateSuccess,
  sendRequestAccessPublicMethodsUpdateError,
  sendRequestAccessPublicMethodsDeleteSuccess,
  sendRequestAccessPublicMethodsDeleteError,

  //Access Role Public Methods
  sendRequestAccessRolePublicMethodsGetSuccess,
  sendRequestAccessRolePublicMethodsGetError,
  setAccessRolePublicMethods,
  sendRequestAccessRolePublicMethodsCreateSuccess,
  sendRequestAccessRolePublicMethodsCreateError,
  sendRequestAccessRolePublicMethodsUpdateSuccess,
  sendRequestAccessRolePublicMethodsUpdateError,
  sendRequestAccessRolePublicMethodsDeleteSuccess,
  sendRequestAccessRolePublicMethodsDeleteError,

  //Access Role Users
  sendRequestAccessRoleUsersGetSuccess,
  sendRequestAccessRoleUsersGetError,
  setAccessRoleUsers,
  sendRequestAccessRoleUsersCreateSuccess,
  sendRequestAccessRoleUsersCreateError,
  sendRequestAccessRoleUsersUpdateSuccess,
  sendRequestAccessRoleUsersUpdateError,
  sendRequestAccessRoleUsersDeleteSuccess,
  sendRequestAccessRoleUsersDeleteError,

  //Entity
  sendRequestEntityGetSuccess,
  sendRequestEntityGetError,
  setEntity,
  sendRequestEntityCreateSuccess,
  sendRequestEntityCreateError,
  sendRequestEntityUpdateSuccess,
  sendRequestEntityUpdateError,
  sendRequestEntityDeleteSuccess,
  sendRequestEntityDeleteError,

  //Entity Keys
  sendRequestEntityKeysGetSuccess,
  sendRequestEntityKeysGetError,
  setEntityKeys,
  sendRequestEntityKeysCreateSuccess,
  sendRequestEntityKeysCreateError,
  sendRequestEntityKeysUpdateSuccess,
  sendRequestEntityKeysUpdateError,
  sendRequestEntityKeysDeleteSuccess,
  sendRequestEntityKeysDeleteError,

  //Entity Key Roles
  sendRequestEntityKeyRolesGetSuccess,
  sendRequestEntityKeyRolesGetError,
  setEntityKeyRoles,
  sendRequestEntityKeyRolesCreateSuccess,
  sendRequestEntityKeyRolesCreateError,
  sendRequestEntityKeyRolesUpdateSuccess,
  sendRequestEntityKeyRolesUpdateError,
  sendRequestEntityKeyRolesDeleteSuccess,
  sendRequestEntityKeyRolesDeleteError,
} from '../../../reducers/utils/servicesReducer';
import { selectCommonState } from '../../../reducers/utils/commonReducer';

//Access Roles
export function* handleGetAccessRoles(action) {
  const api = '/access/role/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessRolesGetSuccess());
      yield put(setAccessRoles(response.data.data.role))
    } else {
      yield put(sendRequestAccessRolesGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessRolesGetError());
  }
}

//Access Get Components For User
export function* handleGetComponentsForUser(action) {
  const api = '/access/getComponentsForUser?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessGetComponentsForUserSuccess());
      yield put(setAccessComponentsForUser(response.data.data.components))
    } else {
      yield put(sendRequestAccessGetComponentsForUserError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessGetComponentsForUserError());
  }
}

//Access Components
export function* handleGetAccessComponents(action) {
  const api = '/access/components/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessComponentsGetSuccess());
      yield put(setAccessComponents(response.data.data.components))
    } else {
      yield put(sendRequestAccessComponentsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessComponentsGetError());
  }
}

export function* handleCreateAccessComponents(action) {
  const api = '/access/components/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessComponentsCreateSuccess());
    } else {
      yield put(sendRequestAccessComponentsCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessComponentsCreateError());
  }
}

export function* handleUpdateAccessComponents(action) {
  const api = '/access/components/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessComponentsUpdateSuccess());
    } else {
      yield put(sendRequestAccessComponentsUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessComponentsUpdateError());
  }
}

export function* handleDeleteAccessComponents(action) {
  const api = '/access/components/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessComponentsDeleteSuccess());
    } else {
      yield put(sendRequestAccessComponentsDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessComponentsDeleteError());
  }
}

//Access Role Components
export function* handleGetAccessRoleComponents(action) {
  const api = '/access/roleComponent/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessRoleComponentsGetSuccess());
      yield put(setAccessRoleComponents(response.data.data))
    } else {
      yield put(sendRequestAccessRoleComponentsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessRoleComponentsGetError());
  }
}

export function* handleCreateAccessRoleComponents(action) {
  const api = '/access/roleComponent/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessRoleComponentsCreateSuccess());
    } else {
      yield put(sendRequestAccessRoleComponentsCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessRoleComponentsCreateError());
  }
}

export function* handleUpdateAccessRoleComponents(action) {
  const api = '/access/roleComponent/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessRoleComponentsUpdateSuccess());
    } else {
      yield put(sendRequestAccessRoleComponentsUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessRoleComponentsUpdateError());
  }
}

export function* handleDeleteAccessRoleComponents(action) {
  const api = '/access/roleComponent/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessRoleComponentsDeleteSuccess());
    } else {
      yield put(sendRequestAccessRoleComponentsDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessRoleComponentsDeleteError());
  }
}

//Access Public Methods
export function* handleGetAccessPublicMethods(action) {
  const api = '/access/publicMethod/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessPublicMethodsGetSuccess());
      yield put(setAccessPublicMethods(response.data.data.publicMethods))
    } else {
      yield put(sendRequestAccessPublicMethodsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessPublicMethodsGetError());
  }
}

export function* handleCreateAccessPublicMethods(action) {
  const api = '/access/publicMethod/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessPublicMethodsCreateSuccess());
    } else {
      yield put(sendRequestAccessPublicMethodsCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessPublicMethodsCreateError());
  }
}

export function* handleUpdateAccessPublicMethods(action) {
  const api = '/access/publicMethod/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessPublicMethodsUpdateSuccess());
    } else {
      yield put(sendRequestAccessPublicMethodsUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessPublicMethodsUpdateError());
  }
}

export function* handleDeleteAccessPublicMethods(action) {
  const api = '/access/publicMethod/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessPublicMethodsDeleteSuccess());
    } else {
      yield put(sendRequestAccessPublicMethodsDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessPublicMethodsDeleteError());
  }
}

//Access Role Public Methods
export function* handleGetAccessRolePublicMethods(action) {
  const api = '/access/rolePublicMethod/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessRolePublicMethodsGetSuccess());
      yield put(setAccessRolePublicMethods(response.data.data));
    } else {
      yield put(sendRequestAccessRolePublicMethodsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessRolePublicMethodsGetError());
  }
}

export function* handleCreateAccessRolePublicMethods(action) {
  const api = '/access/rolePublicMethod/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessRolePublicMethodsCreateSuccess());
    } else {
      yield put(sendRequestAccessRolePublicMethodsCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessRolePublicMethodsCreateError());
  }
}

export function* handleUpdateAccessRolePublicMethods(action) {
  const api = '/access/rolePublicMethod/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessRolePublicMethodsUpdateSuccess());
    } else {
      yield put(sendRequestAccessRolePublicMethodsUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessRolePublicMethodsUpdateError());
  }
}

export function* handleDeleteAccessRolePublicMethods(action) {
  const api = '/access/rolePublicMethod/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessRolePublicMethodsDeleteSuccess());
    } else {
      yield put(sendRequestAccessRolePublicMethodsDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessRolePublicMethodsDeleteError());
  }
}

//Access Role Users
export function* handleGetAccessRoleUsers(action) {
  const api = '/access/roleUsers/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessRoleUsersGetSuccess());
      yield put(setAccessRoleUsers(response.data.data));
    } else {
      yield put(sendRequestAccessRoleUsersGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessRoleUsersGetError());
  }
}

export function* handleCreateAccessRoleUsers(action) {
  const api = '/access/roleUsers/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessRoleUsersCreateSuccess());
    } else {
      yield put(sendRequestAccessRoleUsersCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessRoleUsersCreateError());
  }
}

export function* handleUpdateAccessRoleUsers(action) {
  const api = '/access/roleUsers/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessRoleUsersUpdateSuccess());
    } else {
      yield put(sendRequestAccessRoleUsersUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessRoleUsersUpdateError());
  }
}

export function* handleDeleteAccessRoleUsers(action) {
  const api = '/access/roleUsers/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestAccessRoleUsersDeleteSuccess());
    } else {
      yield put(sendRequestAccessRoleUsersDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestAccessRoleUsersDeleteError());
  }
}

//Entity
export function* handleGetEntity(action) {
  const api = '/entitySettings/entityForSettings/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestEntityGetSuccess());
      yield put(setEntity(response.data.data.entityForSettings));
    } else {
      yield put(sendRequestEntityGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestEntityGetError());
  }
}

export function* handleCreateEntity(action) {
  const api = '/entitySettings/entityForSettings/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestEntityCreateSuccess());
    } else {
      yield put(sendRequestEntityCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestEntityCreateError());
  }
}

export function* handleUpdateEntity(action) {
  const api = '/entitySettings/entityForSettings/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestEntityUpdateSuccess());
    } else {
      yield put(sendRequestEntityUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestEntityUpdateError());
  }
}

export function* handleDeleteEntity(action) {
  const api = '/entitySettings/entityForSettings/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestEntityDeleteSuccess());
    } else {
      yield put(sendRequestEntityDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestEntityDeleteError());
  }
}

//Entity Keys
export function* handleGetEntityKeys(action) {
  const api = '/entitySettings/EntitySettingsKeys/getByEntityId?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestEntityKeysGetSuccess());
      yield put(setEntityKeys(response.data.data.entitySettingsKeys));
    } else {
      yield put(sendRequestEntityKeysGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestEntityKeysGetError());
  }
}

export function* handleCreateEntityKeys(action) {
  const api = '/entitySettings/EntitySettingsKeys/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestEntityKeysCreateSuccess());
    } else {
      yield put(sendRequestEntityKeysCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestEntityKeysCreateError());
  }
}

export function* handleUpdateEntityKeys(action) {
  const api = '/entitySettings/EntitySettingsKeys/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestEntityKeysUpdateSuccess());
    } else {
      yield put(sendRequestEntityKeysUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestEntityKeysUpdateError());
  }
}

export function* handleDeleteEntityKeys(action) {
  const api = '/entitySettings/EntitySettingsKeys/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestEntityKeysDeleteSuccess());
    } else {
      yield put(sendRequestEntityKeysDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestEntityKeysDeleteError());
  }
}

//Entity Key Roles
export function* handleGetEntityKeyRoles(action) {
  const api = '/entitySettings/EntitySettingsKeysRoles/getByEntitySettingKeyId?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestEntityKeyRolesGetSuccess());
      yield put(setEntityKeyRoles(response.data.data.entitySettingsKeyRoles));
    } else {
      yield put(sendRequestEntityKeyRolesGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestEntityKeyRolesGetError());
  }
}

export function* handleCreateEntityKeyRoles(action) {
  const api = '/entitySettings/EntitySettingsKeysRoles/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestEntityKeyRolesCreateSuccess());
    } else {
      yield put(sendRequestEntityKeyRolesCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestEntityKeyRolesCreateError());
  }
}

export function* handleUpdateEntityKeyRoles(action) {
  const api = '/entitySettings/EntitySettingsKeysRoles/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestEntityKeyRolesUpdateSuccess());
    } else {
      yield put(sendRequestEntityKeyRolesUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestEntityKeyRolesUpdateError());
  }
}

export function* handleDeleteEntityKeyRoles(action) {
  const api = '/entitySettings/EntitySettingsKeysRoles/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestEntityKeyRolesDeleteSuccess());
    } else {
      yield put(sendRequestEntityKeyRolesDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestEntityKeyRolesDeleteError());
  }
}
