import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setFormFiltersShow,
  updateFormFilterData,
  resetState,
  fetchDataForMap,
  fetchLocationsLib,
  fetchFormFilters,
} from '../../../../../redux/reducers/utils/deliveryReducers/deliveryMapReducer';
import styled from 'styled-components';
import { Form, Button, Select, DatePicker, Checkbox } from 'antd';
import { FilterOutlined, CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

const { Option } = Select;
dayjs.extend(customParseFormat);
const serverFormat = 'YYYY-MM-DD';
const clientFormat = 'DD-MM-YYYY';

const MapFilter = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const {
    formFilters,
    formFiltersShow,
    selectedFilters,
  } = useSelector((store) => store.deliveryMap);
  const dispatch = useDispatch();
  const cities = formFilters;
  const [form] = Form.useForm();

  useEffect(() => {
    if (isAuth) {
      dispatch(fetchFormFilters({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  const handleOpen = () => { dispatch(setFormFiltersShow(true)); };
  const handleClose = () => { dispatch(setFormFiltersShow(false)); };

  const handleCityChange = (value, option) => {
    const updatedFilter = {
      city: value,
      cityCoordinates: option.coordinates,
      delivery: null,
      timePeriod: null,
      date: null,
      polygons: null,
    };

    dispatch(updateFormFilterData(updatedFilter));
    dispatch(fetchLocationsLib({ token: localStorage.getItem('token'), cityId: value }));
    setTimeout(() => {
      form.resetFields(['delivery', 'timePeriod', 'day', 'routePolygonIds']);
    }, 0)
  };

  const handleDeliveryChange = (value) => {
    const updatedFilter = {
      delivery: value,
      timePeriod: null,
      date: null,
      polygons: null,
    };

    dispatch(updateFormFilterData(updatedFilter));
    setTimeout(() => {
      form.resetFields(['timePeriod', 'day', 'routePolygonIds']);
    }, 0)
  };

  const handleTimePeriodChange = (value, option) => {
    const selectedPeriod = option.data;
    const racePolygonIds = selectedPeriod.racePolygonIds.map(polygon => polygon.id)
    const updatedFilter = {
      timePeriod: value,
      date: null,
      polygons: racePolygonIds,
    };

    dispatch(updateFormFilterData(updatedFilter));
    setTimeout(() => {
      form.resetFields(['day', 'routePolygonIds']);
    }, 0)
  };

  const handleDateChange = (value) => {
    const updatedFilter = {
      date: dayjs(value).format(serverFormat),
    };

    dispatch(updateFormFilterData(updatedFilter));

    form.validateFields()
      .then(() => {
        const requestData = {
          token: localStorage.getItem('token'),
          raceId: selectedFilters.timePeriod,
          racePolygonIds: selectedFilters.polygons,
          day: dayjs(value).format(serverFormat),
        };
    
        dispatch(fetchDataForMap(requestData));
      })
      .catch((errorInfo) => {
        console.error('Ошибка при валидации формы:', errorInfo);
      });

    dispatch(resetState());
  };

  const onChangePolygons = (checkedValues) => {
    const updatedFilter = {
      polygons: checkedValues,
    };

    dispatch(updateFormFilterData(updatedFilter));

    form.validateFields()
      .then(() => {
        const requestData = {
          token: localStorage.getItem('token'),
          raceId: selectedFilters.timePeriod,
          racePolygonIds: checkedValues,
          day: selectedFilters.date,
        };
    
        dispatch(fetchDataForMap(requestData));
      })
      .catch((errorInfo) => {
        console.error('Ошибка при валидации формы:', errorInfo);
      });

    dispatch(resetState());
  };

  return (
    <Wrapper>
      {!formFiltersShow &&
        <Button
          style={{marginTop: '10px'}}
          type="primary"
          onClick={handleOpen}
        >
          <FilterOutlined />
        </Button>
      }
      {formFiltersShow &&
        <Popup>
          <Button
            style={{ position: 'absolute', top: '5px', right: '5px', zIndex: 800 }}
            type='text'
            icon={<CloseOutlined/>}
            onClick={handleClose}
          />
          <Content>
            <Form
              form={form}
              name='MapFilter'
              initialValues={{
                city: selectedFilters?.city,
                delivery: selectedFilters?.delivery,
                timePeriod: selectedFilters?.timePeriod,
                day: selectedFilters?.date && dayjs(selectedFilters?.date),
                routePolygonIds: selectedFilters?.polygons,
              }}
            >
              <StyledFormItem name='city' rules={[{ required: true }]}>
                <Select style={{ width:'100%' }} placeholder='Выберите город' onChange={handleCityChange}>
                  {cities.map((city) => (
                    <Option key={city.id} value={city.id} coordinates={[city.cityLatitude, city.cityLongitude]}>
                      {city.name}
                    </Option>
                  ))}
                </Select>
              </StyledFormItem>
              <StyledFormItem name='delivery' rules={[{ required: true }]}>
                <Select style={{ width:'100%' }} placeholder='Выберите доставку' onChange={handleDeliveryChange} disabled={!selectedFilters?.city}>
                  {selectedFilters?.city && 
                    cities.find((city) => city.id === selectedFilters.city)?.deliveryServices.map((delivery) => (
                      <Option key={delivery.id} value={delivery.id}>
                        {delivery.name}
                      </Option>
                    ))}
                </Select>
              </StyledFormItem>
              <StyledFormItem name='timePeriod' rules={[{ required: true }]}>
                <Select style={{ width:'100%' }} placeholder='Выберите время' onChange={handleTimePeriodChange} disabled={!selectedFilters?.delivery}>
                  {selectedFilters?.delivery && selectedFilters?.city &&
                    cities.find((city) => city.id === selectedFilters.city)?.deliveryServices
                      .find((delivery) => delivery.id === selectedFilters.delivery)?.listPeriods.map((period) => (
                        <Option key={period.deliveryServiceId} value={period.raceId} data={period}>
                          Доставка с {period.timeStart} до {period.timeEnd}
                        </Option>
                      ))}
                </Select>
              </StyledFormItem>
              <StyledFormItem name='day' rules={[{ required: true }]}>
                <DatePicker style={{ width:'100%' }} placeholder='Введите дату' format={clientFormat} onChange={handleDateChange} disabled={!selectedFilters?.timePeriod} />
              </StyledFormItem>
              <StyledFormItem name='routePolygonIds' rules={[{ required: true, type: 'array', message: 'Выберите хотя бы один полигон' }]} >
                <Checkbox.Group style={{ width: '100%' }} onChange={onChangePolygons}>
                  {selectedFilters?.timePeriod && selectedFilters?.delivery && selectedFilters?.city &&
                    cities.find((city) => city.id === selectedFilters.city)?.deliveryServices
                      .find((delivery) => delivery.id === selectedFilters.delivery)?.listPeriods
                      .find((period) => period.raceId === selectedFilters.timePeriod)?.racePolygonIds.map((polygon) => (
                        <Checkbox key={polygon.id} value={polygon.id}>{polygon.name}</Checkbox>
                      ))}
                </Checkbox.Group>
              </StyledFormItem>
            </Form>
          </Content>
        </Popup>
      }
    </Wrapper>
  );
};

export default MapFilter;

const Wrapper = styled.div`

`;

const Popup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  padding: 40px 20px 15px;
  width: 100%;
  max-width: 350px;
  min-width: 300px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 10px;

  & .ant-form-item-label {
    padding: 0;
  }
`;
