import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Text from '../../../../../ui/Text';
import Card from '../../../../../components/Card';
import Title from '../../../../../ui/Title';
import { formatDate } from '../../../../../core/utils';

const Menu = ({ isBlocked, currentRace, timeStart, timeEnd, products }) => {
    const [groupedProducts, setGroupedProducts] = useState({});

    useEffect(() => {
        const categories = products.filter(products => products.status === 'on').reduce((acc, product) => {
            const { categoryName } = product;
            if (!acc[categoryName]) {
                acc[categoryName] = [];
            }
            acc[categoryName].push(product);
            return acc;
        }, {});
        setGroupedProducts(categories);
    }, [products]);

    return (
        <Card style={{filter: isBlocked && 'opacity(0.6)'}}>
            {isBlocked && <BlockedLayer>
                <div>Приём заказов<br/>приостановлен</div>
            </BlockedLayer>}
            <CardHeader>
                <Title level={2} align='center'>Меню "{currentRace?.kitchenName}" на {currentRace?.deliveryDay && formatDate(currentRace?.deliveryDay)}</Title>
                <Text size='24px'>доставка с {timeStart}-{timeEnd}</Text>
            </CardHeader>
            <StyledTable>
                <thead>
                    <StyledTr>
                        <StyledTh style={{width: '100%'}}>Название</StyledTh>
                        <StyledTh>Цена</StyledTh>
                        <StyledTh>Продано</StyledTh>
                        <StyledTh>Опубликовано</StyledTh>
                    </StyledTr>
                </thead>
            </StyledTable>
            {Object.entries(groupedProducts).map(([categoryName, items]) => (
                <Category key={categoryName}>
                    <Title level={3}>{categoryName}</Title>
                    <StyledTable>
                        <tbody>
                            {items.map(item => (
                                <StyledTr key={item.productId}>
                                    <StyledTd style={{width: '100%'}}>{item.name}</StyledTd>
                                    <StyledTd>{item.price}</StyledTd>
                                    <StyledTd>{item.amountOrdered || '0'}</StyledTd>
                                    <StyledTd>{item.amountForOrder || '∞'}</StyledTd>
                                </StyledTr>
                            ))}
                        </tbody>
                    </StyledTable>
                </Category>
            ))}
        </Card>
    );
};

export default Menu;

const BlockedLayer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    div {
        margin-top: 30vh;
        width: 320px;
        height: fit-content;
        padding: 20px;
        background: #EDEDED;
        border-radius: 24px;

        font-size: 32px;
        font-weight: 500;
        text-align: center;
        color: #B2B2B2;
    }
`;

const CardHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

const Category = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 16px;
    padding-bottom: 32px;
`;

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
`;

const StyledTr = styled.tr`
    &:not(:last-child) {
        border-bottom: 1px solid #eeeeee;
    }
`;

const StyledTh = styled.th`
    min-width: 90px;
    padding: 12px 0;
    font-size: 14px;
    color: #b2b2b2;
    text-align: left;
`;

const StyledTd = styled.td`
    min-width: 90px;
    padding: 12px 0;
    font-size: 18px;
    text-align: left;
`;
