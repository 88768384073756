import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getKitchenNotWorking,
  createKitchenNotWorking,
  deleteKitchenNotWorking,
} from '../redux/reducers/utils/kitchenReducers/kitchenSettingsReducer';
import { Flex, Calendar, Button, Select } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import styled from 'styled-components';

dayjs.extend(customParseFormat);
const serverFormat = 'YYYY-MM-DD';
const clientFormat = 'DD-MM-YYYY';

const NotWorkingDatePicker = () => {
  const { selectKitchen } = useSelector((store) => store.kitchen);
  const {
    kitchenNotWorking,
  } = useSelector((store) => store.kitchenSettings);
  const dispatch = useDispatch();

  const [datesData, setDatesData] = useState([]);
  const [uniqueKey, setUniqueKey] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const [currentDate, setCurrentDate] = useState(dayjs());
  const currentMonthName = currentDate.format('MMMM');
  const startOfСurrentMonth = currentDate.startOf('month');
  const endOfСurrentMonth = currentDate.endOf('month');
  const validRangeСurrentMonth = [startOfСurrentMonth, endOfСurrentMonth];

  const [nextMonthDate, setNextMonthDate] = useState(dayjs().add(1, 'month'));
  const nextMonthName = nextMonthDate.format('MMMM');
  const startOfNextMonth = nextMonthDate.startOf('month');
  const endOfNextMonth = nextMonthDate.endOf('month');
  const validRangeNextMonth = [startOfNextMonth, endOfNextMonth];

  const [afterMonthDate, setAfterMonthDate] = useState(dayjs().add(2, 'month'));
  const afterMonthName = afterMonthDate.format('MMMM');
  const startOfAfterMonth = afterMonthDate.startOf('month');
  const endOfAfterMonth = afterMonthDate.endOf('month');
  const validRangeAfterMonth = [startOfAfterMonth, endOfAfterMonth];

  useEffect(() => {
    if (kitchenNotWorking) {
      setDatesData(kitchenNotWorking);
    }
  }, [kitchenNotWorking])

  const headerRender = (month) => {
    return (
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '25px',
          color: '#000000',
        }}
      >
        {month}
      </div>
    );
  };

  const dateCellRender = (value, validRange) => {
    const selectedDateStr = value.format(serverFormat);
    const isSelected = selectedDates.includes(selectedDateStr);
    const isInValidRange = dayjs(value).isAfter(validRange[0]) && dayjs(value).isBefore(validRange[1]);
    const notWorkingDay = datesData.find(item => item.day === selectedDateStr);

    return (
      <>
        {isInValidRange && 
          <>
            {notWorkingDay ? 
              <NotWorkingDayDateCell
                active={notWorkingDay.uniqueKey === uniqueKey?.id}
                onClick={() => {handleClickNotWorkingDate(notWorkingDay)}}
              >
                {value.date()}
              </NotWorkingDayDateCell>
            :
              <DateCell onClick={() => {handleSelect(value)}}>
                {value.date()}
                {isSelected && <SelectedDateCell></SelectedDateCell>}
              </DateCell>
            }

          </>
        }
      </>
    );
  };

  const handleClickNotWorkingDate = (item) => {
    console.log(item)
    if (!uniqueKey || uniqueKey.id !== item.uniqueKey) {
      setUniqueKey({id: item.uniqueKey, reason: item.reason})
    } else {
      setUniqueKey(null)
    }
  };

  const handleSelect = (date) => {
    const selectedDateStr = date.format(serverFormat);
    setSelectedDates((prevDates) =>
      prevDates.includes(selectedDateStr)
        ? prevDates.filter((d) => d !== selectedDateStr)
        : [...prevDates, selectedDateStr]
    );
    setUniqueKey(null)
  };

  const handleChange = (value) => {
    setInputValue(value);
  }

  const handleCreate = () => {
    const getUniqueKey = () => { return crypto.randomUUID(); };

    const requestData = {
      token: localStorage.getItem('token'),
      kitchenId: selectKitchen.id,
      days: selectedDates,
      reason: inputValue,
      uniqueKey: getUniqueKey(),
    }

    dispatch(createKitchenNotWorking(requestData))
    .then(() => {
      return dispatch(getKitchenNotWorking({ token: localStorage.getItem('token'), kitchenId: selectKitchen.id }))
    });
    setSelectedDates([]);
    setInputValue('');
  }

  const handleDelete = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      uniqueKey: uniqueKey.id,
    }

    dispatch(deleteKitchenNotWorking(requestData))
    .then(() => {
      return dispatch(getKitchenNotWorking({ token: localStorage.getItem('token'), kitchenId: selectKitchen.id }))
    });
    setSelectedDates([]);
    setUniqueKey(null);
  }
  
  return (
    <>
      <CalendarWrapper>
        <Calendar
          style={{width: '300px'}}
          value={currentDate}
          fullscreen={false}
          validRange={validRangeСurrentMonth}
          headerRender={() => headerRender(currentMonthName)}
          dateFullCellRender={(value) => dateCellRender(value, validRangeСurrentMonth)}
        />
        <Calendar
          style={{width: '300px'}}
          value={nextMonthDate}
          fullscreen={false}
          validRange={validRangeNextMonth}
          headerRender={() => headerRender(nextMonthName)}
          dateFullCellRender={(value) => dateCellRender(value, validRangeNextMonth)}
        />
        <Calendar
          style={{width: '300px'}}
          value={afterMonthDate}
          fullscreen={false}
          validRange={validRangeAfterMonth}
          headerRender={() => headerRender(afterMonthName)}
          dateFullCellRender={(value) => dateCellRender(value, validRangeAfterMonth)}
        />
        <Flex
          style={{flex: '1 1 100%'}}
          wrap='wrap'
          gap='10px 20px'
        >
          {uniqueKey ? 
            <Button style={{flex: '1 0 auto'}} type='primary' danger={true} onClick={handleDelete}>Отменить не рабочие дни "{uniqueKey.reason}"</Button>
          :
            <>
              <Select
                style={{ flex: '1 1 75%' }}
                placeholder="Укажите причину"
                value={inputValue}
                onChange={handleChange}
                allowClear
              >
                <Select.Option value={'Выходной день'}><p>Выходной день</p></Select.Option>
                <Select.Option value={'Санитарный день'}><p>Санитарный день</p></Select.Option>
                <Select.Option value={'Отпуск'}><p>Отпуск</p></Select.Option>
              </Select>
              <Button style={{flex: '1 0 auto'}} type='primary' disabled={!inputValue} onClick={handleCreate}>Добавить</Button>
            </>
          }
        </Flex>
      </CalendarWrapper>
    </>
  );
};

export default NotWorkingDatePicker;

const CalendarWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
`
const DateCell = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 25px;
  height: 25px;
  color: #000000;
`
const NotWorkingDayDateCell = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  
  margin: 0 auto;
  width: 25px;
  height: 25px;

  background: #EA394B;
  border-radius: 5px;
  color: #ffffff;

  box-shadow: ${(props) => (props.active ? '0 0 15px 0px #0000ff' : undefined)};
`
const SelectedDateCell = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  
  margin: 0 auto;
  width: 25px;
  height: 25px;

  background: transparent;
  border: solid 1px #EA394B;
  border-radius: 5px;
`