import React from 'react';
import styled from 'styled-components';

const Text = ({ style, className, size, strong, align, color, link, children }) => {
  return (
    <TextWrapper
        style={style}
        className={className}
        size={size}
        strong={strong}
        align={align}
        color={color} 
    >
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <span>{children}</span>
      )}
    </TextWrapper>
  );
};

export default Text;

const TextWrapper = styled.div`
    display: flex;
    font-size: ${(props) => props.size && props.size};
    font-weight: ${(props) => props.strong && 'bold'};
    text-align: ${(props) => props.align && props.align};                      
    color: ${(props) => props.color ? props.color : '#2A2A2A'};

    a {
        text-decoration: none;
        color: inherit;
        &:hover {
            color: var(--color-accent);
            text-decoration: none;
        }
    }
`;
