import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestDeliveryServiceCouriersUpdate,
  sendRequestDeliveryServiceCouriersDelete,
  setCurrentDeliveryServiceCourier,
} from '../../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Flex, Form, ColorPicker, Popconfirm, Input, Button, Typography } from 'antd';

const { Title } = Typography;

const EditableForm = () => {
  const navigate = useNavigate();
  const {
    currentDeliveryService,
    currentDeliveryServiceCourier,
  } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (currentDeliveryServiceCourier) {
      form.setFieldsValue({
        color: currentDeliveryServiceCourier.color,
        prefix: currentDeliveryServiceCourier.prefix,
        name: currentDeliveryServiceCourier.name,
        phone: currentDeliveryServiceCourier.phone,
      });
    }
  }, [currentDeliveryServiceCourier, form]);

  const handleSubmit = () => {
    form.validateFields()
    .then((values) => {
      const requestData = {
        token: localStorage.getItem('token'),
        courierId: currentDeliveryServiceCourier.courierId,
        deliveryServiceId: currentDeliveryService.id,
        color: typeof values.color === 'object' ? `#${values.color.toHex()}` : values.color,
        prefix: values.prefix,
        name: values.name,
        phone: values.phone,
        isEnabled: currentDeliveryServiceCourier.isEnabled,
      };

      console.log(requestData)
      dispatch(sendRequestDeliveryServiceCouriersUpdate(requestData));
      form.resetFields();
    })
    .catch((errorInfo) => {
      console.error('Ошибка при валидации формы:', errorInfo);
    });
  }

  const handleDelete = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      deliveryServiceId: currentDeliveryService.id,
      courierId: currentDeliveryServiceCourier.courierId,
    }

    console.log(requestData)
    dispatch(sendRequestDeliveryServiceCouriersDelete(requestData));
    navigate(`/delivery/services`);
    dispatch(setCurrentDeliveryServiceCourier(null));
  };

  const handleLiterChange = (e) => {
    const upperCaseValue = e.target.value.toUpperCase();
    form.setFieldsValue({ prefix: upperCaseValue });
  };

  return (
    <Flex vertical>
      <Form
        style={{ width: '100%' }}
        form={form}
        name='deliveryService'
        layout='vertical'
        initialValues={{
          color: currentDeliveryServiceCourier.color,
          prefix: currentDeliveryServiceCourier.prefix,
          name: currentDeliveryServiceCourier.name,
          phone: currentDeliveryServiceCourier.phone,
        }}
      >
        <Flex gap='large'>
            <Form.Item label='Цвет' name='color' rules={[{ required: true, message: 'Выберите цвет' }]}><ColorPicker/></Form.Item>
            <Form.Item label='Литера' name='prefix' rules={[{ required: true, message: 'Введите литеру' }]}><Input maxLength={3} placeholder='Введите литеру' onChange={handleLiterChange}/></Form.Item>
          </Flex>
          <Form.Item label='Имя' name='name' rules={[{ required: true, message: 'Введите имя' }]}><Input placeholder='Введите имя'/></Form.Item>
          <Form.Item label='Телефон' name='phone' rules={[{ required: true, message: 'Введите телефон' }]}><Input maxLength={12} disabled/></Form.Item>
        </Form>
      <Flex gap='large'>
        <Button type='primary' htmlType='submit' onClick={handleSubmit} style={{ display: 'flex', marginLeft: 'auto' }}>Сохранить</Button>
        <Popconfirm
          title="Удаление службы доставки!"
          description={`Вы действительно хотите удалить службу доставки?`}
          okText="Да"
          cancelText="Нет"
          placement="left"
          onConfirm={handleDelete}
        >
          <Button danger={true}>Удалить</Button>
        </Popconfirm>
      </Flex>
    </Flex>
  );
};

export default EditableForm;
