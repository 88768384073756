import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { CheckIcon, ClipIcon, DownloadIcon, ShevronRightIcon } from '../../../../../components/Icons';
import { Text, Title } from '../../../../../core/styles';
import { Modal, Input, Button } from 'antd';
import liveSitiyBanner from '../../../../../assets/img/live-sitiy-banner.png';
import step1 from '../../../../../assets/img/step-1.png';
import step2 from '../../../../../assets/img/step-2.png';
import step31 from '../../../../../assets/img/step-3-1.png';
import step32 from '../../../../../assets/img/step-3-2.png';
import step4 from '../../../../../assets/img/step-4.png';
import Flex from '../../../../../components/Flex';
import { Link } from 'react-router-dom';

const colors = ['#B3E6F2', '#FBD5D5', '#CAE0FF', '#FFE8CB'];

const NewKitchenSteps = ({onClick}) => {
    const { selectKitchen } = useSelector((store) => store.kitchen);
    const [openContactsModal, setOpenContactsModal] = useState(false);

    const steps = [
        {
            id: 'concludeContract',
            title: 'Заключить договор',
            status: selectKitchen.stepsStartKitchen.concludeContract,
        },
        {
            id: 'fillListProducts',
            title: 'Заполнить список товаров',
            status: selectKitchen.stepsStartKitchen.fillListProducts,
        },
        {
            id: 'dishesAndBagsBeenPurchased',
            title: 'Закупить посуду и пакеты',
            status: selectKitchen.stepsStartKitchen.dishesAndBagsBeenPurchased,
        },
        {
            id: 'testRunBeen',
            title: 'Провести тестовый запуск',
            status: selectKitchen.stepsStartKitchen.testRunBeen,
        },
    ];

    const handleDownloadClick = () => {
        alert('Нужна ссылка на скачивание типового договора')
    }

    const handleContactsClick = () => {
        setOpenContactsModal(true);
    }

    const handleCloseContactsModal = () => {
        setOpenContactsModal(false);
    }

    return (
        <Wrapper>
            <Header>
                <StyledTitle>4 шага для<br/>запуска кухни</StyledTitle>
                <Banner href='https://live.sitiy.ru/' target='_blank' rel="noopener nofollow">Посмотреть заказы в<br/>реальном времени</Banner>
            </Header>

            <Cards>
                {steps.map((item, index) => (
                    <StepCard
                        key={item.id}
                        title={item.title}
                        bg={colors[index]}
                        status={item.status}
                        onClick={item.id === 'fillListProducts' ? onClick : undefined}
                    >
                        {item.id === 'concludeContract' ?
                            <>
                                <StepButton onClick={handleDownloadClick}>Скачать шаблон договора <DownloadIcon/></StepButton>
                                <ContactsButton to={'/kitchen/settings'}>
                                    <Flex column flex='1'>
                                        <Title style={{marginBottom: '10px'}} level={5}>Заполните реквизиты</Title>
                                        <Text secondary size='14px' align='left'>Это можно сделать один раз. При изменении, обращайтесь к менеджеру</Text>
                                    </Flex>
                                    <ShevronRightIcon/>
                                </ContactsButton>
                                <img style={{bottom: '-48px', left: 0}} src={step1}/>
                            </>
                        : item.id === 'fillListProducts' ?
                            <>
                                <img style={{bottom: '-24px', right: 0}} src={step2}/>
                            </>
                        : item.id === 'dishesAndBagsBeenPurchased' ?
                            <>
                                <Notice color='#E1EDFF'>
                                    <div style={{position: 'absolute', top: '-12px', right: '20px'}}><ClipIcon/></div>
                                    <p>У нас есть проверенная регламентированная тара и рекомендованная посуда.</p>

                                    <a style={{marginTop: '10px'}} href='#'>Почему нельзя использовать любую? <span style={{textDecoration: 'underline'}}>Читать...</span></a>
                                </Notice>
                                <img style={{bottom: '-48px', right: '12px', zIndex: 1}} src={step31}/>
                                <img style={{bottom: 0, right: '72px'}} src={step32}/>
                            </>
                        : item.id === 'testRunBeen' ?
                            <>
                                <Notice color='#FFF3E0'>
                                    <div style={{position: 'absolute', top: '-12px', right: '20px'}}><ClipIcon/></div>
                                    Как только вы будете готовы, у вас будут приобретены пакеты и посуда, внесены товары, мы согласуем дату проведения тестового запуска
                                </Notice>
                                <img style={{bottom: '-48px', right: 0}} src={step4}/>
                            </>
                        : null}
                    </StepCard>
                ))}
            </Cards>
        </Wrapper>
    );
}

export default NewKitchenSteps;

const StepCard = ({title, bg, status, children, onClick}) => {
    return (
        <Card bg={bg} style={{cursor: onClick ? 'pointer' : undefined}} onClick={onClick}>
            <CardBody>
                <CardTitle>{title}</CardTitle>
                <CardContent>
                    {children}
                </CardContent>
            </CardBody>
            <CardFooter status={status}>
                <p>{status === 1 ? 'Выполнено' : 'В ожидании выполнения'}</p>
                <div><CheckIcon/></div>
            </CardFooter>
        </Card>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
`
const Header = styled.div`
    flex: 1;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
`
const StyledTitle = styled.div`
    flex: 1 1 330px;
    display: flex;
    font-size: 44px;
    font-weight: 700;
    color: #2A2A2A;
`
const Banner = styled.a`
    flex: 1 0 330px;
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
    height: 100px;
    padding: 16px 32px;
    background: url(${liveSitiyBanner}) no-repeat center center / cover;
    border-radius: 32px;
    font-size: 24px;
    color: #ffffff;
    overflow: hidden;
    cursor: pointer;
    transition: all .5s;
    &:hover {
        scale: 1.025;
    }
`
const Cards = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    @media (max-width: 1440px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }
`
const Card = styled.div`
    position: relative;
    flex: 1 1 305px;
    display: flex;
    flex-direction: column;
    background: ${({ bg }) => bg ? bg : '#ffffff'};
    border-radius: 32px;
    overflow: hidden;
    transition: all .3s;
    &:hover {
        transform: translateY(-10px);
    }
`
const CardTitle = styled.div`
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 600;
`
const CardBody = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 24px;
    min-height: 360px;
    img {
        position: absolute;
    }
    @media (max-width: 768px) {
        min-height: auto;
    }
`
const CardContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        display: none;
    }
`
const CardFooter = styled.div`
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-top: auto;
    padding: 5px 5px 5px 24px;
    background: ${(props) => props.status === 1 ? '#6BCBAF' : '#ffffff'};
    border-radius: 32px;
    color: ${(props) => props.status === 1 ? '#ffffff' : '#B2B2B2'};
    div {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 60px;
        min-height: 60px;
        background: ${(props) => props.status === 1 ? '#ffffff' : '#EAEAEA'};
        border-radius: 60px;
        color: ${(props) => props.status === 1 ? '#49BC9A' : '#B2B2B2'};
    }
`
const StepButton = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-bottom: 12px;
    padding: 0 16px;
    height: 50px;
    background: #36ACC8;
    border-radius: var(--radius-medium);
    font-size: 16px;
    color: #ffffff;
    transition: all .3s;
    &:hover {
        background: #3098b2;
    }
    &:active {
        background: #2a859c;
    }
`
const ContactsButton = styled(Link)`
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 16px;
    background: #ffffff;
    border-radius: var(--radius-medium);
    color: #000000;
    transition: all .3s;
    &:hover {
        background: #F9F9F9;
        color: var(--color-accent);
    }
    &:active {
        background: #ffffff;
    }
`
const Notice = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px;
    background: ${(props) => props.color ? props.color : '#F9F9F9'};
    border-radius: var(--radius-medium);
`
const Textarea = styled(Input.TextArea)`
  width: 100%;
  margin-bottom: 16px;
`
const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`
