import apiInstance from '../../../../../api';
import {call, put, select} from 'redux-saga/effects';
import {
  sendRequestAddressesSearchSuccess,
  sendRequestAddressesSearchError,
  setAddresses,
} from '../../../../reducers/utils/clientsReducers/addressesSearchReducer';
import { selectCommonState } from '../../../../reducers/utils/commonReducer';

export function* handleGetAddresses(action) {
  const api = '/address/search?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestAddressesSearchSuccess());
      yield put(setAddresses(response.data.data));
    } else {
      yield put(sendRequestAddressesSearchError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestAddressesSearchError());
  }
}
