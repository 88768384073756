import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  // Wallets
  walletsSearchRequestStatus: null,
  wallets: [],
  totalWallets: 0,
  currentPage: 1,
  uploadWalletsRequestStatus: null,
  uploadInfo: null,
  uploadStatus: null,
  walletCreateRequestStatus: null,
  walletUpdateRequestStatus: null,
  walletsStatusesGetRequestStatus: null,
  walletsStatuses: [],

  // Transactions
  transactionsSearchRequestStatus: null,
  transactions: [],
  totalTransactions: 0,
  currentTransactionsPage: 1,
  transactionCreateRequestStatus: null,
};

const financeWalletsSlice = createSlice({
  name: 'financeWallets',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    // Wallets
    sendRequestWalletsSearch: (state) => { state.walletsSearchRequestStatus = 'pending'; },
    sendRequestWalletsSearchSuccess: (state) => { state.walletsSearchRequestStatus = 'resolved'; },
    sendRequestWalletsSearchError: (state) => { state.walletsSearchRequestStatus = 'rejected'; },
    clearWalletsSearchStatus: (state) => { state.walletsSearchRequestStatus = null; },
    setWallets: (state, action) => {
      const data = action.payload;

      state.wallets = data.wallets;
      state.totalWallets = data.countWallets;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },

    sendRequestUploadWallets: (state) => { state.uploadWalletsRequestStatus = 'pending'; },
    sendRequestUploadWalletsSuccess: (state) => { state.uploadWalletsRequestStatus = 'resolved'; },
    sendRequestUploadWalletsError: (state) => { state.uploadWalletsRequestStatus = 'rejected'; },
    clearUploadWalletsStatus: (state) => { state.uploadWalletsRequestStatus = null; },
    setUploadData: (state, action) => {
      const data = action.payload;

      state.uploadInfo = data.informationAboutWallets;
      state.uploadStatus = data.status;
    },
    clearUploadData: (state) => {
      state.uploadInfo = null;
      state.uploadStatus = null;
    },

    sendRequestWalletCreate: (state) => { state.walletCreateRequestStatus = 'pending'; },
    sendRequestWalletCreateSuccess: (state) => { state.walletCreateRequestStatus = 'resolved'; },
    sendRequestWalletCreateError: (state) => { state.walletCreateRequestStatus = 'rejected'; },
    clearWalletCreateStatus: (state) => { state.walletCreateRequestStatus = null; },

    sendRequestWalletUpdate: (state) => { state.walletUpdateRequestStatus = 'pending'; },
    sendRequestWalletUpdateSuccess: (state) => { state.walletUpdateRequestStatus = 'resolved'; },
    sendRequestWalletUpdateError: (state) => { state.walletUpdateRequestStatus = 'rejected'; },
    clearWalletUpdateStatus: (state) => { state.walletUpdateRequestStatus = null; },

    sendRequestWalletsStatusesGet: (state) => { state.walletsStatusesGetRequestStatus = 'pending'; },
    sendRequestWalletsStatusesGetSuccess: (state) => { state.walletsStatusesGetRequestStatus = 'resolved'; },
    sendRequestWalletsStatusesGetError: (state) => { state.walletsStatusesGetRequestStatus = 'rejected'; },
    clearWalletsStatusesGetStatus: (state) => { state.walletsStatusesGetRequestStatus = null; },
    setWalletsStatuses: (state, action) => {
      state.walletsStatuses = action.payload;
    },

    // Transactions
    sendRequestTransactionsSearch: (state) => { state.transactionsSearchRequestStatus = 'pending'; },
    sendRequestTransactionsSearchSuccess: (state) => { state.transactionsSearchRequestStatus = 'resolved'; },
    sendRequestTransactionsSearchError: (state) => { state.transactionsSearchRequestStatus = 'rejected'; },
    clearTransactionsSearchStatus: (state) => { state.transactionsSearchRequestStatus = null; },
    setTransactions: (state, action) => {
      const data = action.payload;

      state.transactions = data.walletTransactions;
      state.totalTransactions = data.countWalletTransactions;
    },
    setCurrentTransactionsPage: (state, action) => {
      state.currentTransactionsPage = action.payload;
    },

    sendRequestTransactionCreate: (state) => { state.transactionCreateRequestStatus = 'pending'; },
    sendRequestTransactionCreateSuccess: (state) => { state.transactionCreateRequestStatus = 'resolved'; },
    sendRequestTransactionCreateError: (state) => { state.transactionCreateRequestStatus = 'rejected'; },
    clearTransactionCreateStatus: (state) => { state.transactionCreateRequestStatus = null; },
  },
});

export const {
  // Wallets
  sendRequestWalletsSearch,
  sendRequestWalletsSearchSuccess,
  sendRequestWalletsSearchError,
  clearWalletsSearchStatus,
  setWallets,
  setCurrentPage,

  sendRequestUploadWallets,
  sendRequestUploadWalletsSuccess,
  sendRequestUploadWalletsError,
  clearUploadWalletsStatus,
  setUploadData,
  clearUploadData,

  sendRequestWalletCreate,
  sendRequestWalletCreateSuccess,
  sendRequestWalletCreateError,
  clearWalletCreateStatus,

  sendRequestWalletUpdate,
  sendRequestWalletUpdateSuccess,
  sendRequestWalletUpdateError,
  clearWalletUpdateStatus,

  sendRequestWalletsStatusesGet,
  sendRequestWalletsStatusesGetSuccess,
  sendRequestWalletsStatusesGetError,
  clearWalletsStatusesGetStatus,
  setWalletsStatuses,

  // Transactions
  sendRequestTransactionsSearch,
  sendRequestTransactionsSearchSuccess,
  sendRequestTransactionsSearchError,
  clearTransactionsSearchStatus,
  setTransactions,
  setCurrentTransactionsPage,

  sendRequestTransactionCreate,
  sendRequestTransactionCreateSuccess,
  sendRequestTransactionCreateError,
  clearTransactionCreateStatus,
} = financeWalletsSlice.actions;

export default financeWalletsSlice.reducer;