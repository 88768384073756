import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Tooltip as AntdTooltip } from 'antd';
import Text from './Text';

const Tooltip = ({ title, text, placement, open, children }) => {
    // useEffect(() => {
    //     document.documentElement.scrollTop = document.documentElement.clientHeight;
    //     document.documentElement.scrollLeft = document.documentElement.clientWidth;
    // }, []);

    return (
		<AntdTooltip
            title={() => (
                <div>
                    {title && <Text style={{marginBottom: '10px'}} size='14px' strong>{title}</Text>}
                    <Text size='14px'>{text}</Text>
                </div>
            )}
            placement={placement}
            open={title === '' && text === '' ? false : open}
            color='white'
            // overlayStyle={StyledWrapper}
            overlayInnerStyle={Styles}
		>
            <div>{children}</div>
        </AntdTooltip>
    )
}

export default Tooltip;

const Styles = {
    padding: '12px',
	background: '#ffffff',
    borderRadius: '8px',
    color: '#2a2a2a',
}
