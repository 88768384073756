import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { persistor } from '../redux/configureStore';
import { logout, setUserTimezone } from '../redux/reducers/utils/authReducer';
import { Dropdown, Avatar, Select } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Flex, Text, Title } from '../core/styles';
import moment from 'moment';
import { updateServerTime } from '../redux/reducers/utils/commonReducer';
import Button from './Button';
import { Link } from 'react-router-dom';

const { Option } = Select;

const HeaderProfileDropdownComponent = () => {
  const { serverTime } = useSelector((store) => store.common);
  const { userProfile, logoutStatus, userTimezone } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (logoutStatus === 'resolved') {
      localStorage.removeItem('token');
      localStorage.removeItem('session');
      persistor.purge();
    }
  },[logoutStatus])

  // useEffect(() => {
  //     const updateTime = () => {
  //     dispatch(updateServerTime(serverTime + 1));
  //   };

  //   const intervalId = setInterval(updateTime, 1000);

  //   return () => clearInterval(intervalId);
  // },[serverTime])

  const handleLogout = () => {
    const requestData = {
      token: localStorage.getItem('token')
    }

    dispatch(logout(requestData));
  };

  const handleTimezoneChange = (value) => {
    const timezone = parseInt(value, 10);
    dispatch(setUserTimezone(timezone));
  };

  const items = [
    {
      key: '1',
      label: userProfile ? 
        <div onClick={(e) => e.stopPropagation()}>
          <Text size='12px'>ID: {userProfile.userId}</Text>
          <Title level={5}>{userProfile.phone}</Title>
          <Text>{userProfile.name}</Text>
          
        </div>
      : 'Loading...',
      disabled: true,
    },
    {
      key: '2',
      label: (
        <Link to={'/settings/profile'}>Профиль</Link>
      ),
    },
    {
      key: '3',
      label: (
        <Text onClick={handleLogout}>Выйти</Text>
      ),
    },
    {
      key: '4',
      label: (
        <>
          <Text size={'10px'}>Серверное время: {serverTime ? moment.unix(serverTime).format('DD:MM:YYYY HH:mm:ss') : '--:--:--'}</Text>
          <Text size={'10px'}>Локальное время: {serverTime ? moment.unix(serverTime).utcOffset(userTimezone || 0).format('DD:MM:YYYY HH:mm:ss') : '--:--:--'}</Text>
        </>
      ),
      disabled: true,
    },
  ];

  return (
    <StyledDropdown menu={{ items }} trigger={['click']}>
      <Avatar icon={<UserOutlined />} onClick={(e) => e.preventDefault()}/>
    </StyledDropdown>
  );
};

export default HeaderProfileDropdownComponent;

const StyledDropdown = styled(Dropdown)`
  margin-left: auto;
`
