import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentRoute } from '../../redux/reducers/utils/navReducer';

function KitchenModule() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === '/kitchen') {
      dispatch(setCurrentRoute('/kitchen/dashboard'));
      navigate('/kitchen/dashboard');
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   if (kitchens.length >= 1 && selectKitchen === null) {
  //     dispatch(setSelectKitchen(kitchens[0]));
  //   }
  // }, [kitchens]);

  // useEffect(() => {
  //   if (userKitchenCreateRequestStatus === 'resolved') {
  //     dispatch(sendRequestKitchens({ token: localStorage.getItem('token'), offset: 0, limit: 10000 }));
  //   }
  // }, [userKitchenCreateRequestStatus]);

  // useEffect(() => {
  //   if (isAuth) {
  //     dispatch(sendRequestKitchens({ token: localStorage.getItem('token'), offset: 0, limit: 10000 }));
  //     dispatch(sendRequestCitiesGet({ token: localStorage.getItem('token') }));
  //     dispatch(sendRequestGetAllKitchenStatuses({ token: localStorage.getItem('token') }));
  //   }
  // }, [isAuth]);

  // useEffect(() => {
  //   if(selectKitchen && !selectKitchen.stepsStartKitchen) {
  //     dispatch(getSteps({token: localStorage.getItem('token'), kitchenId: selectKitchen.id}));
  //   }
  // }, [selectKitchen])

  // useEffect(() => {
  //   if(stepsUpdateRequestStatus === 'resolved') {
  //     dispatch(getSteps({token: localStorage.getItem('token'), kitchenId: selectKitchen.id}));
  //   }
  // }, [stepsUpdateRequestStatus])
}

export default KitchenModule;
