import apiInstance from '../../../../../api';
import {call, put, select} from 'redux-saga/effects';
import {
  sendRequestKitchenComplaintGetSuccess,
  sendRequestKitchenComplaintGetError,
  setKitchenComplaintData,
  sendRequestKitchenComplaintCreateSuccess,
  sendRequestKitchenComplaintCreateError,
  sendRequestKitchenComplaintUpdateSuccess,
  sendRequestKitchenComplaintUpdateError,
  sendRequestKitchenComplaintDeleteSuccess,
  sendRequestKitchenComplaintDeleteError,
  sendRequestKitchenComplaintConfirmSuccess,
  sendRequestKitchenComplaintConfirmError,
  sendRequestKitchenComplaintTableServicesTypeGetSuccess,
  sendRequestKitchenComplaintTableServicesTypeGetError,
  setKitchenComplaintTableServicesType,
  sendRequestKitchenComplaintTablePretensionsStatusGetSuccess,
  sendRequestKitchenComplaintTablePretensionsStatusGetError,
  setKitchenComplaintTablePretensionsStatus,
} from '../../../../reducers/utils/kitchenReducers/kitchenComplaintReducer';
import { selectCommonState } from '../../../../reducers/utils/commonReducer';

export function* handleGetKitchenComplaint(action) {
  const api = '/kitchen/pretensions/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload;
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenComplaintGetSuccess());
      yield put(setKitchenComplaintData(response.data.data.pretensions));
    } else {
      yield put(sendRequestKitchenComplaintGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenComplaintGetError());
  }
}

export function* handleCreateKitchenComplaint(action) {
  const api = '/kitchen/pretensions/create?XDEBUG_SESSION=PHPSTORM';
  const data = action.payload;
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  
  let formData = new FormData();
  const maxImages = 4;

  for (let i = 1; i <= maxImages; i++) {
    const imageKey = `image${i}`;
    if (action.payload[imageKey]) {
      formData.append(imageKey, action.payload[imageKey]);
    }
  }

  formData.append('data', JSON.stringify( data ));

  try {
    const response = yield call(() => apiInstance.post(fullApi, formData));
    // const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenComplaintCreateSuccess());
    } else {
      yield put(sendRequestKitchenComplaintCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenComplaintCreateError());
  }
}

export function* handleUpdateKitchenComplaint(action) {
  const api = '/kitchen/pretensions/update?XDEBUG_SESSION=PHPSTORM';
  const data = action.payload;
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenComplaintUpdateSuccess());
    } else {
      yield put(sendRequestKitchenComplaintUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenComplaintUpdateError());
  }
}

export function* handleDeleteKitchenComplaint(action) {
  const api = '/kitchen/pretensions/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload;
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenComplaintDeleteSuccess());
    } else {
      yield put(sendRequestKitchenComplaintDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenComplaintDeleteError());
  }
}

export function* handleConfirmKitchenComplaint(action) {
  const api = '/kitchen/pretensions/confirm?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload;
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenComplaintConfirmSuccess());
    } else {
      yield put(sendRequestKitchenComplaintConfirmError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenComplaintConfirmError());
  }
}

export function* handleGetTableServicesType(action) {
  const api = '/kitchen/pretensions/getTableServicesType?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload;
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenComplaintTableServicesTypeGetSuccess());
      yield put(setKitchenComplaintTableServicesType(response.data.data));
    } else {
      yield put(sendRequestKitchenComplaintTableServicesTypeGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenComplaintTableServicesTypeGetError());
  }
}

export function* handleGetTablePretensionsStatus(action) {
  const api = '/kitchen/pretensions/getTablePretensionsStatus?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload;
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenComplaintTablePretensionsStatusGetSuccess());
      yield put(setKitchenComplaintTablePretensionsStatus(response.data.data));
    } else {
      yield put(sendRequestKitchenComplaintTablePretensionsStatusGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenComplaintTablePretensionsStatusGetError());
  }
}
