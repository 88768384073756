import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestKitchenContractsUpdate,
  sendRequestKitchenContractsDelete,
  sendRequestKitchenContractCommissionsCreate,
  sendRequestKitchenContractCommissionsDelete,
} from '../../../../../redux/reducers/utils/kitchenReducers/kitchenSettingsReducer';
import { Drawer, Flex, Table, Typography, Dropdown, Checkbox, DatePicker, Select, Modal, Form, Input, Button, Popconfirm, InputNumber } from 'antd';
import { PlusOutlined, SettingOutlined, EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ContractsCreateModalComponent from './ContractsCreateModalComponent';
import PrintTable from '../../../../../components/PrintTable';
import { formatDate } from '../../../../../core/utils';

const { Title, Text } = Typography;
const { Search } = Input;

dayjs.extend(customParseFormat);
const serverFormat = 'YYYY-MM-DD';
const clientFormat = 'DD-MM-YYYY';

const ContractsTableComponent = () => {
  const { kitchenContracts } = useSelector((store) => store.kitchenSettings);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredKitchenContracts = kitchenContracts?.filter((item) =>
    item.id?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.contractNumber?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.contractDate?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.contractCommission?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.commissionStartDate?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.organization.name?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.organization.inn?.toString().toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      title: 'Название',
      dataIndex: ['organization', 'name'],
      key: 'organizationName',
    },
    {
      title: 'ИНН',
      dataIndex: ['organization', 'inn'],
      key: 'organizationInn',
    },
    {
      title: 'Договор',
      key: 'contract',
      children: [
        {
          title: 'Номер договора',
          dataIndex: 'contractNumber',
          key: 'contractNumber',
        },
        {
          title: 'Дата создания договора',
          key: 'contractDate',
          sorter: (a, b) => moment(a.contractDate, 'YYYY/MM/DD').diff(moment(b.contractDate, 'YYYY/MM/DD')),
          render: (record) => (<Text>{formatDate(record.contractDate)}</Text>)
        },
        {
          title: 'Комиссии по договору',
          key: 'contractCommission',
          render: (record) => (
            <Flex vertical gap='small'>
              {record.commissions && record.commissions.map((commission, index) => (
                <Flex vertical gap='0' key={record.id} style={{padding: '4px 8px', fontSize: '10px', lineHeight: 1, border: 'solid 1px #f1f1f1', borderRadius: '5px'}}>
                  <div><b>Коммисия по договору: {commission.contractCommission} р.</b></div>
                  <div>Дата начала: {formatDate(commission.commissionStartDate)}</div>
                </Flex>
              ))}
            </Flex>
          ),
        },
      ],
    },
    {
      title: '',
      key: 'actions',
      render: (_, record) => (
        <AccessAction
          record={record}
        />
      ),
      width: '120px'
    },
  ];

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  return (
    <>
      <Flex gap={20} wrap='wrap' style={{ margin: '20px 0' }}>
        <Search
          placeholder="Поиск"
          onChange={(e) => handleSearch(e.target.value)}
          style={{ maxWidth: '500px' }}
        />
        <ContractsCreateModalComponent/>
      </Flex>
      <PrintTable>
        <Table
          dataSource={filteredKitchenContracts}
          columns={columns}
          scroll={{ x: 'auto' }}
          size="small"
          pagination={{
            defaultPageSize: 20,
          }}
        />
      </PrintTable>
    </>
  );
};

export default ContractsTableComponent;

const AccessAction = ({record}) => {
  const { organizations } = useSelector((store) => store.settingsCatalogs);
  const dispatch = useDispatch();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [form] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = useState(false);

  const organizationOptions = organizations?.map((item) => ({
    ...item,
    value: item?.id,
    label: item?.urName,
  }));

  const handleOpen = () => {
    setIsOpenDrawer(true);
  };

  const handleFormChange = () => {
    setIsFormChanged(true);
  };
  
  const handleUpdateContract = () => {
    form.validateFields()
      .then((values) => {
        const requestData = {
          token: localStorage.getItem('token'),
          id: record.id,
          organizationId: values.organization,
          contractNumber: values.contractNumber,
          contractDate: moment(new Date(values.contractDate)).format(serverFormat),
          kitchenId: record.kitchenId,
          walletKitchenId: record.walletKitchenId,
        }
    
        dispatch(sendRequestKitchenContractsUpdate(requestData));
        setIsOpenDrawer(false);
        form.resetFields();
      })
      .catch((errorInfo) => {
        console.error('Ошибка при валидации формы:', errorInfo);
      });
  };

  const handleCancel = () => {
    setIsOpenDrawer(false);
    setIsFormChanged(false);
    form.resetFields();
  };

  const handleDelete = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      id: record.id,
      kitchenId: record.kitchenId,
    }

    dispatch(sendRequestKitchenContractsDelete(requestData));
  };

  return (
    <Action>
      <Button
        icon={<EditOutlined />}
        onClick={handleOpen}
      >
      </Button>
      <Drawer
        title={
          <Flex gap={20}>
            { isFormChanged &&
              <>
                <Button type="primary" onClick={handleUpdateContract}>Сохранить</Button>
                <Button onClick={handleCancel}>Отменить</Button>
              </>
            }
          </Flex>
        }
        placement="right"
        open={isOpenDrawer}
        onClose={handleCancel}
        width='40%'
        mask={false}
      >
        <Form
          style={{ width: '100%' }}
          form={form}
          name='contract'
          layout='vertical'
          onValuesChange={handleFormChange}
          initialValues={{
            organization: {value: record.organization.id, label: organizations?.find(organization => organization.id.toString() === record.organization.id.toString())?.urName,}.value,
            contractNumber: record.contractNumber,
            contractDate: dayjs(record.contractDate, serverFormat),
          }}
        >
          <Form.Item label='Организация' name='organization' rules={[{ required: true, message: 'Выберите организацию' }]}>
            <Select
              showSearch
              placeholder="Выберите организацию"
              optionFilterProp="children"
              options={organizationOptions}
              filterOption={(input, option) =>
                (option?.urName?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
                (option?.inn?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionRender={(option) => (
                <Flex
                  vertical
                  style={{ margin: '5px 0', height: '80px' }}
                >
                  <div>ID: {option.data.id}</div>
                  <Title level={5}>{option.data.urName}</Title>
                  <div>ИНН: {option.data.inn}</div>
                </Flex>
              )}
            />
          </Form.Item>
          <Flex gap='small' align='flex-end'>
            <Form.Item style={{width:'50%'}} label='Номер договора' name='contractNumber' rules={[{ required: true, message: 'Введите номер договора' }]}>
              <Input placeholder='Номер договора'/>
            </Form.Item>
            <Form.Item style={{width:'50%'}} label='Дата заключения договора' name='contractDate' rules={[{ required: true, message: 'Выберите дату' }]}>
              <DatePicker style={{ width:'100%' }} placeholder='Выберите дату' format={clientFormat}/>
            </Form.Item>
          </Flex>
        </Form>

        <Flex vertical gap='small'>
        <Title level={4}>Комиссии по договору</Title>
          <ContractsTermsComponent
            idContract={record.id}
            contractTerms={record.commissions}
          />
        </Flex>
      </Drawer>
      <Popconfirm
        title="Удаление договора!"
        description={`Вы действительно хотите удалить договор ${record.organization.name}`}
        okText="Да"
        cancelText="Нет"
        placement="left"
        onConfirm={() => handleDelete(record.id)}
      >
        <Button danger={true}>Удалить</Button>
      </Popconfirm>
    </Action>
  );
};

const Action = styled.div`
  position: relative;
  z-index: 600;
  display: flex;
  gap: 5px;
  align-items: center;
`

const ContractsTermsComponent = ({ idContract, contractTerms }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const modifyContractTerms = contractTerms?.map(item => ({ ...item, key: item.id }));

  const columns = [
    {
      title: 'Комиссия по договору',
      key: 'contractCommission',
      render: (record) => (<Text>{record.contractCommission}%</Text>)
    },
    {
      title: 'Дата начала',
      key: 'commissionStartDate',
      render: (record) => (<Text>{formatDate(record.commissionStartDate)}</Text>)
    },
    {
      title: '',
      key: 'action',
      render: (record) => {
        return (
          <Button onClick={() => handleDeleteCommission(record.id)}>Удалить</Button>
        )
      },
    },
  ];

  const handleCreateCommission = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      idContract: idContract,
      contractCommission: values.contractCommission,
      commissionStartDate: values.commissionStartDate,
    }

    dispatch(sendRequestKitchenContractCommissionsCreate(requestData))
    form.resetFields();
  }

  const handleDeleteCommission = (id) => {
    const requestData = {
      token: localStorage.getItem('token'),
      id: id,
    }

    dispatch(sendRequestKitchenContractCommissionsDelete(requestData));
  }

  return (
    <>
      <Form
        form={form}
        onFinish={handleCreateCommission}
        layout='inline'
      >
        <Form.Item name='contractCommission' rules={[{ required: true, message: 'Комиссия по договору' }]}>
          <InputNumber max={100} placeholder='10%'/>
        </Form.Item>
        <Form.Item name='commissionStartDate' rules={[{ required: true, message: 'Выберите дату' }]}>
          <DatePicker style={{ width:'100%' }} placeholder='Выберите дату' format={clientFormat}/>
        </Form.Item>
        <Form.Item style={{marginLeft: 'auto'}}>
          <Button type="primary" htmlType="submit">Создать</Button>
        </Form.Item>
      </Form>
      <PrintTable>
        <Table
          dataSource={modifyContractTerms}
          columns={columns}
          size="small"
          pagination={false}
        />
      </PrintTable>
    </>
  );
}