import apiInstance from '../../../../../api';
import {call, put, select} from 'redux-saga/effects';
import {
  //Search
  sendRequestClientsGetSuccess,
  sendRequestClientsGetError,
  setClients,

  //Detail
  sendRequestClientProfileGetSuccess,
  sendRequestClientProfileGetError,
  setClientProfile,

  sendRequestClientOrdersStatisticsGetSuccess,
  sendRequestClientOrdersStatisticsGetError,
  setClientOrdersStatistics,

  sendRequestClientOrdersGetSuccess,
  sendRequestClientOrdersGetError,
  setClientOrders,

  sendRequestClientAddressesGetSuccess,
  sendRequestClientAddressesGetError,
  setClientAddresses,
  sendRequestMakeAddressActiveSuccess,
  sendRequestMakeAddressActiveError,

  sendRequestClientDevicesGetSuccess,
  sendRequestClientDevicesGetError,
  setClientDevices,

  sendRequestPushNotificationGetSuccess,
  sendRequestPushNotificationGetError,
  setPushNotification,

  sendRequestMessagesGetSuccess,
  sendRequestMessagesGetError,
  setMessages,
} from '../../../../reducers/utils/clientsReducers/clientsSearchReducer';
import { selectCommonState } from '../../../../reducers/utils/commonReducer';

//Search
export function* handleGetClients(action) {
  const api = '/user/search?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestClientsGetSuccess());
      yield put(setClients(response.data.data));
    } else {
      yield put(sendRequestClientsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestClientsGetError());
  }
}

//Detail
export function* handleGetClientProfile(action) {
  const api = '/user/getUserProfile?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestClientProfileGetSuccess());
      yield put(setClientProfile(response.data.data.userProfile));
    } else {
      yield put(sendRequestClientProfileGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestClientProfileGetError());
  }
}

export function* handleGetClientOrdersStatistics(action) {
  const api = '/user/orders/getOrdersStatistics?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestClientOrdersStatisticsGetSuccess());
      yield put(setClientOrdersStatistics(response.data.data.orderStatistics));
    } else {
      yield put(sendRequestClientOrdersStatisticsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestClientOrdersStatisticsGetError());
  }
}

export function* handleGetClientOrders(action) {
  const api = '/user/orders/getOrders?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestClientOrdersGetSuccess());
      yield put(setClientOrders(response.data.data));
    } else {
      yield put(sendRequestClientOrdersGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestClientOrdersGetError());
  }
}

export function* handleGetClientAddresses(action) {
  const api = '/user/addresses/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestClientAddressesGetSuccess());
      yield put(setClientAddresses(response.data.data.addresses));
    } else {
      yield put(sendRequestClientAddressesGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestClientAddressesGetError());
  }
}

export function* handleMakeAddressActive(action) {
  const api = '/user/addresses/makeAddressActive?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestMakeAddressActiveSuccess());
    } else {
      yield put(sendRequestMakeAddressActiveError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestMakeAddressActiveError());
  }
}

export function* handleGetClientDevices(action) {
  const api = '/user/devices/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestClientDevicesGetSuccess());
      yield put(setClientDevices(response.data.data.devices));
    } else {
      yield put(sendRequestClientDevicesGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestClientDevicesGetError());
  }
}

export function* handleGetPushNotification(action) {
  const api = '/user/pushNotifications/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestPushNotificationGetSuccess());
      yield put(setPushNotification(response.data.data.pushNotifications));
    } else {
      yield put(sendRequestPushNotificationGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestPushNotificationGetError());
  }
}

export function* handleGetMessages(action) {
  const api = '/user/massages/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestMessagesGetSuccess());
      yield put(setMessages(response.data.data.massages));
    } else {
      yield put(sendRequestMessagesGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestMessagesGetError());
  }
}
