import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getUserInfo, updateUserInfo } from "../../../../redux/reducers/utils/settingsReducers/settingsProfileReducer";
import Title from "../../../../ui/Title";
import { Button, Form, Input, Select } from "antd";
import Card from "../../../../components/Card";
import { Flex } from "../../../../core/styles";

const { Option } = Select;

const ProfilePage = () => {
    const { isAuth } = useSelector((store) => store.auth);
    const {
        userInfoGetRequestStatus,
        userName,
        userTimezone,
    } = useSelector((store) => store.settingsProfile);
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        if (isAuth) {
            dispatch(getUserInfo({ token: localStorage.getItem('token') }));
        }
    }, [isAuth]);

    useEffect(() => {
        if (userName && userTimezone !== undefined) {
            const data = { name: userName, timezone: userTimezone.toString() };
            form.setFieldsValue(data);
        }
    }, [userName, userTimezone]);

    const handleSave = () => {
        form.validateFields()
            .then(values => {
                console.log(values);
                dispatch(updateUserInfo({token: localStorage.getItem('token'), ...values}));
            })
            .catch(info => {
                console.log('Ошибка при сохранении:', info);
            });
    };
    
    const handleCancel = () => {
        form.resetFields();
    };

    return (
        <>
            <Title level={1}>Профиль</Title>

            <Form
                form={form}
                layout="vertical"
                // onValuesChange={handleValuesChange}
                initialValues={{
                    name: userName,
                    timezone: userTimezone ? userTimezone.toString() : userTimezone === 0 ? userTimezone.toString() : undefined,
                }}
            >
                <Card>
                    <Flex gap='20px' wrap='wrap'>
                        <Form.Item style={{flex: '1 1 330px'}} label="Имя" name="name">
                            <Input placeholder="Иван" />
                        </Form.Item>

                        <Form.Item style={{flex: '1 1 330px'}} label="Часовой пояс" name="timezone">
                            <Select
                                placeholder="UTC+00:00"
                            >
                                <Option value="-12">UTC-12:00</Option>
                                <Option value="-11">UTC-11:00</Option>
                                <Option value="-10">UTC-10:00</Option>
                                <Option value="-9">UTC-09:00</Option>
                                <Option value="-8">UTC-08:00</Option>
                                <Option value="-7">UTC-07:00</Option>
                                <Option value="-6">UTC-06:00</Option>
                                <Option value="-5">UTC-05:00</Option>
                                <Option value="-4">UTC-04:00</Option>
                                <Option value="-3">UTC-03:00</Option>
                                <Option value="-2">UTC-02:00</Option>
                                <Option value="-1">UTC-01:00</Option>
                                <Option value="0">UTC+00:00</Option>
                                <Option value="1">UTC+01:00</Option>
                                <Option value="2">UTC+02:00</Option>
                                <Option value="3">UTC+03:00</Option>
                                <Option value="4">UTC+04:00</Option>
                                <Option value="5">UTC+05:00</Option>
                                <Option value="6">UTC+06:00</Option>
                                <Option value="7">UTC+07:00</Option>
                                <Option value="8">UTC+08:00</Option>
                                <Option value="9">UTC+09:00</Option>
                                <Option value="10">UTC+10:00</Option>
                                <Option value="11">UTC+11:00</Option>
                                <Option value="12">UTC+12:00</Option>
                            </Select>
                        </Form.Item>
                    </Flex>
                    <Flex gap='20px'>
                        <Button type="primary" onClick={handleSave}>Сохранить</Button>
                        <Button onClick={handleCancel}>Отменить</Button>
                    </Flex>
                </Card>
            </Form>
        </>
    );
}

export default ProfilePage;
