import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestDeliveryServiceRacesCreate } from '../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Flex, Modal, Form, Select, Input, TimePicker, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

const RaceCreateModalComponent = () => {
  const { cities } = useSelector((store) => store.settingsCatalogs);
  const { currentDeliveryService } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();
  const [ isCreate, setIsCreate] = useState(false);
  const [form] = Form.useForm();
  const [ timezone, setTimezone] = useState(null);

  const handleOpen = () => {
    setIsCreate(true);
  };

  const handleCancel = () => {
    setIsCreate(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      cityId: values.city,
      deliveryServiceId: currentDeliveryService.id,
      timeStart: moment(new Date(values.timeStart)).subtract(timezone, 'hours').format("HH:mm"),
      timeEnd: moment(new Date(values.timeEnd)).subtract(timezone, 'hours').format("HH:mm"),
      initialCourierCutoff: values.initialCourierCutoff ? moment(new Date(values.initialCourierCutoff)).subtract(timezone, 'hours').format("HH:mm") : "00:00",
      finalCourierCutoff: values.finalCourierCutoff ? moment(new Date(values.finalCourierCutoff)).subtract(timezone, 'hours').format("HH:mm") : "00:00",
    }

    dispatch(sendRequestDeliveryServiceRacesCreate(requestData));
    setIsCreate(false);
    form.resetFields();
  }

  const cityOptions = cities?.map((item) => ({
    ...item,
    value: item.id,
    label: item.name,
    timezone: item.timezone,
  }));

  const handleChange = (value, option) => {
    setTimezone(option.timezone);
  };

  return (
    <>
      <Button
        type='primary'
        shape="round"
        icon={<PlusOutlined />}
        onClick={handleOpen}
        style={{marginLeft: 'auto'}}
      >
        Добавить рейс
      </Button>
      <Modal
        title="Создание рейса доставки"
        open={isCreate}
        onCancel={handleCancel}
        footer={null}
      >
        <Form style={{ width: '100%' }} form={form} name='deliveryService' layout='vertical' onFinish={handleSubmit}>
          <Form.Item label='Город' name='city' rules={[{ required: true, message: 'Выберите город' }]}>
            <Select
              showSearch
              placeholder="Выберите город"
              optionFilterProp="children"
              options={cityOptions}
              filterOption={(input, option) =>
                (option?.name?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionRender={(option) => (
                <Flex>{option.data.label} (GMT{option.data.timezone >= 0 ? `+${option.data.timezone}` : option.data.timezone})</Flex>
              )}
              onChange={handleChange}
            />
          </Form.Item>
          <Flex justify='space-between' gap='large'>
            <Form.Item style={{flex: '1' }} label="Начало доставки" name="timeStart" rules={[{ required: true, message: 'Укажите начало доставки' }]}>
              <TimePicker style={{width: '100%' }} format="HH:mm"/>
            </Form.Item>
            <Form.Item style={{flex: '1' }} label="Завершение доставки" name="timeEnd" rules={[{ required: true, message: 'Укажите завершение доставки' }]}>
              <TimePicker style={{width: '100%' }} format="HH:mm"/>
            </Form.Item>
          </Flex>
          <Flex justify='space-between' gap='large'>
            <Form.Item style={{flex: '1' }} label="Время начала работы курьера" name="initialCourierCutoff">
              <TimePicker style={{width: '100%' }} format="HH:mm"/>
            </Form.Item>
            <Form.Item style={{flex: '1' }} label="Время окончания работы курьера" name="finalCourierCutoff">
              <TimePicker style={{width: '100%' }} format="HH:mm"/>
            </Form.Item>
          </Flex>
          <Button type='primary' htmlType='submit' style={{ display: 'flex', marginLeft: 'auto' }}>Создать</Button>
        </Form>
      </Modal>
    </>
  );
};

export default RaceCreateModalComponent;
