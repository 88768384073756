import React from 'react';
import styled, { css } from "styled-components";

const Button = ({ 
  className, 
  style, 
  type = 'default', 
  round = false, 
  color, 
  size = 'medium', 
  disabled = false, 
  onClick, 
  children 
}) => (
  <StyledButton
    style={style}
    className={className}
    type={type}
    round={round}
    color={color}
    size={size}
    disabled={disabled}
    onClick={!disabled ? onClick : undefined}
  >
    {children}
  </StyledButton>
);

export default Button;

const buttonStyles = {
  default: css`
    background: #EAEAEA;
    color: #2a2a2a;
  `,
  icon: css`
    background: #EAEAEA;
    color: #2a2a2a;
    min-width: ${({ size }) => getSize(size)};
    padding: 16px;
    height: ${({ size }) => getSize(size)};
  `,
  text: css`
    background: none;
    color: #2a2a2a;
  `,
};

const getSize = (size) => {
  switch (size) {
    case 'small':
      return '40px';
    case 'large':
      return '80px';
    default:
      return '60px';
  }
};

const StyledButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-width: ${({ type }) => (type === 'text' ? 'fit-content' : '150px')};
  padding: ${({ type }) => (type === 'icon' ? '16px' : '0 35px')};
  height: ${({ size, type }) => (type === 'text' ? 'auto' : getSize(size))};
  border-radius: ${({ round }) => (round ? '100px' : '20px')};
  font-family: 'SF Pro Display', sans-serif !important;
  font-size: ${({ size }) => (size === 'small' ? '14px' : '16px')};
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  transition: all 0.2s ease;
  border: none;
  overflow: hidden;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${({ type }) => buttonStyles[type || 'default']};

  ${({ color }) =>
    color &&
    css`
      background: ${color};
      color: #ffffff;
    `}
    
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.3s;
    pointer-events: none;
  }

  &:hover::before {
    background: ${({ disabled }) => !disabled && 'rgba(0, 0, 0, 0.1)'};
  }

  &:active::before {
    background: ${({ disabled }) => !disabled && 'rgba(0, 0, 0, 0.2)'};
  }
`;
