import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestModifierGroupsProductsCreate,
  sendRequestModifierGroupsProductUpdate
} from '../../../../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import { Table, Switch, InputNumber } from 'antd';
import PrintTable from '../../../../../../components/PrintTable';

const ModifiersTableComponent = ({ products }) => {
  const navigate = useNavigate();
  const {
    currentModifierGroupId,
  } = useSelector((store) => store.kitchenMenu);
  const dispatch = useDispatch();

  const columns = [
    {
      title: 'Статус',
      key: 'action',
      render: (_, record) => (
        <Switch
          checked={record.isEnabled}
          onChange={(checked) => handleSwitchProduct(record, checked)}
        />
      ),
      filters: [
        {
          text: 'Включен',
          value: true,
        },
        {
          text: 'Выключен',
          value: false,
        },
      ],
      onFilter: (value, record) => record.isEnabled === value,
    },
    {
      title: 'Название продукта',
      dataIndex: 'nameProduct',
      key: 'nameProduct',
      sorter: (a, b) => {
        if (a.nameProduct && b.nameProduct) {
          return a.nameProduct.localeCompare(b.nameProduct);
        }
        if (!a.nameProduct && b.nameProduct) return 1;
        if (a.nameProduct && !b.nameProduct) return -1;
        return 0;
      }
    },
    {
      title: 'Описание продукта',
      dataIndex: 'descriptionProduct',
      key: 'descriptionProduct',
      sorter: (a, b) => {
        if (a.descriptionProduct && b.descriptionProduct) {
          return a.descriptionProduct.localeCompare(b.descriptionProduct);
        }
        if (!a.descriptionProduct && b.descriptionProduct) return 1;
        if (a.descriptionProduct && !b.descriptionProduct) return -1;
        return 0;
      }
    },
    {
      title: 'Количество модификаторов',
      key: 'modifiers',
      children: [
        {
          title: 'min',
          dataIndex: 'min',
          key: 'min',
          render: (_, record) => (
            <EditableCell record={record} minMax='min' handleUpdateProduct={handleUpdateProduct}/>
          ),
          sorter: (a, b) => a.min - b.min,
        },
        {
          title: 'max',
          dataIndex: 'max',
          key: 'max',
          render: (_, record) => (
            <EditableCell record={record} minMax='max' handleUpdateProduct={handleUpdateProduct}/>
          ),
          sorter: (a, b) => a.max - b.max,
        },
      ],
    },
  ];

  const handleSwitchProduct = (record, checked) => {
    const requestData = {
      token: localStorage.getItem('token'),
      modifierGroupProductId: record.modifierGroupProductId,
      modifierGroupId: record.modifierGroupId,
      productId: record.productId,
      min: record.min,
      max: record.max,
      isEnabled: record.isEnabled,
    };
  
    console.log({...requestData, isEnabled: checked});
    if (record.modifierGroupProductId === 0) {
      dispatch(sendRequestModifierGroupsProductsCreate({...requestData, isEnabled: checked}));
    } else {
      dispatch(sendRequestModifierGroupsProductUpdate({...requestData, isEnabled: checked}));
    }
  }

  const handleUpdateProduct = (record, updateData) => {
    const requestData = {
      token: localStorage.getItem('token'),
      modifierGroupProductId: record.modifierGroupProductId,
      modifierGroupId: record.modifierGroupId,
      productId: record.productId,
      min: record.min,
      max: record.max,
      isEnabled: record.isEnabled,
    };

    dispatch(sendRequestModifierGroupsProductUpdate({...requestData, ...updateData}));
  };

  return (
    <PrintTable>
      <Table
        dataSource={products}
        columns={columns}
        scroll={{ x: 'auto' }}
        bordered
        size="small"
        pagination={false}
      />
    </PrintTable>
  );
};

export default ModifiersTableComponent;

const EditableCell = ({ record, minMax, handleUpdateProduct }) => {
  const recordValue = minMax === 'min' ? record.min : record.max;
  const [value, setValue] = useState(recordValue);
  const [hasChanges, setHasChanges] = useState(false);
  const inputRef = React.createRef();

  useEffect(() => {
    setValue(recordValue);
    setHasChanges(false);
  }, [record]);

  const handleChangeInMenuValue = (value) => {
    setValue(value);
    setHasChanges(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      inputRef.current.blur();
      handleBlur();
    }
  };

  const handleBlur = () => {
    if (hasChanges) {
      const updateData = minMax === 'min' ? { min: value } : { max: value };
      handleUpdateProduct(record, updateData);
      setHasChanges(false);
    }
  };

  return (
    <InputNumber
      ref={inputRef}
      value={value}
      maxLength={1}
      disabled={record.isEnabled === false}
      onChange={handleChangeInMenuValue}
      onBlur={handleBlur}
      onKeyDown={handleKeyPress}
    />
  );
};
