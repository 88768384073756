import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCurrentDeliveryServiceCourier,
  sendRequestDeliveryServiceCouriersUpdate,
  sendRequestDeliveryServiceCouriersDelete
} from '../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Flex, Modal, Typography, Table, Form, Popconfirm, Input, Button, ColorPicker, Tag } from 'antd';
import { EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';
import CourierCreateModalComponent from './CourierCreateModalComponent';
import PrintTable from '../../../../../../../components/PrintTable';
import { useForm } from 'antd/es/form/Form';

const { Text } = Typography;
const { Search } = Input;

const CouriersTableComponent = () => {
  const navigate = useNavigate();
  const { deliveryServiceCouriers } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const modifyCouriers = deliveryServiceCouriers?.map(item => {  
    return { ...item, key: item.id};
  });

  const filteredCouriers = modifyCouriers?.filter((item) =>
    item.courierId?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.name?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.phone?.toString().toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleCellClick = (record) => {
    dispatch(setCurrentDeliveryServiceCourier(record));
    navigate(`couriers/${record.courierId}`);
  };

  const columns = [
    {
      title: 'ID\u00A0курьера',
      dataIndex: 'courierId',
      key: 'courierId',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Цвет',
      dataIndex: 'color',
      key: 'color',
      render: (_, record) => (
        <ColorPicker value={record.color} disabled style={{cursor: 'pointer'}} />
      ),
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <Flex align='center'>
          <Tag>{record.prefix}</Tag><Text>{record.name}</Text>
        </Flex>
      ),
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Привязанные рейсы',
      dataIndex: 'relatedRaces',
      key: 'relatedRaces',
      render: (_, record) => {
        const relatedRaces = record.relatedRaces;
        if (!relatedRaces) {
          return <Text type="secondary">Нет связанных рейсов</Text>;
        }
      
        const { id, timeStart, timeDelivery, timeEnd, initialCourierCutoff, finalCourierCutoff } = relatedRaces;
      
        return (
          <Flex gap="small">
            <Text strong>ID: {id}</Text>
            <Flex vertical gap='small'>
              <Flex gap="small">
                <Text type="secondary">Начало доставки: {timeStart}</Text>
                <Text type="secondary">Конец доставки: {timeEnd}</Text>
              </Flex>
              <Flex gap="small">
                <Text type="secondary">Время: {timeDelivery} мин</Text>
                <Text type="secondary">Доступное время: {initialCourierCutoff} - {finalCourierCutoff}</Text>
              </Flex>
            </Flex>
          </Flex>
        );
      },
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Сумма на ЗП-кошельке',
      dataIndex: 'walletBalance',
      key: 'walletBalance',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
      sorter: (a, b) => a.walletBalance - b.walletBalance,
    },
    {
      title: 'Статус',
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      render: (_, record) => {
        let color = 'green';
        let status = 'Активен'
        if (record.isEnabled === 0) {
          color = 'volcano';
          status = 'Отключен'
        }
        return (
          <Tag color={color}>
            {status.toUpperCase()}
          </Tag>
        );
      },
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
      filters: [
        {
          text: 'Активен',
          value: 1,
        },
        {
          text: 'Отключен',
          value: 0,
        },
      ],
      onFilter: (value, record) => record.isEnabled === value,
    },
    {
      title: '',
      key: 'actions',
      render: (_, record) => (
        <AccessAction
          record={record}
        />
      ),
    },
  ];

  const hasDuplicateValues = (prefix) => {
    const prefixCount = filteredCouriers.reduce((acc, courier) => {
      if (courier.prefix === prefix) {
        acc += 1;
      }
      return acc;
    }, 0);
    return prefixCount > 1;
  };

  const doubleLinter = filteredCouriers.filter(courier => {
    return hasDuplicateValues(courier.prefix);
  });

  return (
    <>
      <Flex gap='large' style={{padding: '10px 0 30px'}}>
        {doubleLinter.length > 0 && (<Text><ExclamationCircleFilled style={{ color: '#FFA800' }} /> есть повторяющаяся литера!</Text>)}
      </Flex>
      <Flex gap={20} wrap='wrap' style={{ margin: '20px 0' }}>
        <Search
          placeholder="Поиск по ID, имени и телефону"
          onChange={(e) => handleSearch(e.target.value)}
          style={{ maxWidth: '500px' }}
        />
        <CourierCreateModalComponent/>
      </Flex>
      <PrintTable>
        <Table
          dataSource={filteredCouriers}
          columns={columns}
          size="small"
          onRow={(record) => ({
            style: { background: hasDuplicateValues(record.prefix) ? '#ffa1a1' : undefined },
          })}
          pagination={{
            defaultPageSize: 50,
          }}
        />
      </PrintTable>
    </>
  );
};

export default CouriersTableComponent;

const AccessAction = ({record}) => {
  const dispatch = useDispatch();
  const [ isCreate, setIsCreate] = useState(false);
  const [form] = Form.useForm();

  form.setFieldsValue(record);

  const handleOpen = () => {
    setIsCreate(true);
  };

  const handleCancel = () => {
    setIsCreate(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      courierId: record.courierId,
      deliveryServiceId: record.deliveryServiceId,
      color: typeof values.color === 'object' ? `#${values.color.toHex()}` : values.color,
      prefix: values.prefix,
      name: values.name,
      phone: values.phone,
      isEnabled: record.isEnabled,
    }

    dispatch(sendRequestDeliveryServiceCouriersUpdate(requestData));
    setIsCreate(false);
    form.resetFields();
  }

  const handleDelete = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      deliveryServiceId: record.id,
      courierId: record.courierId,
    }

    console.log(requestData)
    dispatch(sendRequestDeliveryServiceCouriersDelete(requestData));
  };

  const handleLiterChange = (e) => {
    const upperCaseValue = e.target.value.toUpperCase();
    form.setFieldsValue({ prefix: upperCaseValue });
  };

  return (
    <Action>
      <Button
        icon={<EditOutlined />}
        onClick={handleOpen}
      >
      </Button>
      <Modal
        title="Редактирование курьера"
        open={isCreate}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          style={{ width: '100%' }}
          form={form}
          name='deliveryService'
          layout='vertical'
          onFinish={handleSubmit}
        >
          <Flex gap='large'>
            <Form.Item label='Цвет' name='color' rules={[{ required: true, message: 'Выберите цвет' }]}><ColorPicker/></Form.Item>
            <Form.Item label='Литера' name='prefix' rules={[{ required: true, message: 'Введите литеру' }]}><Input maxLength={3} placeholder='Введите литеру' onChange={handleLiterChange}/></Form.Item>
          </Flex>
          <Form.Item label='Имя' name='name' rules={[{ required: true, message: 'Введите имя' }]}><Input placeholder='Введите имя'/></Form.Item>
          <Form.Item label='Телефон' name='phone' rules={[{ required: true, message: 'Введите телефон' }]}><Input maxLength={12} disabled/></Form.Item>
          <Button type='primary' htmlType='submit' style={{ display: 'flex', marginLeft: 'auto' }}>Сохранить</Button>
        </Form>
      </Modal>
      <Popconfirm
        title="Удаление службы доставки!"
        description={`Вы действительно хотите удалить компонента доступа ${record.name}`}
        okText="Да"
        cancelText="Нет"
        placement="left"
        onConfirm={handleDelete}
      >
        <Button danger={true}>Удалить</Button>
      </Popconfirm>
    </Action>
  );
};

const Action = styled.div`
  position: relative;
  z-index: 600;
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  align-items: center;
`
