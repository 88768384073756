import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestModifierGroupsCreate } from '../../../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import { Flex, Modal, Form, Input, InputNumber, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const ModifiersCreateModalComponent = () => {
  const { selectKitchen } = useSelector((store) => store.kitchen);
  const dispatch = useDispatch();
  const [ isCreate, setIsCreate] = useState(false);
  const [form] = Form.useForm();

  const handleOpen = () => {
    setIsCreate(true);
  };

  const handleCancel = () => {
    setIsCreate(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      kitchenId: selectKitchen.id,
      ...values,
    }

    console.log(requestData);
    dispatch(sendRequestModifierGroupsCreate(requestData));
    setIsCreate(false);
    form.resetFields();
  }
  
  return (
    <>
      <Button
        type='primary'
        shape="round"
        icon={<PlusOutlined />}
        onClick={handleOpen}
        style={{marginLeft: 'auto'}}
      >
        Добавить группу модификаторов
      </Button>
      <Modal
        title="Создание службы доставки"
        open={isCreate}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          style={{ width: '100%' }}
          form={form}
          name='modifiersGroup'
          layout='vertical'
          onFinish={handleSubmit}
        >
          <Form.Item label='Название группы модификаторов' name='name' rules={[{ required: true, message: 'Обязательное поле' }]}>
            <Input placeholder='Название группы модификаторов'/>
          </Form.Item>
          <Form.Item label='Публичное название группы модификаторов' name='public_name' rules={[{ required: true, message: 'Обязательное поле' }]}>
            <Input placeholder='Публичное название группы модификаторов'/>
          </Form.Item>
          <Flex>
            <Form.Item label='Минимальное кол-во модификаторов' name='min' rules={[{ required: true, message: 'Обязательное поле' }]}>
              <InputNumber placeholder='1'/>
            </Form.Item>
            <Form.Item label='Максимальное кол-во модификаторов' name='max' rules={[{ required: true, message: 'Обязательное поле' }]}>
              <InputNumber placeholder='3'/>
            </Form.Item>
          </Flex>
          <Button type='primary' htmlType='submit' style={{ display: 'flex', marginLeft: 'auto' }}>Создать</Button>
        </Form>
      </Modal>
    </>
  );
};

export default ModifiersCreateModalComponent;
