import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCurrentNewPoligon,
  setUploadNewPoligons,
  sendRequestDeliveryServicePolygonsMassUpdate,
} from '../../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Row, Col, Flex, Table, Menu, Select, ColorPicker, Dropdown, Button, message, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons'
import PrintTable from '../../../../../../../../components/PrintTable';

const { Option } = Select;

const NewPolygonsTableComponent = () => {
  const { uploadNewPoligons, currentNewPoligon, currentDeliveryService } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();
  const [modifyUploadNewPolygons, setModifyUploadNewPolygons] = useState([]);
  const [updatedPolygons, setUpdatedPolygons] = useState([]);

  useEffect(() => {
    const modifyUploadNewPolygons = uploadNewPoligons?.map((item, index) => {  
      return { ...item, key: index + 1};
    });

    setModifyUploadNewPolygons(modifyUploadNewPolygons)
  }, [uploadNewPoligons]);

  const handleCellClick = (record) => {
    console.log(record)
    dispatch(setCurrentNewPoligon(record));
  };

  const handleUpdate = (record, updateOption, value) => {
    let status = '';
    if (updateOption === 'Ничего не делать') {
      setUpdatedPolygons(prevPolygons => prevPolygons.filter(polygon => polygon.name !== record.description));
      return;
    } else if (updateOption === 'Создать новый') {
      status = 'create';
    } else if (updateOption === 'Обновить') {
      status = 'update';
    }

    if (status) {
      const updatedPolygon = {
        deliveryServiceId: currentDeliveryService.id,
        name: record.description,
        description: "Описание",
        coordinates: record.baseStrCoords,
        fillColor: record.fillColor,
        strokeColor: record.strokeColor,
        isEnabled: "1",
        status: status
      };

      if (updateOption === 'Обновить') {
        updatedPolygon.polygonId = value;
      }

      setUpdatedPolygons(prevPolygons => [...prevPolygons, updatedPolygon]);
    }
  };

  const handleCancelUpdate = () => {
    setUpdatedPolygons([])
    dispatch(setUploadNewPoligons(null));
  }

  const handleSuccessUpdate = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      poligons: updatedPolygons,
    }

    if (updatedPolygons.length > 0) {
      console.log(requestData)
      dispatch(sendRequestDeliveryServicePolygonsMassUpdate(requestData));
      setUpdatedPolygons([])
      dispatch(setUploadNewPoligons(null));
    } else {
      message.info('Вы не выбрали ни каких действий');
    }
  }
  
  const columns = [
    {
      title: 'Название',
      dataIndex: 'description',
      key: 'description',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Цвет',
      key: 'color',
      render: (_, record) => (
        <Flex gap='small'>
          <ColorPicker defaultValue={record.strokeColor} size="small" disabled style={{cursor: 'pointer'}}/>
          <ColorPicker defaultValue={record.fillColor} size="small" disabled style={{cursor: 'pointer'}}/>
        </Flex>
      ),
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <UpdateSelector record={record} onUpdate={handleUpdate}/>
      ),
    },
  ];

  return (
    <Flex vertical>
      <Flex gap='large' justify='flex-end' style={{marginBottom: '20px'}}>
        <Button type='primary' onClick={handleSuccessUpdate}>Сохранить</Button>
        <Button onClick={handleCancelUpdate}>Отменить</Button>
      </Flex>
      <PrintTable>
        <Table
          dataSource={modifyUploadNewPolygons}
          columns={columns}
          size="small"
          pagination={{
            defaultPageSize: 10,
          }}
          onRow={(record) => ({
            style: { cursor: 'pointer', background: record.key === currentNewPoligon?.key ? '#e6f7ff' : '' }
          })}
        />
      </PrintTable>
    </Flex>
  );
};

export default NewPolygonsTableComponent;

const UpdateSelector = ({ record, onUpdate }) => {
  const { deliveryServicePolygons } = useSelector((store) => store.deliveryServices);
  const [updateOption, setUpdateOption] = useState('');

  const deliveryServicePolygonsOption = deliveryServicePolygons.map(polygon => {
    return { ...polygon, value: polygon.id, label: polygon.name }
  })

  const handleUpdateChange = (value) => {
    setUpdateOption(value);
    if (value !== 'Обновить') {
      onUpdate(record, value, null);
    }
  };

  const handleItemChange = (value) => {
    onUpdate(record, updateOption, value);
  };

  return (
    <Flex vertical gap='small'>
      <Select defaultValue="Ничего не делать" onChange={handleUpdateChange}>
        <Option value="Ничего не делать">Ничего не делать</Option>
        <Option value="Создать новый">Создать новый</Option>
        <Option value="Обновить">Обновить</Option>
      </Select>
      {updateOption === 'Обновить' && (
        <Select
          defaultValue=""
          onChange={handleItemChange}
          options={deliveryServicePolygonsOption}
        />
      )}
    </Flex>
  );
};
